// @flow

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import List from '@autodisol/ads-js/components/List'
import AlertMessage from '@autodisol/ads-js/components/AlertMessage/AlertMessage'

const styles = () => ({
  status: {
    fontSize: '1rem',
    lineHeight: '1.5',
  },
  subStatus: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
    color: '#868F98',
  },
})

type Props = {
  classes: Object,
  status?: string,
  subStatus: string,
  withdrawalSite?: Object,
  isDisplayUpdateFleetStatus: boolean,
  hasAlreadyBeenAppreciated: boolean,
}

const QuoteInfos = ({
  classes,
  status,
  subStatus,
  withdrawalSite,
  isDisplayUpdateFleetStatus,
  hasAlreadyBeenAppreciated,
}: Props): React$Element<any> => {
  const { t } = useTranslation()

  return (
    <>
      <Typography
        variant="subtitle1"
        className={classes.status}
        style={{
          marginBottom: isDisplayUpdateFleetStatus ? 0 : 16,
        }}
      >
        {status && t(`globals.allStatus.${status ?? ''}`)}{' '}
        {subStatus !== '' && <span className={classes.subStatus}>{subStatus}</span>}
      </Typography>
      {isDisplayUpdateFleetStatus && (
        <Typography variant="subtitle1">{t('globals.allStatus.update_fleet')}</Typography>
      )}

      {withdrawalSite?.name && (
        <List
          data={[
            {
              title: t('quote.identification.siteColon'),
              content: withdrawalSite?.name ?? '-',
            },
          ]}
        />
      )}

      {hasAlreadyBeenAppreciated && (
        <AlertMessage type="info" title={t('quote.multiquotedWithinTheGroup')} />
      )}

      <Divider style={{ margin: '1.5rem 0', width: '100%' }} />
    </>
  )
}

export default (withStyles(styles)(QuoteInfos): any)
