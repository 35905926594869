// @flow
import type { Modes } from 'app/cmp/ModesMenu/types'

const getSortedModes = (modes: Modes): Modes =>
  modes.sort((a, b) => {
    if (a.isSelected === b.isSelected) {
      return 0
    }

    return a.isSelected ? -1 : 1
  })

export const getFormattedData = (modes: Modes, currentMode: string): Modes => {
  const formattedMode = modes
    .map(element => {
      if (element.code === currentMode) return { ...element, isSelected: true }
      return { ...element, isSelected: false }
    })
    .sort((a, b) => (a.isSelected === b.isSelected ? 0 : a.isSelected ? -1 : 1))

  return getSortedModes(formattedMode)
}
