// @flow

import React from 'react'
import { compose } from 'recompose'
import { connect as withRedux } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles'
import withFields from 'sharyn/hocs/with-fields'
import { withRouter } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useTranslation } from 'react-i18next'
import { SEARCH_PATH } from 'quote/quote-paths'
import SearchIcon from '@material-ui/icons/Search'
import Modal from 'Modal'
import IconButton from '@material-ui/core/IconButton'
import SearchModal from 'app/cmp/SearchBar/SearchModal'
import { Tooltip } from '@material-ui/core'

const styles = theme => ({
  form: {
    display: 'flex',
    alignItems: 'center',
  },
  searchInput: {
    fontSize: '0.875rem',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
    padding: '0.67rem 0.33rem',
  },
  iconButton: {
    padding: '0 5px 0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  searchIcon: {
    cursor: 'pointer',
  },
  inputAdornment: {
    margin: '0 5px',
  },
})

type Props = {
  classes: Object,
  className?: string,
  field: Function,
  onChange: Function,
  isSearching: boolean,
  history: Object,
  style?: Object,
}

const SearchBar = ({
  classes: css,
  className,
  field,
  onChange,
  isSearching,
  history,
  style,
}: Props) => {
  const { t } = useTranslation()
  const { open, openModal, closeModal } = Modal.useModal()

  const searchValue = field('search')

  const upperOnChange = e => {
    e.target.value = e.target.value.toUpperCase()
    onChange(e)
  }

  const handleSearch = e => {
    e.preventDefault()
    if (searchValue !== '') {
      history.push({
        pathname: SEARCH_PATH,
        state: { initialSearchFilters: { search: searchValue } },
      })
    } else {
      history.push({
        pathname: SEARCH_PATH,
      })
    }
  }

  return (
    <>
      <form onSubmit={handleSearch} className={css.form} style={style}>
        <TextField
          InputProps={{
            endAdornment: (
              <>
                <Tooltip title={t('quote.search.tooltip.default')}>
                  <InputAdornment className={css.inputAdornment}>
                    {isSearching ? (
                      <CircularProgress size={24} />
                    ) : (
                      <SearchIcon className={css.searchIcon} onClick={handleSearch} />
                    )}
                  </InputAdornment>
                </Tooltip>
                <Tooltip title={t('quote.search.tooltip.multiple')}>
                  <IconButton onClick={openModal} className={css.iconButton} disableRipple>
                    <span
                      className="material-symbols-outlined"
                      style={{
                        fontSize: '1.5rem',
                        cursor: 'pointer',
                        borderLeft: '1px solid #D2D6D9',
                        paddingLeft: 10,
                      }}
                    >
                      content_paste_search
                    </span>
                  </IconButton>
                </Tooltip>
              </>
            ),
            classes: { input: css.searchInput },
          }}
          {...{ className }}
          type="search"
          name="search"
          placeholder={t('header.menu.search')}
          value={searchValue}
          onChange={upperOnChange}
        />
      </form>

      {open && <SearchModal open={open} closeModal={closeModal} searchValue={searchValue} />}
    </>
  )
}

export default (compose(
  withRedux(({ asyncMap }) => ({
    isSearching: asyncMap.search,
  })),
  withStyles(styles),
  withFields(),
  withRouter,
)(SearchBar): any)
