export const styles = () => ({
  priceHead: { textAlign: 'right' },
  price: { textAlign: 'right', whiteSpace: 'nowrap', minWidth: 80 },
  tableRow: {
    '&:hover': {
      background: 'rgba(0,0,0,0.05)',
      cursor: 'pointer',
    },
  },
})
