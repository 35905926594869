// @flow

import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import withStyles from '@material-ui/core/styles/withStyles'
import { secondaryColorDark } from '@autodisol/ads-js/components/styles'
import { getFormattedDateByCountryCode } from '@autodisol/ads-js/utils/date'
import { connect as withRedux } from 'react-redux'
import compose from 'recompose/compose'
import { getQuoteAndCountQuery, getCommentsQuery } from 'quote/quote-queries'
import { getModesData } from 'utils/modes'
import { MODES } from 'utils/constants/modes'
import { getPrices } from '_client/util'
import { QUOTE_STATUS_UPDATED_FLEET } from '_client/config'
import { NO_VALUE } from 'utils/constants'
import { getLanguage } from 'utils/language'
import PrintAndSettingsButtons from 'quote/cmp/PrintQuote/PrintAndSettingsButtons'
import { PRINT_MODE, DEFAULT_PRINT_MODE } from 'quote/cmp/PrintQuote/constants'
import { setMode } from '_client/redux/actions'
import LoadingPage from 'sharyn/components/LoadingPage'
import Grid from '@material-ui/core/Grid'
import Header from 'quote/cmp/PrintQuote/Header'
import VehicleIdentification from 'quote/cmp/PrintQuote/VehicleIdentification'
import VehicleOwner from 'quote/cmp/PrintQuote/VehicleOwner'
import MoreInformation from 'quote/cmp/PrintQuote/MoreInformation'
import Damages from 'quote/cmp/PrintQuote/Damages'
import Dates from 'quote/cmp/PrintQuote/Dates'
import Mileage from 'quote/cmp/PrintQuote/Mileage'
import LoadedPictures from 'quote/cmp/PrintQuote/LoadedPictures'
import Comments from 'quote/cmp/PrintQuote/Comments'
import RestorationCosts from 'quote/cmp/PrintQuote/RestorationCosts'
import Signature from 'quote/cmp/PrintQuote/Signature'
import CommercialProposition from 'quote/cmp/PrintQuote/CommercialProposition'
import Valuation from 'quote/cmp/PrintQuote/Valuation'
import Insert from 'quote/cmp/PrintQuote/Insert'
import VehicleOptions from 'quote/cmp/PrintQuote/VehicleOptions'

import type { Mode } from 'types/modes'
import type { PrintMode } from 'quote/cmp/PrintQuote/types'

const language = getLanguage()

const styles = () => ({
  container: {
    color: secondaryColorDark,
    width: '600pt',
    maxWidth: '600pt',
    margin: '10pt auto',
    '@media print': {
      margin: 0,
    },
  },
  print: {
    fontSize: '0.75rem',
    border: '1px solid lightgrey',
    padding: '20pt',
    marginTop: '10pt',
    marginBottom: '10pt',
    '@media print': {
      border: 'none',
      padding: 0,
    },
  },
  section: {
    display: 'flex',
    breakInside: 'avoid',
  },
  wrapper: {
    display: 'flex',
  },
  vehicle__identification: {
    marginRight: 20,
  },
  insert: {
    marginTop: 20,
    padding: 10,
    border: '2px solid lightgrey',
  },
})

type CommentStatus =
  | 'general_status'
  | 'mechanical_status'
  | 'crashed_status'
  | 'bodywork_status'
  | 'send_in_fleet_status'
  | 'update_fleet'

const getComment = (comments: Object[], status: CommentStatus) => {
  const comment = comments.find(c => c.type === status)

  return comment ? comment.content : null
}

const getComments = (comments: Object[], printField: PrintMode) => {
  if (printField === PRINT_MODE.light) {
    return {
      mechanical: getComment(comments, 'mechanical_status'),
      bodywork: getComment(comments, 'bodywork_status'),
    }
  }
  return {
    general: getComment(comments, 'general_status'),
    mechanical: getComment(comments, 'mechanical_status'),
    crashed: getComment(comments, 'crashed_status'),
    sentInFleet: getComment(comments, 'send_in_fleet_status'),
    updateFleet: getComment(comments, 'update_fleet'),
    bodywork: getComment(comments, 'bodywork_status'),
  }
}

const getCommentsFiltered = (comments: Object, t: Function) =>
  Object.keys(comments)
    .map(key => ({
      name: t(`quote.print.comments.${key}`),
      value: comments[key],
    }))
    .filter(element => Boolean(element.value))

const getDefaultOptions = id => ({
  id,
  ownerName: '',
  ownerFirstname: '',
  commitmentPrice: '',
  mode: DEFAULT_PRINT_MODE,
  isChecked: false,
  usePrintQuote: false,
  vehicleOption: false,
})

const usePrintQuote = (
  options: {
    id: string,
    isChecked: boolean,
    mode: PrintMode,
    isFromCloseQuoteStep?: boolean,
    vehicleOptions?: boolean,
  },
  dispatch: Function,
) => {
  const [printField, setPrintField] = useState(options?.mode ?? DEFAULT_PRINT_MODE)
  const [isChecked, setIsChecked] = useState(options.isChecked)
  const [isCheckedVehicleOptions, setIsCheckedVehicleOptions] = useState(
    options.vehicleOptions ?? false,
  )

  const { t } = useTranslation()
  const { data: quote, loading: isLoadingQuoteData } = useQuery(getQuoteAndCountQuery, {
    variables: { id: options.id },
    onCompleted: response => {
      dispatch(setMode(response?.get_indexed_quote.category))
    },
  })
  const { data: commentsData, loading: isLoadingCommentData } = useQuery(getCommentsQuery, {
    variables: {
      filters: { quote: options.id, threadType: 'enquirer' },
    },
  })

  const comments = getComments(commentsData?.search_comment?.results ?? [], printField)
  const commentsFiltered = getCommentsFiltered(comments, t)

  return {
    isLoading: isLoadingQuoteData || isLoadingCommentData,
    quote: quote?.get_indexed_quote,
    comments: commentsFiltered,
    hasComments: commentsFiltered.length > 0,
    printField,
    isChecked,
    isFromCloseQuoteStep: options.isFromCloseQuoteStep ?? false,
    setPrintField,
    setIsChecked,
    isCheckedVehicleOptions,
    setIsCheckedVehicleOptions,
  }
}

type PrintQuotePageProps = {
  classes: Object,
  routerHistory: Object,
  user?: ?Object,
  mode: Mode,
  dispatch: Function,
  match: {
    params: {
      id: string,
    },
  },
}

const PrintQuotePage = ({
  classes,
  routerHistory,
  user,
  mode,
  dispatch,
  match,
}: PrintQuotePageProps) => {
  const options = routerHistory?.location?.state?.options ?? getDefaultOptions(match.params.id)

  const {
    isLoading,
    quote,
    comments,
    hasComments,
    printField,
    isChecked,
    isFromCloseQuoteStep,
    setPrintField,
    setIsChecked,
    isCheckedVehicleOptions,
    setIsCheckedVehicleOptions,
  } = usePrintQuote(options, dispatch)

  const {
    quoter,
    quoter_marged: quoterMarged,
    pending_quoter_marged: pendingQuoterPrice,
    damage: damagePrice,
    mechanical: mechanicalPrice,
    excess_mileage: excessMileagePrice,
    spot: spotPrice,
    spot_enquirer: spotEnquirerPrice,
    b2c_group: b2cGroup,
    b2c_advised: b2cAdvisedPrice,
    bodywork,
    tradein_price: tradeinPrice,
  } = getPrices(
    [
      'quoter',
      'quoter_marged',
      'pending_quoter_marged',
      'damage',
      'mechanical',
      'excess_mileage',
      'spot',
      'spot_enquirer',
      'b2c_group',
      'b2c_advised',
      'bodywork',
      'tradein_price',
    ],
    quote?.prices,
    user?.config?.pricing?.display,
    user?.rights,
  )

  const isDisplayPendingData = mode === MODES.resval && quote?.status === QUOTE_STATUS_UPDATED_FLEET
  const quoterPrice = isDisplayPendingData ? pendingQuoterPrice : quoterMarged
  const isDisplayVehicleOwner = options.ownerName !== '' && options.ownerFirstname !== ''
  const tags = quote?.tags
  const deliveryDate =
    mode === MODES.resval ? quote?.contractDateStart?.date : quote?.deliveryDate?.date
  const offerDeadline = deliveryDate
    ? getFormattedDateByCountryCode(deliveryDate, language)
    : NO_VALUE
  const vehicleOptions = quote?.options ?? []

  if (isLoading) {
    return <LoadingPage noDelay />
  }

  return (
    <Grid container className={classes.container}>
      <PrintAndSettingsButtons
        isChecked={isChecked}
        isFromCloseQuoteStep={isFromCloseQuoteStep}
        setIsChecked={setIsChecked}
        setPrintField={setPrintField}
        options={options}
        vehicleOptions={vehicleOptions}
        isCheckedVehicleOptions={isCheckedVehicleOptions}
        setIsCheckedVehicleOptions={setIsCheckedVehicleOptions}
      />

      <Grid item xs={12} className={classes.print}>
        <Header quote={quote} mode={mode} />
        <Grid item xs={12} className={classes.section}>
          <Grid item xs={7} className={classes.vehicle__identification}>
            <VehicleIdentification printField={printField} quote={quote} mode={mode} />
          </Grid>
          <Grid item xs={5}>
            {isDisplayVehicleOwner && (
              <VehicleOwner lastname={options.ownerName} firstname={options.ownerFirstname} />
            )}

            {tags && <MoreInformation tags={tags} />}

            {mode !== MODES.standard_bike && <Damages quote={quote} />}
          </Grid>
        </Grid>

        {isCheckedVehicleOptions && (
          <Grid item xs={12} className={classes.section}>
            <VehicleOptions options={vehicleOptions} />
          </Grid>
        )}

        <Grid item xs={12} className={classes.section}>
          <Grid item xs={7} style={{ display: 'flex' }}>
            {mode !== MODES.resval && (
              <Grid item xs={5}>
                <RestorationCosts
                  mode={mode}
                  printField={printField}
                  quoterPrice={quoterPrice?.amount}
                  damagePrice={damagePrice?.amount ?? 0}
                  mechanicalPrice={mechanicalPrice?.amount ?? 0}
                  bodyworkPrice={bodywork?.amount ?? 0}
                />
              </Grid>
            )}
            <Grid item xs={mode === MODES.resval ? 12 : 7}>
              <Dates quote={quote} mode={mode} printField={printField} />
            </Grid>
          </Grid>

          <Grid item xs={5} className={classes.wrapper}>
            <Grid item xs={6}>
              <Mileage quote={quote} mode={mode} printField={printField} />
            </Grid>
            <Grid item xs={6}>
              <LoadedPictures quote={quote} mode={mode} printField={printField} />
            </Grid>
          </Grid>
        </Grid>

        {hasComments && (
          <Grid item xs={12} className={classes.section}>
            <Grid item xs={12}>
              <Comments comments={comments} />
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} className={classes.section}>
          <Grid item xs={4}>
            {mode === MODES.resval ? (
              <RestorationCosts
                mode={mode}
                printField={printField}
                quoterPrice={quoterPrice?.amount}
                damagePrice={damagePrice?.amount ?? 0}
                mechanicalPrice={mechanicalPrice?.amount ?? 0}
                excessMileagePrice={excessMileagePrice?.amount ?? 0}
              />
            ) : (
              <CommercialProposition
                price={options.commitmentPrice}
                offerDeadline={offerDeadline}
              />
            )}
          </Grid>
          {isChecked && printField === PRINT_MODE.light && (
            <Grid item xs={8}>
              <Signature mode={mode} />
            </Grid>
          )}
          {mode !== MODES.resval && printField === PRINT_MODE.full && (
            <Grid item xs={8}>
              <Valuation
                printField={printField}
                mode={mode}
                rotation={quote.spotRotation}
                rotationEnquirer={quote.spotRotationEnquirer}
                prices={{
                  spot: spotPrice?.amount,
                  spotEnquirer: spotEnquirerPrice?.amount,
                  b2c: b2cGroup?.amount,
                  b2cAdvised: b2cAdvisedPrice?.amount,
                  quoterPrice: quoterPrice?.amount,
                  quoterPriceUnmarked: quoter?.amount,
                  tradeinPrice: tradeinPrice?.amount,
                }}
              />
            </Grid>
          )}
          {mode === MODES.resval && printField === PRINT_MODE.full && (
            <Grid item xs={8} className={classes.insert}>
              <Insert
                printField={printField}
                mode={mode}
                prices={{
                  quoterPrice: quoterPrice?.amount,
                  damagePrice: damagePrice?.amount ?? 0,
                  mechanicalPrice: mechanicalPrice?.amount ?? 0,
                }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default (compose(
  withStyles(styles),
  withRedux(({ user, router }) => {
    const { currentMode } = getModesData(user, router.location.pathname)

    return {
      printSettingFieldsPreset: user?.data?.printSettingFieldsPreset,
      user: user.data,
      mode: currentMode,
    }
  }),
)(PrintQuotePage): any)
