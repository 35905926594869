// @flow

import React from 'react'

import NewPasswordPage from '@autodisol/ads-js/components/NewPasswordPage'
import axios from 'axios'
import queryString from 'query-string'
import { getGlobal } from 'sharyn/util/global'
import { connect as withRedux } from 'react-redux'

import { updatePasswordMutation } from 'auth/auth-queries'

const setPasswordCall = async (newPassword: string, token: string) => {
  try {
    const { data } = await axios({
      method: 'post',
      baseURL: getGlobal('store').getState().env.API_URL,
      url: '/v1/graphql',
      headers: { 'X-RESET-TOKEN': token, 'Content-Type': 'application/json' },
      data: { query: updatePasswordMutation, variables: { newPassword } },
    })
    // eslint-disable-next-line camelcase
    return !!data?.data?.update_password
  } catch {
    return false
  }
}

const NewPasswordPageWrapper = ({
  match,
  routerHistory,
  appHost,
  appVersion,
  helpInfo,
}: Object) => (
  <NewPasswordPage
    {...{ setPasswordCall, appVersion, helpInfo }}
    isNeutral={window.location.hostname !== appHost}
    token={decodeURIComponent(match?.params?.token)}
    expiration={queryString.parse(routerHistory.location.search).expiration}
  />
)

export default (withRedux(({ env, ui }) => ({
  appHost: env.APP_ADS_HOST,
  appVersion: env.APP_VERSION,
  helpInfo: ui?.icons?.help,
}))(NewPasswordPageWrapper): any)
