// @flow

import React from 'react'
import Divider from '@material-ui/core/Divider'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = () => ({
  divider: {
    margin: '1.5rem 0',
    width: '100%',
  },
})

type Props = {
  classes: Object,
}

const MyDivider = ({ classes }: Props) => <Divider className={classes.divider} />

export default (withStyles(styles)(MyDivider): any)
