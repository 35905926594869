// @flow

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { MODES } from 'utils/constants/modes'
import Grid from '@material-ui/core/Grid'

import type { Mode } from 'types/modes'

const styles = () => ({
  header: {
    marginBottom: '10pt',
    fontSize: '0.75rem',
    fontWeight: 700,
  },
  vehicle__sheet: {
    textAlign: 'center',
    lineHeight: 1.3,
    '& > h2': {
      fontSize: '0.875rem',
      textTransform: 'uppercase',
      margin: 0,
      fontWeight: 500,
    },
    '& > p': {
      fontWeight: 900,
      fontStyle: 'italic',
      margin: 0,
    },
  },
  print__date: {
    textAlign: 'right',
  },
})

type Props = {
  classes: Object,
  mode: Mode,
  quote?: Object,
}

const Header = ({ classes, mode, quote }: Props) => {
  const { t } = useTranslation()
  const fullname = quote?.contact?.fullname || quote?.enquirer?.fullname || ''
  return (
    <Grid container justify="space-between" className={classes.header}>
      <Grid item xs={3}>
        {quote?.enquirerGroup?.name}
        <br />
        {fullname}
        {mode === MODES.resval && (
          <>
            <br />
            {quote?.reference}
          </>
        )}
      </Grid>
      <Grid item xs={6} className={classes.vehicle__sheet}>
        <h2>{t('quote.print.vehicleSheet')}</h2>
        <p>
          {quote?.make} {quote?.model} {quote?.version} {quote?.trimLevel}
        </p>
      </Grid>
      <Grid item xs={3} className={classes.print__date}>
        {t('quote.print.date')}
        <br />
        {moment().format('DD/MM/YYYY HH:mm')}
      </Grid>
    </Grid>
  )
}

export default (withStyles(styles)(Header): any)
