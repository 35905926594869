// @flow

import React from 'react'
import { printSettingsPreset } from 'quote/quote-constant'
import { useTranslation } from 'react-i18next'
import { getFielsToDisplay, numberFormat } from 'quote/cmp/PrintQuote/functions'
import withStyles from '@material-ui/core/styles/withStyles'
import ListItem from 'quote/cmp/PrintQuote/ListItem'
import SectionTitle from 'quote/cmp/PrintQuote/SectionTitle'

import type { PrintField } from 'types/print'
import type { Mode } from 'types/modes'

const styles = () => ({
  list: {
    listStyleType: 'none',
    paddingLeft: 0,
  },
})

type Props = {
  classes: Object,
  printField: PrintField,
  mode: Mode,
  rotation?: number,
  rotationEnquirer: number,
  prices: {
    spot?: number,
    spotEnquirer?: number,
    b2c?: number,
    b2cAdvised?: number,
    quoterPrice?: number,
    quoterPriceUnmarked?: number,
    tradeinPrice?: number,
  },
}
const Valuation = ({ classes, printField, mode, rotation, rotationEnquirer, prices }: Props) => {
  const { t } = useTranslation()

  const currentPrintSettingsPreset = printSettingsPreset[printField]
  const fields = currentPrintSettingsPreset?.valuation?.fields
  const spotRotation = numberFormat(rotationEnquirer ?? rotation)
  const standard = {
    spot: {
      name: t('quote.print.valuation.spot'),
      value: numberFormat(prices.spotEnquirer ?? prices.spot, 'currency'),
      typeValue: 'price',
    },
    spotRotation: {
      name: t('quote.print.valuation.rotation'),
      value: spotRotation
        ? `${spotRotation} ${Number(spotRotation) > 1 ? t('globals.days') : t('globals.day')}`
        : null,
      typeValue: 'day',
    },
    btocPrice: {
      name: t('quote.print.valuation.recommendedRetailPrice'),
      value: numberFormat(prices.b2c, 'currency'),
      typeValue: 'price',
    },
    btocAdvised: {
      name: t('quote.print.valuation.recommendedPurchasePrice'),
      value: numberFormat(prices.b2cAdvised, 'currency'),
      typeValue: 'price',
    },
    quoterPrice: {
      name: t('quote.commitmentPrice'),
      value: numberFormat(prices.quoterPrice, 'currency'),
      typeValue: 'price',
    },
    quoterPriceUnmarked: {
      name: t('quote.quoterPrice'),
      value: numberFormat(prices.quoterPriceUnmarked, 'currency'),
      typeValue: 'price',
    },
    tradeinPrice: {
      name: t('quote.actualPurchasePrice.title'),
      value: numberFormat(prices.tradeinPrice, 'currency'),
      typeValue: 'price',
    },
  }
  const data = {
    standard: { ...standard },
    resval: {},
    standard_bike: { ...standard },
  }

  const fieldsToDisplay = getFielsToDisplay(fields, data[mode])
  const fieldsToDisplayFiltered = fieldsToDisplay.filter(element => element.value !== null)

  if (!fieldsToDisplayFiltered.length) return null

  return (
    <>
      <SectionTitle title={t('quote.print.valuation.title')} />

      <ul className={classes.list}>
        {fieldsToDisplayFiltered.map(({ name, value, typeValue }) => (
          <ListItem
            key={name}
            name={{ value: name, style: { width: '40%' } }}
            content={{ value }}
            variant="secondary"
            typeValue={typeValue}
          />
        ))}
      </ul>
    </>
  )
}

export default (withStyles(styles)(Valuation): any)
