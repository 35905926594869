// @flow

import React from 'react'
import SectionTitle from 'quote/cmp/PrintQuote/SectionTitle'
import { useTranslation } from 'react-i18next'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = () => ({
  container: {
    paddingLeft: '0',
    fontWeight: 600,
    gap: '10px',
    '& > li': {
      display: 'inline',
      marginRight: 10,
    },
    '& > li::before': {
      content: "'•'",
      left: 0,
      color: '#414b56',
      marginRight: 10,
    },
    '& > li:first-child::before': {
      display: 'none',
    },
  },
})

type Props = {
  classes: Object,
  options: { name: string }[],
}

const VehicleOptions = ({ classes, options }: Props) => {
  const { t } = useTranslation()

  return (
    <div>
      <SectionTitle title={t('quote.print.vehicleOptions.title')} />

      <ul className={classes.container}>
        {options.map(({ name }) => (
          <li key={name}>{name}</li>
        ))}
      </ul>
    </div>
  )
}

export default (withStyles(styles)(VehicleOptions): any)
