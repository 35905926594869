// @flow

import React from 'react'
import List from '@autodisol/ads-js/components/List'
import numeral from 'numeral'
import { useTranslation } from 'react-i18next'

type Props = {
  price?: Object,
  style?: Object,
}

const CommercialPropositionPrice = ({ price, style }: Props): React$Element<any> | null => {
  const { t } = useTranslation()

  return price && price?.amount ? (
    <div>
      <List
        style={style}
        data={[
          {
            title: `${t('quote.commercialProposition')}: `,
            content: price?.amount ? `${numeral(price.amount).format()} €` : '—',
          },
        ]}
      />
    </div>
  ) : null
}

export default CommercialPropositionPrice
