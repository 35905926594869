// @flow

export const EXPORT_LIST_PATH = '/export-list'
export const NEW_QUOTE_PATH = '/new-quote'
export const SEARCH_PATH = '/search'
export const IDENTIFY_PATH = '/identify'

export const editQuotePath = (id: ?string): string => `/edit-quote/${id ?? ':id'}`

export const quotePath = (id: ?string): string => `/quote/${id ?? ':id'}`

export const quotePathValidate = (id: ?string): string => `/valid-quote/${id ?? ':id'}`

export const quotePathReject = (id: ?string): string => `/reject-quote/${id ?? ':id'}`

export const printQuotePath = (id: ?string): string => `/quote/${id ?? ':id'}/print`
