// @flow

import FakeClientErrorPage from 'error/cmp-page/FakeClientErrorPage'
import NotFoundPage from 'error/cmp-page/NotFoundPage'
import { FAKE_CLIENT_ERROR_PATH } from 'error/error-paths'

export const notFoundRoute = {
  pageComponent: NotFoundPage,
  title: 'Page introuvable',
}

export const fakeClientErrorRoute = {
  path: FAKE_CLIENT_ERROR_PATH,
  exact: true,
  pageComponent: FakeClientErrorPage,
  title: 'Fausse erreur',
}
