// STATUS
export const BEFORE_FLEET = [
  'draft',
  'waiting',
  'quoted',
  'accepted',
  'rejected',
  'changes_requested',
]

export const DOCUMENT_TYPE = {
  DMG: 'DMG',
  PHOTO: 'PHOTO',
  DMG_LIST_REPORT: 'DMG_LIST_REPORT',
  REPAIR_COST_REPORT: 'REPAIR_COST_REPORT',
  AUTORIGIN: 'AUTORIGIN',
  RADAR: 'RADAR',
  RADAR_ENQUIRER: 'RADAR_ENQUIRER',
  REG_CARD: 'REG_CARD',
  TECH_CHECK: 'TECH_CHECK',
}

export const MAX_VIN_LENGTH = 17
