// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import Modal from 'Modal'
import Button from '@autodisol/ads-js/components/CustomButton'

const styles = ({ breakpoints }) => ({
  content: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '.875em',
    padding: '20px',
    [breakpoints.up('sm')]: {
      width: 500,
      padding: '20px 0',
    },
  },
  content__elements: {
    listStylePosition: 'inside',
    listStyleType: "'- '",
    paddingLeft: 0,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    '& button': {
      margin: '0 20px 10px',
    },
    [breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      '& button': {
        padding: '10px 40px',
        margin: 0,
      },
      '& button:first-child': {
        marginRight: 10,
      },
      '& button:last-child': {
        marginLeft: 10,
      },
    },
  },
})

type Props = {
  classes: Object,
  handleConfirm: () => void,
  handleCloseModal: () => void,
}

const Confirmation = ({ classes, handleConfirm, handleCloseModal }: Props) => {
  const { t } = useTranslation()
  return (
    <>
      <Modal.Header>{t('quote.duplicate.request')}</Modal.Header>
      <Modal.Content>
        <div className={classes.content}>
          <p>
            {t('quote.duplicate.question')} <br /> {t('quote.duplicate.editableInformation')}
          </p>
          <ul className={classes.content__elements}>
            <li>{t('quote.kmToStart')}</li>
            <li>{t('quote.kmToGo')}</li>
            <li>{t('quote.contractDateStart')}</li>
            <li>{t('quote.remainingContractDuration')}</li>
          </ul>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <div className={classes.footer}>
          <Button
            colorType="secondary"
            height="short"
            variant="contained"
            onClick={handleCloseModal}
          >
            {t('globals.cancel')}
          </Button>
          <Button colorType="green" height="short" variant="contained" onClick={handleConfirm}>
            {t('globals.confirm')}
          </Button>
        </div>
      </Modal.Actions>
    </>
  )
}

export default (withStyles(styles)(Confirmation): any)
