// @flow

import React, { useState } from 'react'
import { Grid, FormControlLabel, Checkbox, Typography, RadioGroup, Radio } from '@material-ui/core'
import Button from '@autodisol/ads-js/components/CustomButton'
import withStyles from '@material-ui/core/styles/withStyles'
import { useTranslation } from 'react-i18next'
import { connect as withRedux } from 'react-redux'
import { getModesData } from 'utils/modes'
import { updateExportSelection } from '_client/redux/actions'
import { EXPORT_LIST_PATH } from 'quote/quote-paths'
import { exportQuotesQuery } from 'quote/quote-queries'
import { useMutation } from '@apollo/client'
import spread from 'sharyn/util/spread'
import { getFields } from 'quote/functions'
import { MODES } from 'utils/constants/modes'
import { getLanguage } from 'utils/language'

type Props = {
  dispatch: Function,
  history: Object,
  nbResults?: number,
  field: Function,
  mode: string,
  categories: string[],
  exportSelection: Object,
  search: string | null,
  searchParams: Object | null,
}

const ExportCmp = ({
  dispatch,
  history,
  nbResults,
  field,
  mode,
  categories,
  exportSelection,
  search,
  searchParams,
}: Props) => {
  const { t } = useTranslation()

  const [exportKeepLines, setExportKeepLines] = useState('no')

  const [getExport, { loading: isExportLoading }] = useMutation(exportQuotesQuery, {
    onCompleted: () => {
      history.push(EXPORT_LIST_PATH)
    },
  })

  const nbSelectedFields = Object.values(exportSelection).reduce((a, item) => a + Number(item), 0)
  const selectedColumnToExport = Object.keys(exportSelection).filter(
    items => exportSelection[items],
  )

  const exportHandler = () => {
    getExport({
      variables: {
        filters: {
          ...(search && { query: search }),
          ...(searchParams && searchParams),
          categories,
          ...spread({
            ...getFields(field, mode),
          }),
        },
        fieldGroups: selectedColumnToExport,
        removeEmptyColumns: exportKeepLines !== 'yes',
        locale: getLanguage(),
      },
    })
  }

  const changeSelectedField =
    ({ selectionName }) =>
    checked => {
      const _exportSelection = { ...exportSelection, [selectionName]: checked }
      dispatch(updateExportSelection({ [mode]: _exportSelection }))
    }

  return (
    <Grid container>
      <Grid item>
        <Typography variant="body1" style={{ fontSize: '1.1rem' }}>
          {t('export.fieldsToExport')}
        </Typography>
      </Grid>
      <Grid container xs={12} style={{ marginTop: 15, marginBottom: 30 }}>
        {Object.keys(exportSelection).map(selectionName => (
          <Grid item xs={12} md={3} key={selectionName}>
            <FormControlLabel
              control={<CheckboxStyle checked={exportSelection[selectionName]} />}
              onChange={(_, checked) => {
                changeSelectedField({ selectionName })(checked)
              }}
              label={
                selectionName === 'delivery'
                  ? `Km / ${t(`export.${selectionName}`)}`
                  : t(`export.${selectionName}`)
              }
            />
          </Grid>
        ))}
      </Grid>
      <Grid item>
        <Typography variant="body1">{t('export.exportKeepLines')}</Typography>
      </Grid>
      <Grid container xs={12} style={{ marginTop: 15, marginBottom: 30 }}>
        <RadioGroup
          value={exportKeepLines}
          row
          onChange={e => {
            setExportKeepLines(e.target.value)
          }}
        >
          <FormControlLabel
            value="yes"
            control={<Radio color="secondary" />}
            label={t('globals.yes')}
          />
          <FormControlLabel
            value="no"
            control={<Radio color="secondary" style={{ marginLeft: 80 }} />}
            label={t('globals.no')}
          />
        </RadioGroup>
      </Grid>
      <Grid container justify="space-between" style={{ width: '20rem', margin: 'auto' }}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            height="short"
            isWidthLimited
            isLoading={isExportLoading}
            disabled={nbResults === 0 || !nbResults || nbSelectedFields === 0}
            onClick={exportHandler}
          >
            {t('export.exportSelection')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            colorType="primary"
            height="short"
            isWidthLimited
            isLoading={isExportLoading}
            disabled={nbResults === 0 || !nbResults}
            onClick={exportHandler}
          >
            {t('export.exportResults')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

const CheckboxStyle = withStyles(theme => ({
  root: {
    padding: '5px 5px 5px 15px',
    color: theme.palette.secondary.light,
    '&$checked': {
      color: theme.palette.secondary.dark,
    },
  },
  checked: {},
}))(Checkbox)

export default (withRedux(({ user, router }) => {
  const { currentMode } = getModesData(user, router.location.pathname)

  return {
    mode: currentMode,
    exportSelection: user?.data?.exportSelection?.[currentMode] ?? {},
    categories: currentMode ? [currentMode] : [MODES.standard],
  }
})(ExportCmp): any)
