// @flow

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { useTranslation } from 'react-i18next'

const styles = () => ({
  expired: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
})

type Props = {
  classes: Object,
}

const ExpiredQuotation = ({ classes }: Props) => {
  const { t } = useTranslation()

  return <p className={classes.expired}>{t('quote.expired')}</p>
}

export default (withStyles(styles)(ExpiredQuotation): any)
