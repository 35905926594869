// @flow
import React, { useState } from 'react'
import compose from 'recompose/compose'
import { connect as withRedux } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { Link, withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@autodisol/ads-js/components/CustomButton'
import Add from '@material-ui/icons/Add'
import AssignmentOutlined from '@material-ui/icons/AssignmentOutlined'
import { IDENTIFY_PATH, SEARCH_PATH } from 'quote/quote-paths'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import { getModesData } from 'utils/modes'
import { findFacetsQuery } from 'home/home-queries'
import { findFacets } from '_client/util'
import { MODES } from 'utils/constants/modes'
import FleetButton from 'home/cmp/FleetButton'

import type { Mode } from 'types/modes'

import CorrectionIcon from '../../../public/img/CorrectionIcon.svg'
import DraftIcon from '../../../public/img/DraftIcon.svg'
import WaitAssignValidIcon from '../../../public/img/WaitAssignValidIcon.svg'

const styles = ({ palette, breakpoints }) => ({
  homeIcon: { position: 'absolute', left: 30 },
  homeButtonBadge: {
    width: '2rem',
    minWidth: '2rem',
    height: '2rem',
    borderRadius: '50%',
    background: 'white',
    color: palette.secondary.dark,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  homeButtonText: {
    flexGrow: 1,
    textAlign: 'left',
    marginLeft: 70,
    whiteSpace: 'pre-line',
    [breakpoints.up('sm')]: {
      whiteSpace: 'normal',
    },
    [breakpoints.up('md')]: {
      whiteSpace: 'pre-line',
    },
  },
})

type Props = {
  classes: Object,
  history: Object,
  hasRightsToMakeNewRequests: boolean,
  hasRightsToValidAssignQuote: boolean,
  mode: Mode,
  query: {
    categories: string[],
  },
}

const ModeComponent = ({
  classes,
  history,
  hasRightsToMakeNewRequests,
  hasRightsToValidAssignQuote,
  mode,
  query,
}: Props) => {
  const { t } = useTranslation()
  const [count, setCount] = useState({
    monitoring: 0,
    drafts: 0,
    corrections: 0,
    waitAssignValid: 0,
  })
  const handleClick = (pathname, state) => () => {
    history.push({
      pathname,
      state,
    })
  }

  const { loading: isLoadingFirstQuery } = useQuery(findFacetsQuery, {
    variables: {
      filters: {
        onlyCount: true,
        categories: query.categories,
      },
    },
    onCompleted: resp => {
      const formattedFacets: Object = findFacets(resp.search_quote?.facets)

      setCount(state => ({
        ...state,
        drafts: formattedFacets.status?.draft ?? 0,
        corrections: formattedFacets.status?.changes_requested ?? 0,
        waitAssignValid: formattedFacets.status?.wait_assign_valid ?? 0,
      }))
    },
  })
  const { loading: isLoadingSecondQuery } = useQuery(findFacetsQuery, {
    variables: {
      filters: {
        onlyCount: true,
        categories: query.categories,
        status: ['waiting', 'quoted'],
        updatedAfter: {
          date: moment().utcOffset(0, true).format('YYYY-MM-DD'),
          timezone: 'Europe/Paris',
        },
      },
    },
    onCompleted: resp => {
      const formattedFacets: Object = findFacets(resp.search_quote?.facets)

      setCount(state => ({
        ...state,
        monitoring:
          (formattedFacets?.status?.waiting ?? 0) + (formattedFacets?.status?.quoted ?? 0),
      }))
    },
  })

  const isLoading = isLoadingFirstQuery || isLoadingSecondQuery

  return (
    <Grid container spacing={32}>
      {hasRightsToMakeNewRequests && (
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            colorType="primary"
            height="tall"
            component={Link}
            to={IDENTIFY_PATH}
            fullWidth
            style={{ minHeight: '100%' }}
          >
            <Add className={classes.homeIcon} style={{ fontSize: '2rem' }} />
            <span className={classes.homeButtonText}>{t(`home.newRequest.${mode}`)}</span>
          </Button>
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <Button
          colorType="secondary"
          height="tall"
          variant="contained"
          onClick={handleClick(SEARCH_PATH, { initialSearchState: 'monitoring' })}
          fullWidth
        >
          <AssignmentOutlined className={classes.homeIcon} style={{ fontSize: '2rem' }} />
          <span className={classes.homeButtonText}>{t(`home.quotesMonitoring.${mode}`)}</span>
          <Typography variant="button" component="div" className={classes.homeButtonBadge}>
            {count.monitoring}
          </Typography>
        </Button>
      </Grid>
      {mode === MODES.resval && (
        <Grid item xs={12} md={6}>
          <FleetButton categories={query.categories} />
        </Grid>
      )}

      <Grid item xs={12} md={6}>
        <Button
          colorType="secondary"
          height="tall"
          variant="contained"
          onClick={handleClick(SEARCH_PATH, { initialSearchState: 'drafts' })}
          fullWidth
          isLoading={isLoading}
          disabled={count.drafts === 0}
        >
          <DraftIcon className={classes.homeIcon} style={{ width: '2rem', fill: 'white' }} />
          <span className={classes.homeButtonText}>{t('home.drafts')}</span>
          <Typography variant="button" component="div" className={classes.homeButtonBadge}>
            {count.drafts}
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <Button
          colorType="secondary"
          height="tall"
          variant="contained"
          onClick={handleClick(SEARCH_PATH, { initialSearchState: 'corrections' })}
          fullWidth
          isLoading={isLoading}
          disabled={count.corrections === 0}
        >
          <CorrectionIcon className={classes.homeIcon} style={{ width: '2rem', fill: 'white' }} />
          <span className={classes.homeButtonText}>{t('home.correctionRequests')}</span>
          <Typography variant="button" component="div" className={classes.homeButtonBadge}>
            {count.corrections}
          </Typography>
        </Button>
      </Grid>
      {hasRightsToValidAssignQuote && (
        <Grid item xs={12} md={6}>
          <Button
            colorType="secondary"
            height="tall"
            variant="contained"
            onClick={handleClick(SEARCH_PATH, { initialSearchState: 'wait_assign_valid' })}
            fullWidth
            isLoading={isLoading}
            disabled={count.waitAssignValid === 0}
          >
            <WaitAssignValidIcon
              className={classes.homeIcon}
              style={{ width: '2rem', fill: 'white' }}
            />
            <span className={classes.homeButtonText}>{t('quote.waitAssignValidButton')}</span>
            <Typography variant="button" component="div" className={classes.homeButtonBadge}>
              {count.waitAssignValid}
            </Typography>
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export default (compose(
  withRedux(({ user, router }) => {
    const { rights } = user.data
    const { currentMode } = getModesData(user, router.location.pathname)

    return {
      hasRightsToValidAssignQuote: rights.includes('valid_assign_quote'),
      hasRightsToMakeNewRequests:
        Boolean(rights.find(right => right === 'identify')) &&
        Boolean(rights.find(right => right === 'create_quote')) &&
        user.data.config.quote.quote[currentMode]?.activated !== false &&
        user.data.config.quote.quote[currentMode]?.readonly !== true,
      mode: currentMode,
    }
  }),
  withStyles(styles),
  withRouter,
)(ModeComponent): any)
