// @flow

import React, { useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { useTranslation } from 'react-i18next'
import { printSettingsPreset } from 'quote/quote-constant'
import Button from '@autodisol/ads-js/components/CustomButton'
import PrintIcon from '@material-ui/icons/Print'
import SettingsIcon from '@material-ui/icons/Build'
import Modal from 'Modal'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Link from 'react-router-dom/Link'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { quotePath } from 'quote/quote-paths'
import { PRINT_MODE } from 'quote/cmp/PrintQuote/constants'

const FORM_ID = 'printSettings'

const styles = ({ palette }) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '@media print': {
      display: 'none',
    },
  },
  rootButton: {
    padding: '10pt',
  },
  outlinedButton: {
    border: `2px solid ${palette.secondary.dark}`,
  },
  subTitle: {
    textTransform: 'lowercase',
  },
  icon: {
    marginRight: '5pt',
  },
})

type Props = {
  classes: Object,
  isChecked: boolean,
  isFromCloseQuoteStep: boolean,
  setIsChecked: Function,
  setPrintField: Function,
  options: { id: string, mode: 'light' | 'full' },
  vehicleOptions: { name: string, price: number }[],
  isCheckedVehicleOptions: boolean,
  setIsCheckedVehicleOptions: Function,
}

const PrintAndSettingsButtons = ({
  classes,
  isChecked,
  isFromCloseQuoteStep,
  setIsChecked,
  setPrintField,
  options,
  vehicleOptions,
  isCheckedVehicleOptions,
  setIsCheckedVehicleOptions,
}: Props) => {
  const { id, mode } = options

  const { t } = useTranslation()
  const { open, openModal, closeModal } = Modal.useModal()

  const [printMode, setPrintMode] = useState(mode)

  const handleClickPrint = () => {
    window.print()
  }

  const handleSelect = ({ target }) => {
    setPrintMode(target.value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    const checked = e.target?.elements?.requestSign?.checked
    const checkedVehicleOptions = e.target?.elements?.vehicleOptions?.checked

    setPrintField(printMode)

    if (checked !== undefined) setIsChecked(checked)

    if (checkedVehicleOptions !== undefined) setIsCheckedVehicleOptions(checkedVehicleOptions)

    closeModal()
  }

  return (
    <>
      <div className={classes.buttons}>
        {isFromCloseQuoteStep && (
          <Button variant="contained" colorType="secondary" component={Link} to={quotePath(id)}>
            <ArrowBackIosIcon className={classes.icon} />
            {t('quote.print.backToVehicleSheet')}
          </Button>
        )}

        <Button
          variant="contained"
          colorType="secondary"
          onClick={openModal}
          style={{ marginLeft: 'auto', marginRight: 20 }}
        >
          <SettingsIcon className={classes.icon} />
          {t('quote.print.settings')}
        </Button>
        <Button variant="contained" colorType="primary" onClick={handleClickPrint}>
          <PrintIcon className={classes.icon} />
          {t('quote.print.printSheet')}
        </Button>
      </div>

      {open && (
        <Modal
          open={open}
          handleClose={closeModal}
          ariaLabelledby="print settings"
          ariaDescribedby="print settings"
        >
          <Modal.Header>
            {t('quote.print.request')}{' '}
            <Typography variant="caption" className={classes.subTitle}>
              {t('quote.print.fieldsToDisplay')}
            </Typography>
          </Modal.Header>
          <Modal.Content>
            <form id={FORM_ID} onSubmit={handleSubmit}>
              <Select
                value={printMode}
                onChange={handleSelect}
                fullWidth
                MenuProps={{ disablePortal: true }}
                name="printMode"
              >
                {Object.keys(printSettingsPreset).map(groupName => (
                  <MenuItem value={groupName} key={groupName}>
                    {t(`quote.print.settings_${groupName}`)}
                  </MenuItem>
                ))}
              </Select>

              {printMode === PRINT_MODE.light && (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox color="primary" name="requestSign" defaultChecked={isChecked} />
                    }
                    label={t('quote.print.requestSign')}
                  />
                </div>
              )}

              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name="vehicleOptions"
                      disabled={vehicleOptions.length === 0}
                      defaultChecked={isCheckedVehicleOptions}
                    />
                  }
                  label={t('quote.print.vehicleOptions.title')}
                />
              </div>
            </form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              colorType="secondary"
              height="short"
              variant="contained"
              onClick={closeModal}
              style={{ width: 215, marginRight: 4 }}
            >
              {t('globals.cancel')}
            </Button>
            <Button
              form={FORM_ID}
              type="submit"
              colorType="primary"
              height="short"
              variant="contained"
              style={{ width: 215, marginLeft: 4 }}
            >
              {t('globals.validate')}
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </>
  )
}

export default (withStyles(styles)(PrintAndSettingsButtons): any)
