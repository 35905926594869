// @flow

import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import HelpIcon from '@material-ui/icons/Help'
import { useTranslation } from 'react-i18next'
import { getQuestions } from 'quote/cmp/questionList'
import questionsDetails from 'quote/cmp/questionsDetails'
import { MODES } from 'utils/constants/modes'
import { TAG_HAS_VEHICLE_INSPECTION } from '_client/config'
import AddPictureButton from 'quote/cmp/AddPictureButton'
import { DOCUMENT_TYPE } from 'utils/constants/quote'
import Pictures from '@autodisol/ads-js/components/Pictures'
import Modal from 'Modal'
import Button from '@autodisol/ads-js/components/CustomButton'

import type { Mode } from 'types/modes'

const getFormattedQuestionsFromConfiguration = (questionsFromConfiguration: Object[]) =>
  questionsFromConfiguration
    .map(({ name }) => questionsDetails[name] ?? null)
    .filter(element => Boolean(element))

const QuestionsJSX = ({
  classes: css,
  field,
  setField,
  isLoadingRemoveDocument,
  onChange,
  selectedVersion,
  questionsFromConfiguration,
  mode,
  vehicleInspectionDocuments,
  handleAddDocument,
  handleDeleteDocument,
  isLoadingUploadDocument,
}: {
  classes: Object,
  field: Function,
  setField: Function,
  isLoadingRemoveDocument: boolean,
  onChange: Function,
  selectedVersion: Object | null,
  questionsFromConfiguration: Object[],
  mode: Mode,
  vehicleInspectionDocuments: Object[],
  handleAddDocument: Function,
  handleDeleteDocument: Function,
  isLoadingUploadDocument: boolean,
}) => {
  const { t } = useTranslation()
  const { open, openModal, closeModal } = Modal.useModal()

  const formattedQuestions = getFormattedQuestionsFromConfiguration(questionsFromConfiguration)
  const questions = getQuestions(mode)
  const isClickedVehicleInspectionOnTrue = field('hasVehicleInspection') === 'on'
  const hasVehicleInspectionDocument = Boolean(vehicleInspectionDocuments.length)

  const getActiveCallbackParams = () => {
    if (mode === MODES.standard_bike) return { energy: field('energy') }

    return selectedVersion
  }

  const deleteDocumentCallback = () => {
    setField('hasVehicleInspection', 'off')
    closeModal()
  }

  const handleChangeRadioGroup = (tag: string) => e => {
    if (
      tag === TAG_HAS_VEHICLE_INSPECTION &&
      hasVehicleInspectionDocument &&
      e.target.value === 'off'
    ) {
      openModal()
      return
    }

    onChange(e)
  }

  const handleClickConfirmModal = () => {
    const pictureId = vehicleInspectionDocuments[0].id

    handleDeleteDocument(pictureId, deleteDocumentCallback)()
  }

  const handleCloseModal = () => {
    if (isLoadingRemoveDocument) return

    closeModal()
  }

  return (
    <div className={css.container}>
      {questions.map(
        ({ name, tag, label, helper, hasSubQuestions, subQuestions, activeCallback, choices }) => {
          const isDisplayVehicleInspectionElement =
            tag === TAG_HAS_VEHICLE_INSPECTION && isClickedVehicleInspectionOnTrue
          const isVehicleInspectionField = tag === TAG_HAS_VEHICLE_INSPECTION
          const isDisabledVehicleInspectionField =
            isVehicleInspectionField && (isLoadingRemoveDocument || isLoadingUploadDocument)

          return (
            (!activeCallback || (activeCallback && activeCallback(getActiveCallbackParams()))) && (
              <React.Fragment key={name}>
                <FormControl fullWidth margin="dense">
                  <FormLabel component="legend" className={css.questionLabel}>
                    {label}
                    {helper && (
                      <Tooltip title={helper} classes={{ tooltip: css.tooltip }} placement="right">
                        <IconButton className={css.helpIcon}>
                          <HelpIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </FormLabel>
                  {choices ? (
                    <RadioGroup value={field(name)} row {...{ name, onChange }}>
                      {choices.map(choice => (
                        <FormControlLabel
                          value={choice.tag}
                          control={<Radio color="secondary" />}
                          label={choice.label}
                          key={choice.tag}
                          classes={choices.length === 2 && { root: css.rightLabelChoices }}
                        />
                      ))}
                    </RadioGroup>
                  ) : (
                    <>
                      <RadioGroup
                        value={field(name)}
                        row
                        onChange={handleChangeRadioGroup(tag)}
                        name={name}
                      >
                        <FormControlLabel
                          value="on"
                          control={<Radio color="secondary" />}
                          label={t('globals.yes')}
                          disabled={isDisabledVehicleInspectionField}
                        />
                        <FormControlLabel
                          value="off"
                          control={<Radio color="secondary" />}
                          label={t('globals.no')}
                          disabled={isDisabledVehicleInspectionField}
                          classes={{ root: css.rightLabel }}
                        />
                      </RadioGroup>
                      {isDisplayVehicleInspectionElement && (
                        <>
                          <AddPictureButton
                            handleChangeInputFile={handleAddDocument(DOCUMENT_TYPE.TECH_CHECK)}
                            disabled={isLoadingUploadDocument || hasVehicleInspectionDocument}
                            isLoading={isLoadingUploadDocument}
                            accept=".pdf, image/jpg, image/jpeg, fake/mime" // fake/mime is a workaround for bug with latest Android version
                            content={t('quote.addTechnicalControl')}
                            height="short"
                            style={{ margin: '10px 0' }}
                          />

                          <div className={css.documents}>
                            {vehicleInspectionDocuments.map(picture => (
                              <Pictures
                                key={picture.id}
                                pictures={[picture]}
                                deletable
                                maxImagesInARow={1}
                                justify="flex-start"
                                handleDelete={handleDeleteDocument(picture.id)}
                              />
                            ))}
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {name === 'damaged' && field('damaged') === 'on' && (
                    <>
                      <TextField
                        name="crashedComment"
                        label={t('quote.identification.previousAccidentColon')}
                        value={field('crashedComment')}
                        placeholder={t('quote.identification.accidentPlaceholder')}
                        type="text"
                        multiline
                        rows="2"
                        style={{ margin: '20px 0px 30px 0', width: '100%' }}
                        InputLabelProps={{ classes: { root: css.textInputLabel } }}
                        InputProps={{ classes: { root: css.textInput } }}
                        {...{ onChange }}
                        className={css.formControl}
                      />
                      <Typography
                        color="secondary"
                        variant="caption"
                        style={{ position: 'relative', top: '-1.5rem' }}
                      >
                        {t('quote.mandatoryComment')}
                      </Typography>
                    </>
                  )}
                </FormControl>
                {hasSubQuestions &&
                  field('hasChargingCable') === 'on' &&
                  subQuestions.map(({ subName, subLabel }) => (
                    <FormControl fullWidth margin="dense" key={subName}>
                      <FormLabel component="legend" className={css.questionLabel}>
                        {subLabel}
                      </FormLabel>
                      <RadioGroup value={field(subName)} row name={subName} {...{ onChange }}>
                        <FormControlLabel
                          value="on"
                          control={<Radio color="secondary" />}
                          label={t('globals.yes')}
                        />
                        <FormControlLabel
                          value="off"
                          control={<Radio color="secondary" />}
                          label={t('globals.no')}
                          classes={{ root: css.rightLabel }}
                        />
                      </RadioGroup>
                    </FormControl>
                  ))}
              </React.Fragment>
            )
          )
        },
      )}
      {formattedQuestions.map(({ name, label, choices }) => (
        <FormControl fullWidth margin="dense" key={name}>
          <FormLabel component="legend" className={css.questionLabel}>
            {label}
          </FormLabel>
          <RadioGroup value={field(name)} row {...{ name, onChange }}>
            {choices.map(choice => (
              <FormControlLabel
                value={choice.value}
                control={<Radio color="secondary" />}
                label={choice.label}
                key={choice.value}
              />
            ))}
          </RadioGroup>
        </FormControl>
      ))}

      {open && (
        <Modal open={open} handleClose={handleCloseModal}>
          <Modal.Header>{t('quote.removeTechnicalControl')}</Modal.Header>
          <Modal.Content>{t('quote.doYouWantRemoveTechnicalControl')}</Modal.Content>
          <Modal.Actions>
            <div className={css.modal__actions}>
              <Button
                colorType="secondary"
                height="short"
                variant="contained"
                onClick={handleCloseModal}
                disabled={isLoadingRemoveDocument}
                style={{ width: 215, marginRight: 4 }}
              >
                {t('globals.no')}
              </Button>
              <Button
                type="submit"
                colorType="primary"
                height="short"
                variant="contained"
                disabled={isLoadingRemoveDocument}
                isLoading={isLoadingRemoveDocument}
                onClick={handleClickConfirmModal}
                style={{ width: 215, marginLeft: 4 }}
              >
                {t('globals.yes')}
              </Button>
            </div>
          </Modal.Actions>
        </Modal>
      )}
    </div>
  )
}

const Questions: any = withStyles(({ breakpoints }) => ({
  container: {
    [breakpoints.up('md')]: {
      maxWidth: 500,
    },
  },
  questionLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  helpIcon: {
    padding: '0 0 0 5px',
    opacity: '0.8',
    position: 'absolute',
    left: 220,
  },
  tooltip: {
    fontSize: '14px',
  },
  textInput: {
    border: 'none',
    borderRadius: '0px',
    borderBottom: '1px solid black',
    paddingLeft: 5,
    paddingTop: '2rem',
  },
  textInputLabel: {
    transform: 'translate(0, -0.5rem)',
    fontWeight: 900,
  },
  rightLabel: {
    marginLeft: 80,
    color: 'red',
  },
  rightLabelChoices: {
    '&:last-child': {
      marginLeft: 65,
    },
  },
  documents: {
    display: 'flex',
    width: '100%',
    '& > div': {
      margin: 8,
      maxWidth: 145,
    },
  },
  modal__actions: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
}))(QuestionsJSX)

export default Questions
