import React from 'react'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import TimerIcon from '@material-ui/icons/Timer'
import MotorcycleIcon from '@material-ui/icons/Motorcycle'

export const ICONS_COMPONENTS = {
  standard: <LocalOfferIcon />,
  resval: <TimerIcon />,
  standard_bike: <MotorcycleIcon />,
}
