// @flow

import React from 'react'

import withLifecycle from 'recompose/lifecycle'
import Page from 'sharyn/components/Page'

const FakeClientErrorPageJSX = () => (
  <Page middle>Une fausse erreur a été exécutée dans votre console.</Page>
)

const FakeClientErrorPage: any = withLifecycle({
  componentDidMount() {
    setTimeout(() => {
      throw Error('Fake error, everything is fine')
    }, 100)
  },
})(FakeClientErrorPageJSX)

export default FakeClientErrorPage
