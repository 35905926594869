// @flow

import React from 'react'

import Button from '@material-ui/core/Button'
import ErrorIcon from '@material-ui/icons/Error'
import Link from 'react-router-dom/Link'
import { setUi } from 'sharyn/redux/actions'
import { getGlobal } from 'sharyn/util/global'

import { LOGOUT_PATH } from 'auth/auth-paths'
import i18next from 'services/translations/config'
import { setMode } from '_client/redux/actions'
import { MODES } from 'utils/constants/modes'
import { NEW_QUOTE_PATH } from 'quote/quote-paths'

type ErrorIdentifySwitchParams = {
  dispatch: Function,
  dismissFirstNotification: Function,
  routerHistory: Object,
  identifyCountry: string,
}

type ErrorIdentifyCategoryParams = {
  dispatch: Function,
  dismissFirstNotification: Function,
  routerHistory: Object,
  identifiedVehicle: Object,
}

type ErrorIdentifyCategory = {
  title: string,
  message: string,
  icon: any,
  mainAction: any,
}

type ErrorIdentifySwitch = ErrorIdentifyCategory

export const genericErrorNotification: {
  title: string,
  subTitle: string,
  message: string,
} = {
  title: i18next.t('notification.error'),
  subTitle: i18next.t('notification.somethingWentWrong'),
  message: i18next.t('notification.weHaveBeenNotififed'),
}

export const genericLoginErrorNotification: {
  title: string,
  subTitle: string,
  message: string,
} = {
  title: i18next.t('notification.login'),
  subTitle: i18next.t('notification.somethingWentWrong'),
  message: i18next.t('notification.weHaveBeenNotififed'),
}

export const networkErrorNotification: {
  title: string,
  subTitle: string,
  message: string,
} = {
  title: i18next.t('notification.network'),
  subTitle: i18next.t('notification.error'),
  message: i18next.t('notification.networkError'),
}

export const sessionExpiredNotification: {
  title: string,
  subTitle: string,
  message: string,
  dismissable: boolean,
  autoHideDuration: null,
  mainAction: any,
} = {
  title: (i18next.t('notification.login'): string),
  subTitle: (i18next.t('notification.error'): string),
  message: (i18next.t('notification.sessionExpired'): string),
  dismissable: false,
  autoHideDuration: null,
  mainAction: (
    <Button
      color="primary"
      component={Link}
      to={LOGOUT_PATH}
      onClick={() => {
        getGlobal('store').dispatch(setUi({ notifications: [] }))
      }}
    >
      {(i18next.t('auth.logIn'): string)}
    </Button>
  ),
}

export const invalidCredentialsNotification: {
  title: string,
  subTitle: string,
  message: string,
} = {
  title: i18next.t('notification.error'),
  subTitle: i18next.t('notification.error'),
  message: i18next.t('notification.invalidCredentials'),
}

export const errorIdentifyResultNotification: {
  title: string,
  subTitle: string,
  message: string,
  icon: any,
} = {
  title: i18next.t('globals.identification'),
  subTitle: i18next.t('notification.subtitleErrorIdentification'),
  message: i18next.t('notification.messageErrorIdentification'),
  icon: <ErrorIcon />,
}

export const errorIdentifySwitchToManualIdentification = ({
  dispatch,
  dismissFirstNotification,
  routerHistory,
  identifyCountry,
}: ErrorIdentifySwitchParams): ErrorIdentifySwitch => ({
  title: i18next.t('globals.identification'),
  message: i18next.t('quote.identification.notificationIdentificationError'),
  icon: <ErrorIcon />,
  mainAction: (
    <Button
      color="primary"
      onClick={() => {
        dispatch(dismissFirstNotification())
        routerHistory.push({ pathname: NEW_QUOTE_PATH, state: { identifyCountry } })
      }}
    >
      {i18next.t('quote.identification.switchToManualIdentification')}
    </Button>
  ),
})

export const errorIdentifyVehicleCategoryIsCar = ({
  dispatch,
  dismissFirstNotification,
  routerHistory,
  identifiedVehicle,
}: ErrorIdentifyCategoryParams): ErrorIdentifyCategory => ({
  title: i18next.t('globals.identification'),
  message: i18next.t('quote.identification.errors.itsNotACar'),
  icon: <ErrorIcon />,
  mainAction: (
    <Button
      color="primary"
      onClick={() => {
        dispatch(dismissFirstNotification())
        dispatch(setMode(MODES.standard_bike))
        routerHistory.push({ pathname: NEW_QUOTE_PATH, state: { identifiedVehicle } })
      }}
    >
      {i18next.t('quote.identification.switchToStandardBikeQuotation')}
    </Button>
  ),
})

export const errorIdentifyVehicleCategoryIsBike = ({
  dispatch,
  dismissFirstNotification,
  routerHistory,
  identifiedVehicle,
}: ErrorIdentifyCategoryParams): ErrorIdentifyCategory => ({
  title: i18next.t('globals.identification'),
  message: i18next.t('quote.identification.errors.itsNotABike'),
  icon: <ErrorIcon />,
  mainAction: (
    <Button
      color="primary"
      onClick={() => {
        dispatch(dismissFirstNotification())
        dispatch(setMode(MODES.standard))
        routerHistory.push({ pathname: NEW_QUOTE_PATH, state: { identifiedVehicle } })
      }}
    >
      {i18next.t('quote.identification.switchToStandardQuotation')}
    </Button>
  ),
})
