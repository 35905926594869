import i18next from 'i18next'

import {
  TAG_IS_DAMAGED,
  TAG_HAS_VAT,
  TAG_IS_BROKEN,
  TAG_NEEDS_TIMING_BELT,
  TAG_HAS_SERVICE_RECORD,
  TAG_IS_FOREIGN,
  TAG_PREVIOUS_AMBULANCE,
  TAG_PREVIOUS_TAXI,
  TAG_PREVIOUS_DRIVING_SCHOOL,
  TAG_PREVIOUS_LSR_LTR,
  TAG_IS_TESTED,
  TAG_STATE_BAD,
  TAG_STATE_AVERAGE,
  TAG_STATE_GOOD,
  TAG_STATE_VERY_GOOD,
} from '_client/config'

const questions = [
  {
    name: 'damaged',
    label: i18next.t('quote.identification.questions.isDamaged'),
    tag: TAG_IS_DAMAGED,
    display: true,
    choices: [],
  },
  {
    name: 'broken',
    label: i18next.t('quote.identification.questions.isBroken'),
    tag: TAG_IS_BROKEN,
    display: true,
    choices: [],
  },
  {
    name: 'timingBelt',
    label: i18next.t('quote.identification.questions.needsTimingBelt'),
    tag: TAG_NEEDS_TIMING_BELT,
    display: false,
    choices: [],
  },
  {
    name: 'serviceRecord',
    label: i18next.t('quote.identification.questions.hasServiceRecord'),
    tag: TAG_HAS_SERVICE_RECORD,
    display: false,
    choices: [],
  },
  {
    name: 'vat',
    label: i18next.t('quote.identification.questions.hasVat'),
    tag: TAG_HAS_VAT,
    helper: 'VU, Dérivé VP, VSL, CTTE, location, VTC, Taxi',
    display: true,
    choices: [],
  },
  {
    name: 'foreign',
    label: i18next.t('quote.identification.questions.isForeign'),
    tag: TAG_IS_FOREIGN,
    display: false,
    choices: [],
  },
  {
    name: 'hasBeenTried',
    tag: TAG_IS_TESTED,
    display: false,
    choices: [],
  },
  {
    name: 'previous_use',
    display: false,
    choices: [
      {
        tag: TAG_PREVIOUS_AMBULANCE,
      },
      {
        tag: TAG_PREVIOUS_TAXI,
      },
      {
        tag: TAG_PREVIOUS_DRIVING_SCHOOL,
      },
      {
        tag: TAG_PREVIOUS_LSR_LTR,
      },
    ],
  },
  {
    name: 'rating',
    display: false,
    choices: [
      {
        tag: TAG_STATE_BAD,
      },
      {
        tag: TAG_STATE_AVERAGE,
      },
      {
        tag: TAG_STATE_GOOD,
      },
      {
        tag: TAG_STATE_VERY_GOOD,
      },
    ],
  },
]

export default questions
