// @flow

import React from 'react'
import TextField from '@material-ui/core/TextField'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@autodisol/ads-js/components/CustomButton'
import LabeledSeparator from '@autodisol/ads-js/components/LabeledSeparator'
import Page from 'sharyn/components/Page'
import { connect as withRedux } from 'react-redux'
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'
import withFields from 'sharyn/hocs/with-fields'
import { useTranslation } from 'react-i18next'
import { PLATE_EXAMPLES } from 'quote/quote-constant'
import { getModesData } from 'utils/modes'
import { MODES } from 'utils/constants/modes'
import { useIdentify } from 'quote/cmp-page/IdentifyPage/useIdentify'

import type { Mode } from 'types/modes'

const styles = theme => ({
  identifyInput: {
    borderColor: theme.palette.secondary.dark,
    background: '#FFFFFF',
    zIndex: 2,
  },
  identifyModeButton: {
    width: '50%',
    paddingBottom: '1.1rem',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  container: {
    minHeight: 0,
    paddingTop: 0,
    height: 'calc(100% - 56px)',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100% - 56px)',
    },
    [theme.breakpoints.up('md')]: {
      height: 'calc(100% - 100px)',
    },
  },
  subContainer: {
    width: '100%',
  },
  buttonsBar: {
    width: '100%',
    transform: 'translateY(10px)',
  },
})

type IdentifyPageProps = {
  classes: Object,
  dispatch: Function,
  routerHistory: Object,
  identification: Object,
  mode: Mode,
  accessModes: Mode[],
}

const IdentifyPage = ({
  classes: css,
  dispatch,
  routerHistory,
  identification,
  mode,
  accessModes,
}: IdentifyPageProps) => {
  const { t } = useTranslation()

  const {
    identifyMode,
    identifyMethod,
    identifyCountry,
    handleChange,
    handleManualMode,
    handleIdentifyMode,
    identify,
    isIdentifying,
    isDisabledIdentification,
    isDisabledIdentificationByVIN,
  } = useIdentify({ dispatch, routerHistory, identification, mode, accessModes })

  return (
    <Page noPaper noPadding maxWidth={350} paperClass={css.container}>
      <form
        onSubmit={e => {
          e.preventDefault()
          identify({
            variables: {
              vin: identifyMethod.vin && identifyMethod.vin,
              plate: identifyMethod.plate && identifyMethod.plate,
              country: identifyCountry,
            },
          })
        }}
        className={css.subContainer}
      >
        {!isDisabledIdentification && (
          <>
            <LabeledSeparator
              title={t('globals.identification')}
              style={{ margin: '0 0 2rem 0' }}
            />
            <div style={{ width: '100%', marginBottom: '2rem' }}>
              {/* TODO: Hide one or the other (Or Both ?) if user has no rights to identify with Plate or VIN */}
              <div className={css.buttonsBar}>
                <Button
                  height="short"
                  disabled={
                    (identification[identifyCountry] &&
                      identification[identifyCountry]?.plate?.activated === false) ||
                    (identification[identifyCountry] && !identification[identifyCountry]?.plate)
                  }
                  colorType={identifyMode === 'plate' ? 'secondary' : 'tertiary'}
                  className={css.identifyModeButton}
                  style={{ width: isDisabledIdentificationByVIN ? '100%' : '50%' }}
                  onClick={handleIdentifyMode('plate')}
                >
                  {t('quote.plate')}
                </Button>
                {!isDisabledIdentificationByVIN && (
                  <Button
                    height="short"
                    colorType={identifyMode === 'vin' ? 'secondary' : 'tertiary'}
                    className={css.identifyModeButton}
                    onClick={handleIdentifyMode('vin')}
                  >
                    {t('quote.vin')}
                  </Button>
                )}
              </div>
              {identifyMode === 'plate' && (
                <TextField
                  name="plate"
                  autoComplete="off"
                  value={identifyMethod.plate}
                  InputProps={{ classes: { root: css.identifyInput } }}
                  placeholder={`${t('globals.exampleShortColon')} ${
                    PLATE_EXAMPLES[identifyCountry] ?? PLATE_EXAMPLES.default
                  }`}
                  onChange={handleChange('plate')}
                  fullWidth
                />
              )}
              {identifyMode === 'vin' && (
                <TextField
                  name="vin"
                  autoComplete="off"
                  value={identifyMethod.vin}
                  InputProps={{ classes: { root: css.identifyInput } }}
                  // eslint-disable-next-line
                  inputProps={{ maxLength: 17 }}
                  placeholder={`${t('globals.exampleShortColon')} 1ABCD23456789123`}
                  onChange={handleChange('vin')}
                  fullWidth
                />
              )}
            </div>
            <Button
              variant="contained"
              colorType="primary"
              height="tall"
              fullWidth
              type="submit"
              disabled={
                (identifyMethod.vin === '' && !identifyMethod?.vin) ||
                (identifyMethod.plate === '' && !identifyMethod?.plate)
              }
              isLoading={isIdentifying}
            >
              {mode === MODES.resval
                ? t('quote.identification.identify.vo')
                : t('quote.identification.identify.default')}
            </Button>
          </>
        )}
        <>
          <LabeledSeparator
            title={t('quote.identification.manualMode')}
            style={{ margin: '2rem 0' }}
          />
          <Button
            variant="contained"
            colorType="secondary"
            height="tall"
            onClick={handleManualMode}
            fullWidth
          >
            {t('quote.identification.manualIdentification')}
          </Button>
        </>
      </form>
    </Page>
  )
}

export default (compose(
  withRedux(({ user, router }) => {
    const { currentMode, accessModes } = getModesData(user, router.location.pathname)

    return {
      rights: user.data.rights,
      identification: user.data.config.identification.autovista,
      mode: currentMode,
      router,
      accessModes,
    }
  }),
  withStyles(styles),
  withFields(),
  withRouter,
)(IdentifyPage): any)
