// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { getFielsToDisplay, numberFormat } from 'quote/cmp/PrintQuote/functions'
import withStyles from '@material-ui/core/styles/withStyles'
import { printSettingsPreset } from 'quote/quote-constant'
import ListItem from 'quote/cmp/PrintQuote/ListItem'

import type { PrintField } from 'types/print'
import type { Mode } from 'types/modes'

const styles = () => ({
  container: {
    padding: 0,
    margin: 0,
  },
})

type Props = {
  classes: Object,
  printField: PrintField,
  mode: Mode,
  prices: {
    quoterPrice?: number,
    damagePrice: number,
    mechanicalPrice: number,
  },
}
const Insert = ({ classes, printField, mode, prices }: Props) => {
  const { t } = useTranslation()

  const currentPrintSettingsPreset = printSettingsPreset[printField]
  const fields = currentPrintSettingsPreset?.insert?.fields

  const data = {
    standard: {},
    resval: {
      quoterPrice: {
        name: t('quote.commitmentPrice'),
        value: numberFormat(prices.quoterPrice, 'currency'),
        typeValue: 'price',
      },
      bodyRepairCosts: {
        name: t(`quote.print.restorationCosts.${mode}.body`),
        value: numberFormat(prices.damagePrice, 'currency'),
        typeValue: 'price',
      },
      mechanicalRestorationCosts: {
        name: t(`quote.print.restorationCosts.${mode}.mechanical`),
        value: numberFormat(prices.mechanicalPrice, 'currency'),
        typeValue: 'price',
      },
    },
    standard_bike: {},
  }

  const fieldsToDisplay = getFielsToDisplay(fields, data[mode])
  const isFieldsToDisplay = fieldsToDisplay.some(element => element.value !== null)

  return isFieldsToDisplay ? (
    <ul className={classes.container}>
      {fieldsToDisplay.map(
        ({ name, value, typeValue }) =>
          value !== null && (
            <ListItem
              key={name}
              name={{ value: name, style: { width: '100%' } }}
              content={{ value }}
              variant="secondary"
              typeValue={typeValue}
            />
          ),
      )}
    </ul>
  ) : null
}

export default (withStyles(styles)(Insert): any)
