import numeral from 'numeral'

export const QUOTE_STATUS_DRAFT = 'draft'
export const QUOTE_STATUS_WAITING = 'waiting'
export const QUOTE_STATUS_QUOTED = 'quoted'
export const QUOTE_STATUS_CHANGES_REQUESTED = 'changes_requested'
export const QUOTE_STATUS_ACCEPTED = 'accepted'
export const QUOTE_STATUS_REJECTED = 'rejected'
export const QUOTE_STATUS_ASSIGNED = 'assigned'
export const QUOTE_STATUS_SENT = 'sent'
export const QUOTE_STATUS_WAIT_ASSIGN_VALID = 'wait_assign_valid'
export const QUOTE_STATUS_REJECTED_ASSIGNMENT = 'rejected_assignment'
export const QUOTE_STATUS_SOLD = 'sold'
export const QUOTE_STATUS_IN_STOCK = 'in_stock'
export const QUOTE_STATUS_CLOSED = 'closed'
export const QUOTE_STATUS_FLEET = 'fleet'
export const QUOTE_STATUS_UPDATED_FLEET = 'updated_fleet'
export const QUOTE_STATUS_ONHOLD_OPENING = 'onhold_opening'
export const QUOTE_STATUS_PROCESSING = 'processing'
export const QUOTE_STATUS_PENDING_PRICE = 'pending_price'
export const QUOTE_STATUS_KEPT = 'kept'

export const TAG_IS_DAMAGED = 'is_damaged'
export const TAG_IS_BROKEN = 'is_broken'
export const TAG_IS_FIRST_HAND = 'is_first_hand'
export const TAG_NEEDS_TIMING_BELT = 'needs_timing_belt'
export const TAG_HAS_SERVICE_RECORD = 'has_service_record'
export const TAG_HAS_VAT = 'has_vat'
export const TAG_HAS_NO_VAT = 'has_no_vat'
export const TAG_IS_FOREIGN = 'is_foreign'
export const TAG_PREVIOUS_AMBULANCE = 'previous_ambulance'
export const TAG_PREVIOUS_TAXI = 'previous_taxi'
export const TAG_PREVIOUS_ROAD = 'previous_road'
export const TAG_PREVIOUS_NOT_CONCERNED = 'previous_not_concerned'
export const TAG_PREVIOUS_DRIVING_SCHOOL = 'previous_driving_school'
export const TAG_PREVIOUS_LSR_LTR = 'previous_lsr_ltr'
export const TAG_IS_TESTED = 'is_tested'
export const TAG_IS_NOT_TESTED = 'is_not_tested'
export const TAG_STATE_BAD = 'state_bad'
export const TAG_STATE_AVERAGE = 'state_average'
export const TAG_STATE_GOOD = 'state_good'
export const TAG_STATE_VERY_GOOD = 'state_very_good'
export const TAG_SPECIFIC_CUSTOMER_TARGET = 'specific_customer_target'
export const TAG_PREVIOUS_CIRCUIT_OFF_ROAD = 'previous_circuit_off_road'
export const TAG_PREVIOUS_DELIVERY = 'previous_delivery'
export const TAG_HAS_VEHICLE_INSPECTION = 'has_vehicle_inspection'
export const TAG_HAS_NO_VEHICLE_INSPECTION = 'has_no_vehicle_inspection'
export const TAG_HAS_ABS_EQUIPMENT = 'has_abs_equipment'
export const TAG_HAS_NO_ABS_EQUIPMENT = 'has_no_abs_equipment'
export const TAG_REG_CARD = 'REG_CARD'
export const TAG_TECHNICAL_CONTROL = 'TECH_CHECK'
export const TAG_QUOTE_IS_ORG_MULTIQUOTED = 'quote_is_org_multiquoted'

export const TYPE_EL_BATTERY_PURCHASED = 'type_el_battery_purchased'
export const TYPE_EL_BATTERY_RENT = 'type_el_battery_rent'
export const TYPE_EL_BATTERY_HAS_WARRANTY = 'type_el_battery_has_warranty'
export const TYPE_EL_BATTERY_HAS_NO_WARRANTY = 'type_el_battery_has_no_warranty'
export const TYPE_EL_BATTERY_HAS_NO_POWER_CABLE = 'type_el_battery_has_no_power_cable'
export const TYPE_EL_BATTERY_HAS_POWER_CABLE = 'type_el_battery_has_power_cable'
export const TYPE_EL_BATTERY_HAS_POWER_CABLE_MODE_2 = 'type_el_battery_has_power_cable_mode_2'
export const TYPE_EL_BATTERY_HAS_NO_POWER_CABLE_MODE_2 = 'type_el_battery_has_no_power_cable_mode_2'
export const TYPE_EL_BATTERY_HAS_POWER_CABLE_MODE_3 = 'type_el_battery_has_power_cable_mode_3'
export const TYPE_EL_BATTERY_HAS_NO_POWER_CABLE_MODE_3 = 'type_el_battery_has_no_power_cable_mode_3'
export const TYPE_EL_BATTERY_NUMBER_SUPPLIED_ONE = 'type_el_battery_number_supplied_one'
export const TYPE_EL_BATTERY_NUMBER_SUPPLIED_TWO = 'type_el_battery_number_supplied_two'
export const TYPE_EL_BATTERY_NUMBER_SUPPLIED_MORE = 'type_el_battery_number_supplied_more'

numeral.localeData().delimiters.thousands = ' '

export const QUOTE_GROUP_STATUS_DRAFT = 'group_draft'
export const QUOTE_GROUP_STATUS_QUOTED = 'group_quoted'
export const QUOTE_GROUP_STATUS_ASSIGNED = 'group_assigned'
export const QUOTE_GROUP_STATUS_SOLD = 'group_sold'
export const QUOTE_GROUP_STATUS_FLEET = 'group_fleet'
export const QUOTE_GROUP_STATUS_CLOSED = 'group_closed'

export const RIGHT_ACCEPT_REJECT_QUOTE = 'accept_reject_quote'
export const RIGHT_CREATE_QUOTE = 'create_quote'
export const RIGHT_SEND_IN_FLEET_QUOTE = 'send_in_fleet_quote'
export const RIGHT_UPDATE_FLEET_QUOTE = 'update_fleet_quote'
export const RIGHT_CLOSE_CASE_QUOTE = 'close_case_quote'
export const RIGHT_REMOVE_SITE_QUOTE = 'remove_site_quote'
export const RIGHT_ADD_SITE_QUOTE = 'add_site_quote'
export const RIGHT_ASSIGN_QUOTE = 'assign_quote'
export const RIGHT_KEEP_ITEM_QUOTE = 'keep_item_quote'
export const RIGHT_CORRECT_QUOTE = 'correct_quote'

const quoteGroupStatusDraft = {
  [QUOTE_GROUP_STATUS_DRAFT]: [
    QUOTE_STATUS_DRAFT,
    QUOTE_STATUS_PROCESSING,
    QUOTE_STATUS_PENDING_PRICE,
    QUOTE_STATUS_ONHOLD_OPENING,
    QUOTE_STATUS_WAITING,
  ],
}

const quoteGroupStatusQuoted = {
  [QUOTE_GROUP_STATUS_QUOTED]: [
    QUOTE_STATUS_QUOTED,
    QUOTE_STATUS_ACCEPTED,
    QUOTE_STATUS_REJECTED,
    QUOTE_STATUS_ASSIGNED,
    QUOTE_STATUS_SENT,
    QUOTE_STATUS_WAIT_ASSIGN_VALID,
    QUOTE_STATUS_REJECTED_ASSIGNMENT,
    QUOTE_STATUS_IN_STOCK,
    QUOTE_STATUS_SOLD,
  ],
}

const quoteGroupStatusAssigned = {
  [QUOTE_GROUP_STATUS_ASSIGNED]: [
    QUOTE_STATUS_ASSIGNED,
    QUOTE_STATUS_SENT,
    QUOTE_STATUS_IN_STOCK,
    QUOTE_STATUS_SOLD,
  ],
}

const quoteGroupStatusSold = {
  [QUOTE_GROUP_STATUS_SOLD]: [QUOTE_STATUS_SOLD],
}

export const quoteGroupStatusList = {
  standard: {
    ...quoteGroupStatusDraft,
    ...quoteGroupStatusQuoted,
    ...quoteGroupStatusAssigned,
    ...quoteGroupStatusSold,
  },
  resval: {
    ...quoteGroupStatusDraft,
    [QUOTE_GROUP_STATUS_QUOTED]: [
      ...quoteGroupStatusQuoted.group_quoted,
      QUOTE_STATUS_FLEET,
      QUOTE_STATUS_UPDATED_FLEET,
      QUOTE_STATUS_CLOSED,
      QUOTE_STATUS_KEPT,
    ],
    ...quoteGroupStatusAssigned,
    ...quoteGroupStatusSold,
    [QUOTE_GROUP_STATUS_FLEET]: [QUOTE_STATUS_FLEET, QUOTE_STATUS_UPDATED_FLEET],
    [QUOTE_GROUP_STATUS_CLOSED]: [QUOTE_STATUS_CLOSED, QUOTE_STATUS_KEPT],
  },
  standard_bike: {
    ...quoteGroupStatusDraft,
    ...quoteGroupStatusQuoted,
    ...quoteGroupStatusAssigned,
    ...quoteGroupStatusSold,
  },
}

// export const TAG_NEEDS_TECHNICAL_CHECK = 'needs_technical_check'
