// @flow

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

import RadioGroup from '@autodisol/ads-js/components/Inputs/ReactHookForm/RadioGroup'
import Divider from '@material-ui/core/Divider'
import SitesFilterSection from '@autodisol/ads-js/components/Filters/Sites'

import type { SitesFiltered } from 'quote/cmp/QuoteActionsPanel/types'

const styles = () => ({
  container: {
    margin: '20px 0 0',
    '& > fieldset > legend': {
      marginBottom: 10,
    },
  },
})

type Props = {
  classes: Object,
  control: any,
  sites: SitesFiltered,
  handleChangeRadio?: (onChange: Function) => (e: SyntheticInputEvent<HTMLInputElement>) => void,
  refetch: Function,
  enquirerGroup?: {
    root: {
      id: string,
    },
  },
  isDsplayFilterSites: boolean,
  isDisabled?: boolean,
  label: string,
  type: 'withdrawal' | 'billing',
}

const SiteSelection = ({
  classes,
  control,
  sites,
  handleChangeRadio,
  refetch,
  enquirerGroup,
  isDsplayFilterSites,
  isDisabled,
  label,
  type,
}: Props) => (
  <div className={classes.container}>
    <RadioGroup
      control={control}
      label={label}
      defaultValue=""
      name={type}
      choices={sites}
      row={false}
      disabled={isDisabled}
      handleChangeRadio={handleChangeRadio}
    >
      <>
        <Divider />
        {isDsplayFilterSites && (
          <SitesFilterSection refetch={refetch} type={type} enquirerGroup={enquirerGroup} />
        )}
      </>
    </RadioGroup>
  </div>
)

export default (withStyles(styles)(SiteSelection): any)
