// @flow

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { useTranslation } from 'react-i18next'
import { numberFormat } from 'quote/cmp/PrintQuote/functions'
import SectionTitle from 'quote/cmp/PrintQuote/SectionTitle'
import { NO_PRICE } from 'utils/constants'

const styles = () => ({
  condition: {
    fontSize: '0.7rem',
    marginTop: 20,
  },
  price: {
    fontWeight: 900,
    fontSize: '1.2rem',
    textAlign: 'center',
    paddingRight: '20pt',
  },
  validity: {
    fontWeight: 900,
    textAlign: 'center',
    paddingRight: '20pt',
  },
})

type Props = {
  classes: Object,
  price?: number | string,
  offerDeadline: string,
}

const CommercialProposition = ({ classes, price, offerDeadline }: Props) => {
  const { t } = useTranslation()

  const formattedPrice = numberFormat(price)

  return (
    <>
      <SectionTitle title={t('quote.print.commercialProposition.title')} />
      <p className={classes.condition}>
        {t('quote.print.commercialProposition.buyBackConditions')}
      </p>
      <p className={classes.price}>{formattedPrice ? `${formattedPrice} €` : NO_PRICE}</p>
      <p className={classes.validity}>
        {t('quote.print.commercialProposition.validity', { date: offerDeadline })}
      </p>
    </>
  )
}

export default (withStyles(styles)(CommercialProposition): any)
