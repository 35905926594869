// @flow

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { useTranslation } from 'react-i18next'
import { printSettingsPreset } from 'quote/quote-constant'
import { getFielsToDisplay, numberFormat } from 'quote/cmp/PrintQuote/functions'
import { NO_PRICE } from 'utils/constants'
import { MODES } from 'utils/constants/modes'
import { PRINT_MODE } from 'quote/cmp/PrintQuote/constants'
import SectionTitle from 'quote/cmp/PrintQuote/SectionTitle'
import ListItem from 'quote/cmp/PrintQuote/ListItem'

import type { Mode } from 'types/modes'
import type { PrintField } from 'types/print'

const styles = () => ({
  container: {
    marginBottom: 20,
  },
  name: {
    fontWeight: 600,
    margin: '8px 0',
  },
  value: {
    fontWeight: 900,
    fontSize: '1.2rem',
    margin: '8px 0',
  },
  list: {
    listStyleType: 'none',
    paddingLeft: 0,
  },
})

type Props = {
  classes: Object,
  mode: Mode,
  printField: PrintField,
  quoterPrice?: number,
  damagePrice: number,
  mechanicalPrice: number,
  excessMileagePrice?: number,
  bodyworkPrice?: number,
}

const RestorationCosts = ({
  classes,
  printField,
  mode,
  quoterPrice,
  damagePrice,
  mechanicalPrice,
  excessMileagePrice,
  bodyworkPrice,
}: Props): React$Element<any> | null => {
  const { t } = useTranslation()

  const currentPrintSettingsPreset = printSettingsPreset[printField]
  const fields = currentPrintSettingsPreset?.valuation?.fields
  const standard = {
    bodyRepairCosts: {
      name: t(`quote.print.restorationCosts.${mode}.body`),
      value: numberFormat(damagePrice, 'currency'),
    },
    mechanicalRestorationCosts: {
      name: t(`quote.print.restorationCosts.${mode}.mechanical`),
      value: numberFormat(mechanicalPrice, 'currency'),
    },
  }

  const data = {
    standard: { ...standard },
    resval: {
      ...standard,
      excessMileage: {
        name: t('quote.print.restorationCosts.resval.excessMileage'),
        value: numberFormat(excessMileagePrice, 'currency'),
      },
    },
    standard_bike: {
      ...standard,
      bodyRepairCosts: {
        ...standard.bodyRepairCosts,
        value: numberFormat(bodyworkPrice, 'currency') ?? numberFormat(damagePrice, 'currency'),
      },
    },
  }

  const fieldsToDisplay = getFielsToDisplay(fields, data[mode])
  const fieldsToDisplayParsed = mode === MODES.resval ? fieldsToDisplay : fieldsToDisplay.reverse()
  const netOffer = quoterPrice ? quoterPrice - damagePrice - mechanicalPrice : NO_PRICE
  const netOffertFormatted = numberFormat(netOffer, 'currency')

  if (mode === MODES.resval && printField === PRINT_MODE.full)
    return netOffertFormatted ? (
      <>
        <SectionTitle title={t('quote.print.netOffer')} />
        <p className={classes.value}>{numberFormat(netOffer, 'currency')}</p>
      </>
    ) : null

  return (
    <>
      <SectionTitle title={t('quote.print.restorationCosts.title')} />

      {fieldsToDisplayParsed.map(({ name, value }) =>
        mode === MODES.resval ? (
          <div key={name} className={classes.container}>
            <p className={classes.name}>{name}</p>
            <p className={classes.value}>{value ?? NO_PRICE}</p>
          </div>
        ) : (
          <ul key={name} className={classes.list}>
            <ListItem
              key={name}
              name={{ value: name, style: { width: '70%' } }}
              content={{ value }}
              variant="secondary"
              typeValue="price"
            />
          </ul>
        ),
      )}
    </>
  )
}

export default (withStyles(styles)(RestorationCosts): any)
