// @flow

import React from 'react'
import compose from 'recompose/compose'
import moment from 'moment'
import { connect as withRedux } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import i18next from 'services/translations/config'
import TextField from '@autodisol/ads-js/components/Inputs/ReactHookForm/TextField'
import Modal from 'Modal'
import ActionWithModal from 'quote/cmp/QuoteActionsPanel/Resval/ActionWithModal'
import ExpiredQuotation from 'quote/cmp/QuoteActionsPanel/Resval/ExpiredQuotation'
import { MAX_KM, MIN_DELIVERY_DATE } from 'quote/cmp/validationRules'
import { getLanguage } from 'utils/language'
import { PLATE_EXAMPLES } from 'quote/quote-constant'
import { useMutation } from '@apollo/client'
import { sendInFleetQuoteQuery } from 'quote/quote-queries'
import { quotePath } from 'quote/quote-paths'
import { useQuoteDataFromCache } from 'hooks/useQuoteDataFromCache'
import { MAX_VIN_LENGTH } from 'utils/constants/quote'
import { RIGHT_SEND_IN_FLEET_QUOTE } from '_client/config'
import { CURRENT_DATE } from 'utils/constants/date'

const FORM_ID = 'sentInFleetForm'
const language = getLanguage()

const validationSchema = ({ minContractDateStart, maxContractDateStart, contractKmStart }) =>
  z.object({
    plate: z.string().min(1, { message: i18next.t('quote.sentInFleet.form.error.plate') }),
    vin: z
      .string()
      .min(1, { message: i18next.t('quote.sentInFleet.form.error.vin.min') })
      .max(MAX_VIN_LENGTH, { message: i18next.t('quote.sentInFleet.form.error.vin.max') }),
    contractDateStart: z.coerce
      .date({
        errorMap: (issue, ctx) => {
          if (issue.code === 'invalid_date')
            return {
              message: i18next.t('quote.sentInFleet.form.error.contractDateStart.invalidValue'),
            }
          return { message: ctx.defaultError }
        },
      })
      .min(new Date(minContractDateStart), {
        message: i18next.t('quote.sentInFleet.form.error.contractDateStart.min', {
          date: new Date(minContractDateStart).toLocaleDateString(language),
        }),
      })
      .max(new Date(maxContractDateStart), {
        message: i18next.t('quote.sentInFleet.form.error.contractDateStart.max', {
          date: new Date(maxContractDateStart).toLocaleDateString(language),
        }),
      }),
    kmToStart: z
      .string()
      .min(1, i18next.t('quote.sentInFleet.form.error.kmToStart.invalidValue'))
      .regex(/^[-+]?[0-9]+$/, i18next.t('quote.sentInFleet.form.error.kmToStart.invalidValue'))
      .transform(s => Number(s))
      .refine(
        n => n >= contractKmStart && n <= MAX_KM,
        i18next.t('quote.sentInFleet.form.error.kmToStart.message', {
          km: contractKmStart,
        }),
      ),
    feedbackSentInFleet: z.string().nullable(),
  })

const useSentInFleet = ({ id, history }) => {
  const quoteData = useQuoteDataFromCache(id)

  const [sendInFleetQuote, { loading: isLoadingSendInFleetQuote }] = useMutation(
    sendInFleetQuoteQuery,
    {
      // eslint-disable-next-line camelcase
      onCompleted: ({ send_in_fleet_quote }) => {
        history.push({
          pathname: quotePath(id),
          state: {
            plate: send_in_fleet_quote.plate,
            make: send_in_fleet_quote.make,
          },
        })
      },
    },
  )

  const onSubmit = data => {
    sendInFleetQuote({
      variables: {
        quoteInput: {
          id,
          plate: data.plate,
          vin: data.vin,
          contractDateStart: {
            date: data.contractDateStart,
            timezone: 'UTC',
          },
          contractKmStart: data.kmToStart,
        },
        comment: data.feedbackSentInFleet,
      },
    })
  }

  return {
    quoteData,
    onSubmit,
    isLoading: isLoadingSendInFleetQuote,
  }
}

type ValidationSchema = z.infer<typeof validationSchema>

type Props = {
  id: string,
  rights: string[],
  history: Object,
}

const SentInFleetAction = ({ id, rights, history }: Props) => {
  const { t } = useTranslation()
  const { open, openModal, closeModal } = Modal.useModal()
  const { quoteData, onSubmit, isLoading } = useSentInFleet({ id, history })
  const { createdAt, plate, vin, contractKmStart, expired } = quoteData
  const minContractDateStart = createdAt
    ? moment(createdAt.date).format('YYYY-MM-DD')
    : MIN_DELIVERY_DATE
  const maxContractDateStart = moment(CURRENT_DATE).add(3, 'days').format('YYYY-MM-DD')
  const hasRightToSendInFleetQuote = rights.includes(RIGHT_SEND_IN_FLEET_QUOTE)

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<ValidationSchema>({
    resolver: zodResolver(
      validationSchema({ minContractDateStart, maxContractDateStart, contractKmStart }),
    ),
    reValidateMode: 'onSubmit',
  })

  const handleCloseModal = () => {
    closeModal()
    reset()
  }

  if (!hasRightToSendInFleetQuote) return null

  return (
    <>
      {expired ? (
        <ExpiredQuotation />
      ) : (
        <ActionWithModal
          handleCloseModal={handleCloseModal}
          actionButtonContent={t('quote.sentInFleet.title')}
          ariaLabelledby="sent in fleet"
          ariaDescribedby="sent in fleet"
          modalTitle={t('quote.sentInFleet.title')}
          modalDescription={t('quote.sentInFleet.description')}
          errors={errors}
          isLoading={isLoading}
          modalValidateButtonContent={t('quote.sentInFleet.title')}
          open={open}
          openModal={openModal}
          formId={FORM_ID}
        >
          <form id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
            <TextField
              control={control}
              name="plate"
              defaultValue={plate}
              type="text"
              label={`${t('quote.sentInFleet.form.fields.definitievePlate')} *`}
              placeholder={`${t('globals.exampleColon')} ${
                PLATE_EXAMPLES[language.slice(0, 2)] ?? PLATE_EXAMPLES.default
              }`}
              helperText={t('quote.identification.fieldOnRegistrationDocument', {
                field: 'A',
              })}
            />
            <TextField
              control={control}
              name="vin"
              defaultValue={vin}
              type="text"
              label={`${t('quote.vin')} *`}
              placeholder={`${t('globals.exampleColon')} 1ABCD23456E789123`}
              helperText={t('quote.sentInFleet.form.fields.fieldOnRegistrationDocument', {
                field: 'E',
              })}
            />
            <TextField
              control={control}
              name="contractDateStart"
              defaultValue=""
              inputProps={{
                min: minContractDateStart,
                max: maxContractDateStart,
              }}
              type="date"
              label={`${t('quote.sentInFleet.form.fields.contractDateStart')} *`}
            />
            <TextField
              control={control}
              name="kmToStart"
              defaultValue=""
              type="number"
              label={`${t('quote.sentInFleet.form.fields.kmToStart')} *`}
              placeholder={`${t('globals.exampleColon')} 1000`}
            />
            <TextField
              variant="standard"
              control={control}
              name="feedbackSentInFleet"
              defaultValue=""
              type="text"
              label={t('quote.sentInFleet.form.fields.feedback')}
              placeholder={t('quote.generalFeedbackPlaceholder')}
              multiline
              rows={3}
            />
          </form>
        </ActionWithModal>
      )}
    </>
  )
}

export default (compose(
  withRedux(({ user }) => ({
    rights: user.data.rights,
  })),
  withRouter,
)(SentInFleetAction): any)
