// @flow

import React from 'react'
import { useLazyQuery } from '@apollo/client'
import { listSitesQueryApollo } from 'quote/quote-queries'
import ContentUpdateSiteQuote from '@autodisol/ads-js/components/Modals/ContentUpdateSiteQuote'

type Props = {
  typeSite: string,
  withdrawalSite: Object,
  billingSite: Object,
  data: Object,
  field: Function,
  onChange: Function,
  enquirerGroup: Object | null,
  isLoadingListSites: boolean,
}

const UpdateSiteQuote = ({
  typeSite,
  withdrawalSite,
  billingSite,
  data,
  field,
  onChange,
  enquirerGroup,
  isLoadingListSites,
}: Props): React$Element<any> => {
  const [fetchListSites, { data: dataSitesList, loading: isLoadingFetchData }] =
    useLazyQuery(listSitesQueryApollo)

  const hasFilter = data?.list_sites?.nbPages > 1
  const sitesList = dataSitesList?.list_sites?.results ?? data?.list_sites?.results
  const isLoading = isLoadingFetchData || isLoadingListSites

  return (
    <ContentUpdateSiteQuote
      typeSite={typeSite}
      withdrawalSite={withdrawalSite}
      billingSite={billingSite}
      field={field}
      onChange={onChange}
      enquirerGroup={enquirerGroup}
      fetchListSites={fetchListSites}
      hasFilter={hasFilter}
      sitesList={sitesList}
      isLoading={isLoading}
    />
  )
}

export default UpdateSiteQuote
