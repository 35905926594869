// @flow

import React from 'react'
import List from '@autodisol/ads-js/components/List'
import numeral from 'numeral'
import { useTranslation } from 'react-i18next'

type Props = {
  quoterPrice?: Object,
  rights?: string[],
  style?: Object,
}

const QuoterPriceNotMarged = ({ quoterPrice, rights, style }: Props): React$Element<any> | null => {
  const { t } = useTranslation()

  return quoterPrice && rights?.find(right => right === 'view_raw_price') ? (
    <div>
      <List
        style={style}
        data={[
          {
            title: `${t('quote.quoterPrice')}: `,
            content: quoterPrice?.amount ? `${numeral(quoterPrice.amount).format()} €` : '—',
          },
        ]}
      />
    </div>
  ) : null
}

export default QuoterPriceNotMarged
