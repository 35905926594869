// @flow

import React from 'react'
import { printSettingsPreset } from 'quote/quote-constant'
import withStyles from '@material-ui/core/styles/withStyles'
import { useTranslation } from 'react-i18next'
import { MODES } from 'utils/constants/modes'
import { QUOTE_STATUS_UPDATED_FLEET } from '_client/config'
import { getFielsToDisplay } from 'quote/cmp/PrintQuote/functions'
import { getLanguage } from 'utils/language'
import { getFormattedDateByCountryCode } from 'utils/date'
import SectionTitle from 'quote/cmp/PrintQuote/SectionTitle'
import ListItem from 'quote/cmp/PrintQuote/ListItem'

import type { Mode } from 'types/modes'
import type { PrintField } from 'types/print'
import { getPrice } from '_client/util'

const language = getLanguage()

const styles = () => ({
  list: {
    paddingLeft: 0,
  },
})

type Props = {
  classes: Object,
  printField: PrintField,
  quote?: Object,
  mode: Mode,
}

const Dates = ({ classes, printField, quote, mode }: Props): React$Element<any> => {
  const { t } = useTranslation()

  const isDisplayPendingData = mode === MODES.resval && quote?.status === QUOTE_STATUS_UPDATED_FLEET
  const contractDuration = isDisplayPendingData
    ? quote?.pendingUpdate?.contractDuration
    : quote?.contractDuration
  const contractDateStart = quote?.contractDateStart?.date
  const currentPrintSettingsPreset = printSettingsPreset[printField]
  const fields = currentPrintSettingsPreset?.dates?.fields
  const lastRegDate = quote?.lastRegDate?.date
  const releaseDate = quote?.releaseDate?.date
  const quotedAtDate = quote?.quotedAt?.date
  const quoterPriceDate = getPrice('quoter_marged', quote?.prices && quote?.prices)?.createdAt?.date
  const b2cAdvisedPriceDate = getPrice('b2c_advised', quote?.prices && quote?.prices)?.createdAt
    ?.date
  const quotationDate = quotedAtDate || quoterPriceDate || b2cAdvisedPriceDate
  const deliveryDate = quote?.deliveryDate?.date

  const standard = {
    firstRegistrationDate: {
      name: t('quote.print.firstRegistrationDate'),
      value: releaseDate && getFormattedDateByCountryCode(releaseDate, language),
    },
    registrationCertificateDate: {
      name: t('quote.print.registrationCertificateDate'),
      value: lastRegDate && getFormattedDateByCountryCode(lastRegDate, language),
    },
    quotationDate: {
      name: t('quote.quotationDate'),
      value: quotationDate && getFormattedDateByCountryCode(quotationDate, language),
    },
    deliveryDate: {
      name: t('quote.deliveryDate'),
      value: deliveryDate && getFormattedDateByCountryCode(deliveryDate, language),
    },
  }

  const data = {
    standard: { ...standard },
    resval: {
      ...standard,
      contractDuration: {
        name: t('quote.contractDuration'),
        value: contractDuration && `${contractDuration} ${t('globals.month')}`,
      },
      contractDateStart: {
        name: t('quote.print.contractDateStart'),
        value: contractDateStart && getFormattedDateByCountryCode(contractDateStart, language),
      },
    },
    standard_bike: { ...standard },
  }
  const fieldsToDisplay = getFielsToDisplay(fields, data[mode])

  return (
    <>
      <SectionTitle title={t('quote.print.dates')} />
      <ul className={classes.list}>
        {fieldsToDisplay.map(({ name, value }) => (
          <ListItem
            name={{ value: name, style: { width: MODES.resval === mode ? '80%' : '71%' } }}
            content={{ value, style: { width: MODES.resval === mode ? '100%' : '29%' } }}
            key={name}
            variant="secondary"
            typeValue="value"
          />
        ))}
      </ul>
    </>
  )
}

export default (withStyles(styles)(Dates): any)
