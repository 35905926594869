const COMMON_SEARCH_FIELDS = {
  creationDate: true,
  makeModel: true,
  status: true,
  commitmentPrice: true,
  quotationDate: false,
  deliveryDate: false,
  enquirer: false,
  registrationDate: false,
  updatedAt: false,
  group: false,
}

const COMMON_STANDARD_SEARCH_FIELDS = {
  ...COMMON_SEARCH_FIELDS,
  plateVin: true,
  soldPrice: true,
  mileage: false,
}

const DEFAULT_EXPORT_FIELDS = {
  request_dates: false,
  pricing_date: false,
  assigned_date: false,
  sold_date: false,
  plate: false,
  vin: false,
  status: false,
  enquirer: false,
  car_name: false,
  car_details: false,
  delivery: false,
  sites: false,
  car_state: false,
  prices: false,
  photos: false,
}

export const SEARCH_FIELDS = {
  standard: {
    ...COMMON_STANDARD_SEARCH_FIELDS,
    advisedPrice: false,
  },
  resval: {
    ...COMMON_SEARCH_FIELDS,
    reference: true,
    plate: true,
    contractDuration: true,
    mileageToGo: true,
    mileageToRoad: false,
  },
  standard_bike: {
    ...COMMON_STANDARD_SEARCH_FIELDS,
  },
}

export const EXPORT_FIELDS = {
  standard: {
    ...DEFAULT_EXPORT_FIELDS,
  },
  resval: {
    ...DEFAULT_EXPORT_FIELDS,
    contract_details: false,
    contract_dates: false,
    pending_update: false,
  },
  standard_bike: {
    ...DEFAULT_EXPORT_FIELDS,
  },
}
