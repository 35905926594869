// @flow

import React from 'react'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import BusIcon from '@material-ui/icons/DirectionsBus'
import EuroIcon from '@material-ui/icons/EuroSymbol'
import GavelIcon from '@material-ui/icons/Gavel'
import HowToVoteIcon from '@material-ui/icons/HowToVote'
import InsertIcon from '@material-ui/icons/InsertInvitation'
import RoomIcon from '@material-ui/icons/Room'
import ReceiptIcon from '@material-ui/icons/Receipt'
import { useTranslation } from 'react-i18next'

import numeral from 'numeral'
import moment from 'moment'
import PaymentsIcon from '../../../public/img/PaymentsIcon.svg'

const TraceabilityJSX = ({
  classes: css,
  auctionGroup,
  tracking,
  withdrawalSite,
  billingSite,
  soldPrice,
  assignedAt,
}: {
  classes: Object,
  auctionGroup?: Object,
  tracking?: Object,
  withdrawalSite?: Object,
  billingSite?: Object,
  soldPrice?: Object,
  assignedAt?: Object,
}) => {
  const { t } = useTranslation()

  return (
    <Grid container spacing="32" direction="column">
      {tracking && tracking.status && (
        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
          <RoomIcon style={{ marginRight: '1rem' }} />
          <Typography className={css.label}>
            {t('quote.traceability.stateColon')}{' '}
            <span className={css.value}>{t(`globals.allStatus.${tracking.status}`)}</span>
          </Typography>
        </Grid>
      )}
      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
        <GavelIcon style={{ marginRight: '1rem' }} />
        <Typography className={css.label}>
          {t('quote.traceability.targetedBidderColon')}{' '}
          <span className={css.value}>{auctionGroup?.name}</span>
        </Typography>
      </Grid>
      <Grid item style={{ display: 'flex' }}>
        <BusIcon style={{ marginRight: '1rem' }} />
        <div>
          <Typography className={css.label}>{t('quote.withdrawalSiteColon')}</Typography>
          <div>
            <Typography className={css.value}>{withdrawalSite?.name}</Typography>
            <Typography className={css.value}>
              {withdrawalSite?.number} {withdrawalSite?.street}
            </Typography>
            <Typography className={css.value}>
              {withdrawalSite?.zipcode} {withdrawalSite?.city}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item style={{ display: 'flex' }}>
        <ReceiptIcon style={{ marginRight: '1rem' }} />
        <div>
          <Typography className={css.label}>{t('quote.traceability.billingSiteColon')}</Typography>
          <div>
            <Typography className={css.value}>{billingSite?.name}</Typography>
            <Typography className={css.value}>
              {billingSite?.number} {billingSite?.street}
            </Typography>
            <Typography className={css.value}>
              {billingSite?.zipcode} {billingSite?.city}
            </Typography>
          </div>
        </div>
      </Grid>
      {assignedAt && (
        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
          <InsertIcon style={{ marginRight: '1rem' }} />
          <Typography className={css.label}>
            {t('quote.traceability.assignedDateColon')}{' '}
            <span className={css.value}>{moment(assignedAt?.date).format('DD/MM/YYYY')}</span>
          </Typography>
        </Grid>
      )}
      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
        <HowToVoteIcon style={{ marginRight: '1rem' }} />
        <Typography className={css.label}>
          {t('quote.traceability.vehicleRegistrationColon')}{' '}
          <span className={css.value}>
            {tracking && tracking.tags.find(tag => tag === 'titleReceived')
              ? t('globals.yes')
              : t('globals.no')}
          </span>
        </Typography>
      </Grid>
      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
        <PaymentsIcon style={{ marginRight: '1rem' }} />
        <Typography className={css.label}>
          {t('quote.traceability.advancePaymentColon')}{' '}
          <span className={css.value}>
            {tracking && tracking.tags.find(tag => tag === 'advance')
              ? t('globals.yes')
              : t('globals.no')}
          </span>
        </Typography>
      </Grid>
      {tracking?.arrivalDate?.date && (
        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
          <InsertIcon style={{ marginRight: '1rem' }} />
          <Typography className={css.label}>
            {t('quote.traceability.arrivalDate')}{' '}
            <span className={css.value}>
              {moment(tracking?.arrivalDate.date).format('DD/MM/YYYY')}
            </span>
          </Typography>
        </Grid>
      )}
      {tracking?.soldDate && (
        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
          <InsertIcon style={{ marginRight: '1rem' }} />
          <Typography className={css.label}>
            {t('quote.traceability.dateOfSaleColon')}{' '}
            <span className={css.value}>
              {moment(tracking?.soldDate.date).format('DD/MM/YYYY')}
            </span>
          </Typography>
        </Grid>
      )}

      {soldPrice?.amount && (
        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
          <EuroIcon style={{ marginRight: '1rem' }} />
          <Typography className={css.label}>
            {t('quote.traceability.sellingPriceColon')}{' '}
            <span className={css.value}>{numeral(soldPrice?.amount).format()} €</span>
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

const Traceability: any = withStyles(() => ({
  label: { fontWeight: 700 },
  value: { fontWeight: 900 },
}))(TraceabilityJSX)

export default Traceability
