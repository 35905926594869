// @flow

import React from 'react'

import ForgottenPasswordPage from '@autodisol/ads-js/components/ForgottenPasswordPage'
import axios from 'axios'
import { getGlobal } from 'sharyn/util/global'
import { connect as withRedux } from 'react-redux'

const emailPasswordResetCall = async (username: string) => {
  const { env } = getGlobal('store').getState()
  try {
    const { data } = await axios({
      baseURL: env.API_URL,
      url: '/request-password-reset',
      params: { username, appref: env.APP_REF },
    })
    return !!data?.success
  } catch {
    return false
  }
}

const ForgottenPasswordPageWrapper = ({
  appHost,
  appVersion,
  helpInfo,
}: {
  appHost?: string,
  appVersion?: string,
  helpInfo?: string,
}) => (
  <ForgottenPasswordPage
    isNeutral={window.location.hostname !== appHost}
    {...{ emailPasswordResetCall, appVersion, helpInfo }}
  />
)

export default (withRedux(({ env, ui }) => ({
  appHost: env.APP_ADS_HOST,
  appVersion: env.APP_VERSION,
  helpInfo: ui?.icons?.help,
}))(ForgottenPasswordPageWrapper): any)
