import i18next from 'services/translations/config'

const questionsDetails = {
  specific_customer_target: {
    name: 'specific_customer_target',
    label: i18next.t('quote.identification.questions.specific_customer_target'),
    choices: [
      {
        label: i18next.t('globals.yes'),
        value: 'on',
      },
      {
        label: i18next.t('globals.no'),
        value: 'off',
      },
    ],
  },
}

export default questionsDetails
