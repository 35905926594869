// @flow

import '@babel/polyfill'

import React from 'react'

import { ApolloClient, InMemoryCache, ApolloLink, ApolloProvider, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import 'numeral/locales'
import moment from 'moment'
import { render } from 'react-dom'
import createSharynStore from 'sharyn/redux/store'
import { notify, startClientNavigation } from 'sharyn/redux/actions'
import { setGlobal } from 'sharyn/util/global'
import configureMercure from '_client/mercure'
import userReducer from '_client/redux/user-reducer'
import App from 'app/App'
import {
  genericErrorNotification,
  networkErrorNotification,
  sessionExpiredNotification,
} from 'app/notifications'
import theme from 'app/theme'
import { createUploadLink } from 'apollo-upload-client'
import i18next from '../services/translations/config'
import Providers from './providers'
import { getLanguage } from '../utils/language'

import '../sentry'

const preloadedState = window.__PRELOADED_STATE__
const { API_URL, IS_DEV_ENV, SSE_URL } = preloadedState.env

moment.locale(getLanguage())

const store = createSharynStore({
  isDevEnv: IS_DEV_ENV,
  preloadedState,
  persistUser: true,
  userReducer,
})
setGlobal('store', store)

const errorNotificationHandler = errors => {
  errors.forEach(error => {
    let errorNotification

    if (error.extensions) {
      let errorCode
      let errorMessage
      let { subcode } = error.extensions

      if (subcode) {
        errorCode = `error.${error.extensions.category}.${error.extensions.code}.${subcode}`
      } else {
        subcode = 'default'
        errorCode = `error.${error.extensions.category}.${error.extensions.code}.${subcode}`

        if (
          error.extensions?.exception?.missing_right &&
          i18next.t(`${errorCode}.${error.extensions?.exception.missing_right}`) !==
            `${errorCode}.${error.extensions?.exception.missing_right}`
        ) {
          errorCode = `${errorCode}.${error.extensions?.exception.missing_right}`
        }
      }

      errorMessage = i18next.t(errorCode)

      if (errorMessage === errorCode) {
        errorMessage = error.message
      }

      errorNotification = { message: errorMessage }
    } else {
      errorNotification = genericErrorNotification
    }

    store.dispatch(notify(errorNotification))
  })
}

const uploadLink = createUploadLink({ uri: `${API_URL}/graphql` })

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      accept: 'application/json',
      authorization: `Bearer ${store.getState().user?.jwt}` || null,
    },
  }))
  return forward(operation)
})

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  if (['getDefaultAuctionGroupIid', 'getQuoteAutoriginQuery'].includes(operation.operationName))
    return

  if (networkError && !graphQLErrors) {
    store.dispatch(
      notify(
        networkError.statusCode === 401 ? sessionExpiredNotification : networkErrorNotification,
      ),
    )
  }

  if (
    graphQLErrors &&
    !['IDENTIFICATION_NOT_ALLOWED_CATEGORY'].includes(graphQLErrors[0]?.extensions?.subcode)
  ) {
    errorNotificationHandler(Array.isArray(graphQLErrors) ? graphQLErrors : [graphQLErrors])
  }
})

export const apolloClient: any = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      CatalogMake: {
        keyFields: ['id', 'type'],
      },
    },
  }),
  link: from([authMiddleware, errorLink, uploadLink]),
  defaultOptions: {
    watchQuery: { fetchPolicy: 'network-only', errorPolicy: 'all' },
    query: { fetchPolicy: 'network-only', errorPolicy: 'all' },
    mutate: { errorPolicy: 'all' },
  },
})

configureMercure({ store, hubUrl: SSE_URL })

render(
  <ApolloProvider client={apolloClient}>
    <Providers {...{ App, theme, store }} />
  </ApolloProvider>,
  // flow-disable-next-line
  document.getElementById('app'),
)

store.dispatch(startClientNavigation())
