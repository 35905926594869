// @flow
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import translationNL from './locales/nl/translation.json'
import translationCA from './locales/ca/translation.json'
import translationFR from './locales/fr/translation.json'
import translationEN from './locales/en/translation.json'
import translationES from './locales/es/translation.json'
import { getLanguage } from '../../utils/language'

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      nl: {
        translation: translationNL,
      },
      ca: {
        translation: translationCA,
      },
      fr: {
        translation: translationFR,
      },
      en: {
        translation: translationEN,
      },
      es: {
        translation: translationES,
      },
    },
    lng: getLanguage(),
    fallbackLng: false,
    interpolation: {
      escapeValue: false,
    },
    debug: process.env.NODE_ENV !== 'production',
  })

export default i18next
