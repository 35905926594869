// @flow

import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import i18next from 'services/translations/config'

import type { SitesFiltered } from 'quote/cmp/QuoteActionsPanel/types'

const validationSchema = z.object({
  withdrawal: z
    .string()
    .min(1, { message: i18next.t('quote.affectTheVehicle.form.error.withdrawal') }),
  billing: z.string().min(1, { message: i18next.t('quote.affectTheVehicle.form.error.billing') }),
  useSameSite: z.boolean().optional(),
})

type ValidationSchema = z.infer<typeof validationSchema>

type Params = {
  billingSites: SitesFiltered,
  postAssignQuote: Function,
}

type ReturnTypeUseFormData = {
  handleChangeCheckbox: (onChange: Function) => (e: SyntheticInputEvent<HTMLInputElement>) => void,
  handleChangeRadio: (onChange: Function) => (e: SyntheticInputEvent<HTMLInputElement>) => void,
  handleClickValidate: () => Promise<void>,
  isDisabledRadio: boolean,
  isDisabledCheckbox: boolean,
  control: any,
  errors: {
    [key: string]: {
      message: string,
      type: string,
    },
  },
  resetData: Function,
}

export const useFormData = ({ billingSites, postAssignQuote }: Params): ReturnTypeUseFormData => {
  const [isSameSite, setIsSameSite] = useState(null)

  const {
    trigger,
    getValues,
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
  })

  const watchUseSameSite = watch('useSameSite')

  const handleClickValidate = async () => {
    const isValid = await trigger(['withdrawal', 'billing', 'useSameSite'])
    const values = getValues()
    if (isValid) {
      postAssignQuote({
        sites: values,
      })
    }
  }

  const handleChangeCheckbox = onChange => e => {
    const { checked } = e.target
    const { withdrawal } = getValues()
    const isFind = Boolean(billingSites.find(element => element.value === withdrawal))

    onChange(e)

    if (!withdrawal) return

    if (checked && isFind) {
      setValue('billing', withdrawal)
      setIsSameSite(isFind)
      return
    }

    setValue('billing', '')
    setIsSameSite(isFind)
  }

  const handleChangeRadio = onChange => e => {
    const { value } = e.target
    const isFind = Boolean(billingSites.find(element => element.value === value))

    onChange(e)
    setIsSameSite(isFind)

    if (!isFind) {
      setValue('useSameSite', isFind)
      setValue('billing', '')
      return
    }

    if (watchUseSameSite && isFind) setValue('billing', value)
  }

  const resetData = () => {
    reset()
    setIsSameSite(null)
  }
  const isDisabledRadio = Boolean(watchUseSameSite && isSameSite)
  const isDisabledCheckbox = isSameSite === false

  return {
    handleChangeCheckbox,
    handleChangeRadio,
    handleClickValidate,
    isDisabledRadio,
    isDisabledCheckbox,
    control,
    errors,
    resetData,
  }
}
