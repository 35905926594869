// @flow

/* eslint-disable no-alert */

import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'

import moment from 'moment'
import { connect as withRedux } from 'react-redux'
import compose from 'recompose/compose'
import Page from 'sharyn/components/Page'
import LoadingPage from 'sharyn/components/LoadingPage'
import withFields from 'sharyn/hocs/with-fields'
import { getAccessMode } from 'utils/modes'
import QuoteSteps from 'quote/cmp/QuoteSteps'
import { getCommentsQuery, getQuoteAndCountQuery } from 'quote/quote-queries'

import { setCurrentDraftQuoteId, setMode } from '_client/redux/actions'

const formatEditedVehicle = searchResult => {
  if (!searchResult) {
    return {}
  }

  const formattedFields = {
    status: searchResult.status,
    regType: searchResult.regType,
    firstRegDate: searchResult.releaseDate,
    lastRegDate: searchResult.lastRegDate,
    prices: searchResult.prices,
    kmToGo: searchResult.contractKm,
    kmToStart: searchResult.contractKmStart,
    contractDateStart: searchResult?.contractDateStart
      ? moment(searchResult.contractDateStart.date).format('YYYY-MM-DD')
      : null,
    models: searchResult?.identificationCountry === 'es' &&
      searchResult.model && [
        {
          id: 1,
          name: searchResult.model,
          saleStart:
            searchResult.startModelPhase &&
            moment(searchResult.startModelPhase.date).format('YYYY-MM'),
          saleEnd:
            searchResult.endModelPhase && moment(searchResult.endModelPhase.date).format('YYYY-MM'),
          versions: searchResult.catalogId && [
            {
              id: searchResult.catalogId,
              name: searchResult.version,
              trimLevel: searchResult.trimLevel,
              energy: searchResult.energy,
              power: searchResult.hpPower,
              taxPower: searchResult.taxPower,
              displacement: searchResult.displacement,
              body: searchResult.body,
              doors: searchResult.doors,
              seats: searchResult.seats,
              co2: searchResult.co2,
              euroStandard: searchResult.euroStandard,
              price: searchResult.firstSellPrice,
              gearbox: searchResult.gearbox,
              gears: searchResult.gears,
              kwPower: searchResult.kwPower,
              saleStart:
                searchResult.startVersionPhase &&
                moment(searchResult.startVersionPhase.date).format('YYYY-MM'),
              saleEnd:
                searchResult.endVersionPhase &&
                moment(searchResult.endVersionPhase.date).format('YYYY-MM'),
            },
          ],
        },
      ],
    makes: searchResult.make && [
      {
        id: 1,
        name: searchResult.make,
        type: searchResult.type,
        families: searchResult.family && [
          {
            id: 1,
            name: searchResult.family,
            subFamilies: [
              {
                id: 2,
                name: searchResult.subFamily && searchResult.subFamily,
                models: searchResult.model && [
                  {
                    id: 1,
                    name: searchResult.model,
                    saleStart:
                      searchResult.startModelPhase &&
                      moment(searchResult.startModelPhase.date).format('YYYY-MM'),
                    saleEnd:
                      searchResult.endModelPhase &&
                      moment(searchResult.endModelPhase.date).format('YYYY-MM'),
                    versions: searchResult.catalogId && [
                      {
                        id: searchResult.catalogId,
                        name: searchResult.version,
                        trimLevel: searchResult.trimLevel,
                        energy: searchResult.energy,
                        power: searchResult.hpPower,
                        taxPower: searchResult.taxPower,
                        displacement: searchResult.displacement,
                        body: searchResult.body,
                        doors: searchResult.doors,
                        seats: searchResult.seats,
                        co2: searchResult.co2,
                        euroStandard: searchResult.euroStandard,
                        price: searchResult.firstSellPrice,
                        gearbox: searchResult.gearbox,
                        gears: searchResult.gears,
                        kwPower: searchResult.kwPower,
                        saleStart:
                          searchResult.startVersionPhase &&
                          moment(searchResult.startVersionPhase.date).format('YYYY-MM'),
                        saleEnd:
                          searchResult.endVersionPhase &&
                          moment(searchResult.endVersionPhase.date).format('YYYY-MM'),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }

  return { ...searchResult, ...formattedFields }
}

const fetchComments =
  ({ getComments, identifiedVehicle, setIdentifiedVechicle, id }) =>
  async () => {
    let finalComment = null
    let damagesComment = null
    let crashedComment = null
    let bodyworkComment = null

    const respComments = await getComments({
      query: getCommentsQuery,
      asyncKey: 'comments',
      variables: {
        filters: {
          quote: id,
          threadType: identifiedVehicle.status === 'draft' ? 'main' : 'enquirer',
        },
      },
    }).then(r => r.data)

    if (respComments.search_comment.results?.length > 0) {
      finalComment = respComments.search_comment.results.find(c => c.type === 'general_status')
      damagesComment = respComments.search_comment.results.find(c => c.type === 'mechanical_status')
      bodyworkComment = respComments.search_comment.results.find(c => c.type === 'bodywork_status')
      crashedComment = respComments.search_comment.results.find(c => c.type === 'crashed_status')
    }

    setIdentifiedVechicle(
      formatEditedVehicle({
        ...identifiedVehicle,
        oldDamagesComment: damagesComment?.content,
        oldFinalComment: finalComment?.content,
        oldCrashedComment: crashedComment?.content,
        oldBodyworkComment: bodyworkComment?.content,
      }),
    )
  }

type EditQuotePageProps = {
  defaultQuoterGroupId?: string,
  routerHistory: Object,
  isCorrectionMode?: boolean,
  currentDraftQuoteId?: string,
  dispatch: Function,
  user: Object,
}

const EditQuotePageJSX = ({
  defaultQuoterGroupId,
  routerHistory,
  isCorrectionMode,
  currentDraftQuoteId,
  dispatch,
  user,
}: EditQuotePageProps) => {
  const id = routerHistory.location.pathname.substring(
    routerHistory.location.pathname.lastIndexOf('/') + 1,
  )

  const accessModes = getAccessMode({ data: user })

  const [identifiedVehicle, setIdentifiedVechicle] = useState({})
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [getComments] = useLazyQuery(getCommentsQuery)
  const [getIndexedQuote] = useLazyQuery(getQuoteAndCountQuery, {
    variables: { id },
    onCompleted: resp => {
      const quoteData = resp.get_indexed_quote

      if (accessModes.includes(quoteData.category)) {
        dispatch(setMode(quoteData.category))
      }

      setCurrentDraftQuoteId(quoteData.id)
      setIdentifiedVechicle(formatEditedVehicle(quoteData))
      fetchComments({
        getComments,
        identifiedVehicle: quoteData,
        setIdentifiedVechicle,
        id,
      })()
      setIsPageLoading(false)
    },
  })

  useEffect(() => {
    setIsPageLoading(true)
    getIndexedQuote()
  }, [getIndexedQuote])

  return (
    <Page noPaper>
      {isPageLoading ? (
        <LoadingPage noDelay />
      ) : (
        <QuoteSteps
          identifiedVehicle={identifiedVehicle}
          {...{
            defaultQuoterGroupId,
            currentDraftQuoteId,
            routerHistory,
            isCorrectionMode,
          }}
          isEditionMode
        />
      )}
    </Page>
  )
}

const EditQuotePage: React<any, any> = compose(
  withRedux(({ user }, ownProps) => ({
    user,
    isCorrectionMode: ownProps.routerHistory.location.state?.mode === 'correction',
    currentDraftQuoteId: user?.data?.currentDraftQuoteId,
  })),
  withFields(),
)(EditQuotePageJSX)

export default EditQuotePage
