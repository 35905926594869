// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import withStyles from '@material-ui/core/styles/withStyles'
import compose from 'recompose/compose'
import { connect as withRedux } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { keepItemQuoteQuery } from 'quote/quote-queries'
import { SEARCH_PATH } from 'quote/quote-paths'
import { RIGHT_KEEP_ITEM_QUOTE } from '_client/config'
import Modal from 'Modal'
import ActionWithModal from 'quote/cmp/QuoteActionsPanel/Resval/ActionWithModal'

const styles = () => ({
  content: {
    textAlign: 'center',
    padding: '20px 100px',
  },
})

type Props = {
  classes: Object,
  id: string,
  rights: string[],
  history: Object,
}

const useKeepTheVehicle = (id: string, history: Object) => {
  const [keepItemQuote, { loading: isLoading }] = useMutation(keepItemQuoteQuery, {
    variables: {
      id,
    },
    onCompleted: () => {
      history.push({
        pathname: SEARCH_PATH,
        state: { initialSearchState: 'monitoring' },
      })
    },
  })

  const handleActions = () => {
    keepItemQuote()
  }

  return {
    handleActions,
    isLoading,
  }
}

const KeepTheVehicleAction = ({ classes, id, rights, history }: Props) => {
  const { t } = useTranslation()
  const { open, openModal, closeModal } = Modal.useModal()
  const { handleActions, isLoading } = useKeepTheVehicle(id, history)

  const hasRightToKeepTheVehicle = rights.includes(RIGHT_KEEP_ITEM_QUOTE)

  if (!hasRightToKeepTheVehicle) return null

  return (
    <ActionWithModal
      handleCloseModal={closeModal}
      actionButtonContent={t('quote.keepTheVehicle.button')}
      ariaLabelledby="keep the vehicle"
      ariaDescribedby="keep the vehicle"
      modalTitle={t('quote.keepTheVehicle.button')}
      isLoading={isLoading}
      modalValidateButtonContent={t('quote.keepTheVehicle.button')}
      open={open}
      openModal={openModal}
      handleClickValidate={handleActions}
      colorType="error"
      buttonType="button"
    >
      <p className={classes.content}>{t('quote.keepTheVehicle.question')}</p>
    </ActionWithModal>
  )
}

export default (compose(
  withRedux(({ user }) => ({
    rights: user.data.rights,
  })),
  withRouter,
  withStyles(styles),
)(KeepTheVehicleAction): any)
