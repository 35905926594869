// @flow

import React from 'react'
import compose from 'recompose/compose'
import { connect as withRedux } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Button from '@autodisol/ads-js/components/CustomButton'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { editQuotePath } from 'quote/quote-paths'
import { withRouter, Link } from 'react-router-dom'
import { RIGHT_CORRECT_QUOTE } from '_client/config'

const styles = () => ({
  label: {
    fontWeight: 700,
    fontSize: '1rem',
    color: '#868F98',
    margin: '0.5rem 0',
  },
  title: {
    fontWeight: 700,
    color: '#868F98',
    margin: '0.5rem 0 0',
    textAlign: 'center',
  },
  comment: {
    textAlign: 'center',
  },
})

type Props = {
  classes: Object,
  id: string,
  rights: string[],
  askCorrectionComment?: string,
}

const CorrectionAction = ({ classes, id, rights, askCorrectionComment }: Props) => {
  const { t } = useTranslation()

  const hasRightToCorrectQuote = rights.includes(RIGHT_CORRECT_QUOTE)

  if (!hasRightToCorrectQuote) return null

  return (
    <>
      <Typography variant="h2" className={classes.title}>
        {t('quote.correctionRequest')}
      </Typography>

      <p className={classes.comment}>{askCorrectionComment}</p>

      <Button
        variant="contained"
        colorType="yellow"
        height="short"
        component={Link}
        to={{
          pathname: editQuotePath(id),
          state: { mode: 'correction' },
        }}
        fullWidth
        style={{ margin: '6px 0 12px' }}
      >
        {t('quote.editDemand')}
      </Button>
    </>
  )
}

export default (compose(
  withStyles(styles),
  withRedux(({ user }) => ({
    rights: user.data.rights,
  })),
  withRouter,
)(CorrectionAction): any)
