import moment from 'moment'
import { MODES } from 'utils/constants/modes'
import { DOCUMENT_TYPE } from 'utils/constants/quote'

export const getFields = (field, mode) => {
  const status = field('status') || undefined
  const plateLike = field('plateLike') ? `*${field('plateLike').replace(/\W/g, '')}*` : undefined
  const vinLike = field('vinLike') ? `*${field('vinLike').replace(/\W/g, '')}*` : undefined
  const referenceLike = field('referenceLike') ? `*${field('referenceLike')}*` : undefined
  const parentEnquirerGroups = field('parentEnquirerGroups') || undefined

  const dateBefore = field('dateBefore')
    ? {
        date: moment(field('dateBefore')).add(1, 'day').toISOString(true),
      }
    : undefined
  const dateAfter = field('dateAfter')
    ? {
        date: moment(field('dateAfter')).toISOString(true),
      }
    : undefined

  return {
    status,
    parentEnquirerGroups,
    plateLike,
    vinLike,
    ...(mode === MODES.resval && { referenceLike }),
    dateBefore,
    dateAfter,
  }
}

export const getDocumentTags = (actualTags, type) => {
  const DEFAULT_COMMERCIAL_PICTURES_TAGS = ['commercial', 'picture']

  const TYPE_NOT_NEEDING_TAGS = [DOCUMENT_TYPE.REG_CARD, DOCUMENT_TYPE.TECH_CHECK]

  if (TYPE_NOT_NEEDING_TAGS.includes(type)) return []

  if (type === DOCUMENT_TYPE.DMG) return ['damage', 'picture']

  return actualTags
    ? [actualTags, ...DEFAULT_COMMERCIAL_PICTURES_TAGS]
    : DEFAULT_COMMERCIAL_PICTURES_TAGS
}
