// @flow

import React from 'react'
import compose from 'recompose/compose'
import { connect as withRedux } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@autodisol/ads-js/components/CustomButton'
import { setMode } from '_client/redux/actions'
import { useTranslation } from 'react-i18next'
import { getModeList } from 'utils/modes'

import type { ModeList } from 'utils/modes'

const styles = ({ breakpoints }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.up('sm')]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  },
  button: {
    marginBottom: 20,
    [breakpoints.up('sm')]: {
      width: 'calc(50% - 20px)',
      marginRight: 20,
    },
    '& > span': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'baseline',
      fontSize: '1.5em',
      padding: '10px',
      textAlign: 'left',
    },
  },
  icon: {
    color: '#fff',
    opacity: 0.7,
    fontSize: '2em',
  },
})

type Props = {
  classes: Object,
  dispatch: Function,
  modeList: ModeList,
}

const ModeSelector = ({ classes, dispatch, modeList }: Props) => {
  const { t } = useTranslation()

  const handleClickButton = mode => () => {
    dispatch(setMode(mode))
  }

  return (
    <div className={classes.container}>
      {modeList.map(({ mode, translationKey, icon }) => {
        const IconComponent = icon

        return (
          <Button
            key={mode}
            colorType="secondary"
            variant="contained"
            onClick={handleClickButton(mode)}
            className={classes.button}
            fullWidth
          >
            <span>
              <IconComponent className={classes.icon} />
            </span>
            <span>{t(translationKey)}</span>
          </Button>
        )
      })}
    </div>
  )
}

export default (compose(
  withRedux(({ user }) => {
    const modeList = getModeList(user)

    return { modeList }
  }),
  withStyles(styles),
)(ModeSelector): any)
