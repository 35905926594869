// @flow

import { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { notify, dismissFirstNotification } from 'sharyn/redux/actions'
import arr from 'sharyn/util/arr'
import isEmpty from 'lodash/isEmpty'
import {
  errorIdentifyResultNotification,
  errorIdentifyVehicleCategoryIsCar,
  errorIdentifyVehicleCategoryIsBike,
  errorIdentifySwitchToManualIdentification,
} from 'app/notifications'
import { NEW_QUOTE_PATH } from 'quote/quote-paths'
import { identifyQuery } from 'quote/quote-queries'
import { elementIdentify, setCurrentDraftQuoteId } from '_client/redux/actions'
import { MODES } from 'utils/constants/modes'
import { IDENTIFICATION_CATEGORIES, IDENTIFICATION_COUNTRIES } from 'utils/constants'

import type { Mode } from 'types/modes'

type Params = {
  dispatch: Function,
  routerHistory: Object,
  identification: Object,
  mode: Mode,
  accessModes: Mode[],
}

type ReturnType = {
  identifyMode: string,
  identifyMethod: Object,
  identifyCountry: string,
  handleChange: Function,
  handleManualMode: Function,
  identify: Function,
  handleIdentifyMode: Function,
  isIdentifying: boolean,
  isDisabledIdentification: boolean,
  isDisabledIdentificationByVIN: boolean,
}

export const useIdentify = ({
  dispatch,
  routerHistory,
  identification,
  mode,
  accessModes,
}: Params): ReturnType => {
  const [identifyCountry, setIdentifyCountry] = useState('')
  const [identifyMode, setIdentifyMode] = useState('')
  const [identifyMethod, setIdentifyMethod] = useState({
    plate: '',
    vin: '',
  })

  const handleChange = id => e => {
    setIdentifyMethod({
      [id]: e.target.value,
    })
  }

  const handleManualMode = async () => {
    dispatch(elementIdentify({}))

    routerHistory.push({
      pathname: NEW_QUOTE_PATH,
      state: { identifyCountry },
    })
  }

  const handleIdentifyMode = (value: string) => () => {
    setIdentifyMode(value)
  }

  const redirectAfterIdendification = (identifiedVehicle: Object) => {
    const { category } = identifiedVehicle
    const [firstMake] = arr(identifiedVehicle?.makes)
    const [firstModel] = arr(identifiedVehicle?.families ?? firstMake?.families)
    const [firstVersion] = arr(identifiedVehicle?.subFamilies ?? firstModel?.subFamilies)
    const hasAllTheVehicleDetails =
      isEmpty(firstMake) || !isEmpty(firstModel) || !isEmpty(firstVersion)

    if (!hasAllTheVehicleDetails) {
      routerHistory.push({ pathname: NEW_QUOTE_PATH, state: { identifyCountry } })
      dispatch(notify(errorIdentifyResultNotification))
      return
    }

    const carModes = [MODES.standard, MODES.resval]
    const bikeModes = [MODES.standard_bike]
    const canSwitchCarMode = Boolean(accessModes.find(element => carModes.includes(element)))
    const canSwitchBikeMode = Boolean(accessModes.find(element => bikeModes.includes(element)))
    const isCarCategory = category === IDENTIFICATION_CATEGORIES.CAR
    const isBikeCategory = category === IDENTIFICATION_CATEGORIES.BIKE

    if (isCarCategory && !carModes.includes(mode) && canSwitchCarMode) {
      dispatch(
        notify(
          errorIdentifyVehicleCategoryIsBike({
            dispatch,
            dismissFirstNotification,
            routerHistory,
            identifiedVehicle,
          }),
        ),
      )
      return
    }

    if (isBikeCategory && !bikeModes.includes(mode) && canSwitchBikeMode) {
      dispatch(
        notify(
          errorIdentifyVehicleCategoryIsCar({
            dispatch,
            dismissFirstNotification,
            routerHistory,
            identifiedVehicle,
          }),
        ),
      )
      return
    }

    routerHistory.push({ pathname: NEW_QUOTE_PATH, state: { identifiedVehicle } })
  }

  const [identify, { loading: isIdentifying }] = useLazyQuery(identifyQuery, {
    onCompleted: ({ identify: data }) => {
      const identifiedVehicle = { ...data, status: 'draft' }

      dispatch(elementIdentify({}))

      if (!identifyMethod.vin && !identifyMethod.plate) return

      dispatch(setCurrentDraftQuoteId(null))

      if (!identifiedVehicle.vin && !identifiedVehicle.plate) {
        dispatch(
          notify(
            errorIdentifySwitchToManualIdentification({
              dispatch,
              dismissFirstNotification,
              routerHistory,
              identifyCountry,
            }),
          ),
        )

        return
      }

      redirectAfterIdendification(identifiedVehicle)
    },
  })

  const isDisabledIdentification =
    [IDENTIFICATION_COUNTRIES.BE, IDENTIFICATION_COUNTRIES.ES].includes(
      identification[identifyCountry],
    ) && mode === MODES.standard_bike
  const isDisabledIdentificationByVIN = mode === MODES.standard_bike

  useEffect(() => {
    Object.keys(identification).forEach(country => {
      if (identification[country].activated === true) {
        setIdentifyCountry(country)
        let definedMode
        const obj = identification[country]
        /* eslint-disable */
        for (const type in obj) {
          if (identification[country][type]?.activated === true) {
            if (!definedMode || definedMode === null) {
              definedMode = type
              setIdentifyMode(type)
            } else {
              break
            }
          }
        }
        /* eslint-enable */
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    identifyMode,
    identifyMethod,
    identifyCountry,
    handleChange,
    handleManualMode,
    handleIdentifyMode,
    identify,
    isIdentifying,
    isDisabledIdentification,
    isDisabledIdentificationByVIN,
  }
}
