// @flow

import React from 'react'
import Button from '@autodisol/ads-js/components/CustomButton'
import { useTranslation } from 'react-i18next'
import Tooltip from '@material-ui/core/Tooltip'
import CommercialPropositionModal from 'quote/cmp/CommercialProposition/CommercialPropositionModal'
import Modal from 'Modal'

export const checkIsDisabled = ({
  hasTradeinOffer,
  isSameUser,
  mustBeUpdateOnlyBySameUser,
  hasViewGroupQuote,
}: {
  hasTradeinOffer: boolean,
  isSameUser: boolean,
  mustBeUpdateOnlyBySameUser: boolean,
  hasViewGroupQuote: boolean,
}): boolean =>
  !(
    hasViewGroupQuote ||
    !mustBeUpdateOnlyBySameUser ||
    (mustBeUpdateOnlyBySameUser && (!hasTradeinOffer || isSameUser))
  )

const CommercialPropositionButton = ({
  rights,
  quoteId,
  quoterPrice,
  tradeinOffer,
  b2cAdvisedPrice,
  userId,
  tradeinOfferConfiguration,
}: {
  rights: string[],
  quoteId: string,
  quoterPrice: Object,
  tradeinOffer: Object,
  b2cAdvisedPrice?: Object,
  userId: string,
  tradeinOfferConfiguration: Object,
}) => {
  const { t } = useTranslation()
  const { open, openModal, closeModal } = Modal.useModal()

  const hasViewGroupQuote = rights.includes('view_group_quote')
  const hasTradeinOffer = tradeinOffer?.amount !== undefined
  const isSameUser = tradeinOffer?.user?.id === userId
  const mustBeUpdateOnlyBySameUser = tradeinOfferConfiguration?.conditions?.same_user ?? false

  const isButtonDisabled = checkIsDisabled({
    hasTradeinOffer,
    isSameUser,
    mustBeUpdateOnlyBySameUser,
    hasViewGroupQuote,
  })

  return (
    <>
      <Tooltip
        title={t('quote.noUpdateCommercialProposition')}
        disableHoverListener={!isButtonDisabled}
        disableFocusListener={!isButtonDisabled}
        placement="top"
      >
        <span style={{ display: 'inline-block', width: '100%' }}>
          <Button
            colorType="primary"
            height="short"
            onClick={openModal}
            fullWidth
            variant="contained"
            disabled={isButtonDisabled}
            style={{ marginBottom: 10 }}
          >
            {t('quote.enterCommercialProposition')}
          </Button>
        </span>
      </Tooltip>
      {open && (
        <CommercialPropositionModal
          rights={rights}
          quoteId={quoteId}
          quoterPrice={quoterPrice}
          tradeinOffer={tradeinOffer}
          b2cAdvisedPrice={b2cAdvisedPrice}
          tradeinOfferConfiguration={tradeinOfferConfiguration}
          open={open}
          closeModal={closeModal}
        />
      )}
    </>
  )
}

export default (CommercialPropositionButton: any)
