// @flow

import React, { useState } from 'react'
import compose from 'recompose/compose'
import { connect as withRedux } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { getModesData, getModeList } from 'utils/modes'
import { setMode } from '_client/redux/actions'

import type { ModeList } from 'utils/modes'
import { useTranslation } from 'react-i18next'

const styles = ({ breakpoints }) => ({
  root: {
    width: '100%',
    position: 'fixed',
    top: 64,
    left: 0,
    zIndex: 1100,
    boxShadow: '0px 3px 6px #00000029',
    backgroundColor: '#fff',
    borderTop: '1px solid #D8D8D8',
    font: 'normal normal bold 16px/19px Lato',
    [breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  center: {
    textAlign: 'center',
  },
})

type Props = {
  classes: Object,
  isDisabledModes: boolean,
  mode: string,
  mustReloadThePage: boolean,
  modeList: ModeList,
  dispatch: Function,
}

const ModesMenuMobile = ({
  classes,
  isDisabledModes,
  mode,
  mustReloadThePage,
  modeList,
  dispatch,
}: Props) => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)

  const handleClickButton = () => {
    setOpen(state => !state)
  }

  const handleClickElement = currentMode => () => {
    setOpen(false)
    dispatch(setMode(currentMode))
    if (mustReloadThePage) window.location.reload()
  }

  const currentMode = modeList.find(element => element.mode === mode)
  const CurrentModeIconComponent = currentMode?.icon

  return (
    <List disablePadding disabled component="nav" className={classes.root}>
      <ListItem disabled={isDisabledModes} button onClick={handleClickButton}>
        {CurrentModeIconComponent && <CurrentModeIconComponent />}
        <ListItemText
          className={!open ? classes.center : undefined}
          inset
          primary={t(currentMode?.translationKey)}
        />
        {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {modeList
            .filter(element => element.mode !== mode)
            .map(element => {
              const IconComponent = element.icon

              return (
                <ListItem
                  onClick={handleClickElement(element.mode)}
                  key={element.mode}
                  button
                  className={classes.nested}
                >
                  <IconComponent />
                  <ListItemText inset primary={t(element.translationKey)} />
                </ListItem>
              )
            })}
        </List>
      </Collapse>
    </List>
  )
}

export default (compose(
  withRedux(({ user, router }) => {
    const { isDisabledModes, currentMode } = getModesData(user, router.location.pathname)
    const modeList = getModeList(user)

    return {
      isDisabledModes,
      mode: currentMode,
      mustReloadThePage: router.location.pathname === '/search',
      modeList,
    }
  }),
  withStyles(styles),
)(ModesMenuMobile): any)
