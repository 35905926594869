// @flow

import * as React from 'react'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import withStyles from '@material-ui/core/styles/withStyles'
import { getErrorsFormatted } from '@autodisol/ads-js/utils/reactHookForm/errors'
import Button from '@autodisol/ads-js/components/CustomButton'
import Modal from 'Modal'
import AlertMessageZone from '@autodisol/ads-js/components/AlertMessage/AlertMessageZone'

const styles = ({ breakpoints }) => ({
  description: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '.875em',
    marginBottom: 50,
    [breakpoints.up('sm')]: {
      marginBottom: 28,
    },
  },
  container: {
    '& > form': {
      padding: '0 20px',
      '& > div:not(div:last-child)': {
        marginBottom: 30,
      },
      '& > div > p': {
        color: '#A8B4BCad',
      },
      [breakpoints.up('sm')]: {
        padding: 0,
        '& > div': {
          marginBottom: 28,
        },
        '& > div:last-child': {
          marginBottom: 0,
        },
      },
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    '& button': {
      margin: '0 20px 10px',
    },
    [breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      '& button': {
        padding: '10px 40px',
        margin: 0,
      },
      '& button:first-child': {
        marginRight: '5%',
      },
      '& button:last-child': {
        marginLeft: '5%',
      },
    },
  },
  errors: {
    marginBottom: 20,
  },
})

type Props = {
  classes: Object,
  children: React.Node,
  handleCloseModal: (closeModal: () => void) => () => void,
  actionButtonContent: string,
  ariaLabelledby: string,
  ariaDescribedby: string,
  modalTitle: string,
  modalDescription?: string,
  errors?: Object,
  isLoading: boolean,
  modalValidateButtonContent: string,
  open: boolean,
  openModal: Function,
  handleClickValidate: Function,
  colorType: string,
  buttonType: 'button' | 'submit',
  formId?: string,
  isLoadingButtonOpenModal?: boolean,
  informationMessage?: React.Node,
}

const ActionWithModal = ({
  classes,
  children,
  handleCloseModal,
  actionButtonContent,
  ariaLabelledby,
  ariaDescribedby,
  modalTitle,
  modalDescription,
  errors,
  isLoading,
  modalValidateButtonContent,
  open,
  openModal,
  handleClickValidate,
  colorType = 'green',
  buttonType = 'submit',
  formId,
  isLoadingButtonOpenModal,
  informationMessage,
}: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <Button
        colorType={colorType}
        height="short"
        variant="contained"
        onClick={openModal}
        fullWidth
        isLoading={isLoadingButtonOpenModal}
        style={{ margin: '6px 0 12px' }}
      >
        {actionButtonContent}
      </Button>

      {open && (
        <Modal
          open={open}
          handleClose={handleCloseModal}
          ariaLabelledby={ariaLabelledby}
          ariaDescribedby={ariaDescribedby}
        >
          <Modal.Header>{modalTitle}</Modal.Header>
          <Modal.Content>
            {modalDescription && <p className={classes.description}>{modalDescription}</p>}

            <div className={classes.container}>{children}</div>
          </Modal.Content>
          <Modal.Actions>
            {errors && Object.keys(errors).length > 0 && (
              <div className={classes.errors}>
                <AlertMessageZone messages={getErrorsFormatted(errors)} />
              </div>
            )}
            <div className={classes.buttons}>
              <Button
                colorType="secondary"
                height="short"
                variant="contained"
                onClick={handleCloseModal}
              >
                {t('globals.cancel')}
              </Button>
              <Button
                form={formId}
                colorType="green"
                height="short"
                variant="contained"
                type={buttonType}
                isLoading={isLoading}
                disabled={isLoading}
                onClick={handleClickValidate}
              >
                {modalValidateButtonContent}
              </Button>
            </div>

            {informationMessage}
          </Modal.Actions>
        </Modal>
      )}
    </>
  )
}

export default (compose(withStyles(styles), withRouter)(ActionWithModal): any)
