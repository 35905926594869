// @flow

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@autodisol/ads-js/components/CustomButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Typography from '@material-ui/core/Typography'
import Pictures from '@autodisol/ads-js/components/Pictures'

const styles = () => ({
  textStyle: { fontWeight: 'bold', textAlign: 'center' },
  buttonFileIcon: {
    color: '#E2EAF0',
    width: 'auto',
    height: 'auto',
  },
  buttonFileIconValidate: {
    color: '#03B564',
    width: 'auto',
    height: 'auto',
  },
  buttonFileIconWrapper: {
    width: 30,
    height: 30,
    position: 'absolute',
    background: '#FFF',
    borderRadius: '100%',
    top: 145,
    right: 0,
  },
  addPicturePlaceholder: {
    '& svg': {
      width: '100%',
      maxWidth: '250px',
    },
  },
})

type Props = {
  classes: Object,
  isDisabled: boolean,
  hasPicture: boolean,
  title: string,
  subtitle?: string,
  pictures: Object[],
  handlePhotoButton: Function,
  isPictureUploading: boolean,
  handleDelete: Function,
  svg: any,
}
const PhotoElement = ({
  classes,
  isDisabled,
  hasPicture,
  title,
  subtitle,
  pictures,
  handlePhotoButton,
  isPictureUploading,
  handleDelete,
  svg,
}: Props) => (
  <Grid item md={6} xs={12}>
    <Button
      onClick={handlePhotoButton}
      disabled={isDisabled}
      className={classes.addPicturePlaceholder}
    >
      {svg}
      <div className={classes.buttonFileIconWrapper}>
        {isPictureUploading ? (
          <CircularProgress size={25} />
        ) : (
          <CheckCircleIcon
            className={hasPicture ? classes.buttonFileIconValidate : classes.buttonFileIcon}
          />
        )}
      </div>
    </Button>

    <Typography className={classes.textStyle}>{title}</Typography>
    {subtitle && <Typography className={classes.textStyle}>{subtitle}</Typography>}
    <Pictures
      pictures={pictures}
      deletable
      maxImagesInARow={1}
      justify="center"
      handleDelete={handleDelete}
      style={{ marginTop: 50 }}
    />
  </Grid>
)

export default (withStyles(styles)(PhotoElement): any)
