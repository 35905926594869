// @flow

/* eslint-disable jsx-a11y/accessible-emoji */

import React, { useRef, useState } from 'react'

import { useQuery, useLazyQuery } from '@apollo/client'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Hidden from '@material-ui/core/Hidden'
import SearchIcon from '@material-ui/icons/Search'

import { setUi } from 'sharyn/redux/actions'
import Button from '@autodisol/ads-js/components/CustomButton'
import { connect as withRedux } from 'react-redux'
import compose from 'recompose/compose'
import HierarchyGroupSelector from '@autodisol/ads-js/components/GroupSelector/HierarchyGroupSelector'
import Divider from '@material-ui/core/Divider'
import { getGroupDetailsQuery, getGroupQueryApollo } from 'quote/quote-queries'
import { PLATE_EXAMPLES, VIN_EXAMPLES, REFERENCE_EXAMPLES } from 'quote/quote-constant'
import { useTranslation } from 'react-i18next'
import { MODES } from 'utils/constants/modes'
import type { Mode } from 'types/modes'

const upperOnChange =
  ({ onChange }) =>
  e => {
    e.target.value = e.target.value.toUpperCase()
    onChange(e)
  }

const QuoteFilterJSX = (props: {
  classes: Object,
  dispatch: Function,
  field: Function,
  isSearchLoading?: boolean,
  onChange: Function,
  quotes?: Object[],
  previousIndeterminateGroups?: string[],
  mainGroupId: string,
  submitHandler: Function,
  identifyCountry: string,
  mode: Mode,
}) => {
  const {
    classes: css,
    field,
    isSearchLoading,
    onChange,
    dispatch,
    previousIndeterminateGroups,
    mainGroupId,
    submitHandler,
    identifyCountry,
    mode,
  } = props

  const { t } = useTranslation()
  const detailsLoading = useRef(false)
  const [loadingGroupId, setLoadingGroupId] = useState(null)
  const [ifListGroupEmpty, setIfListGroupEmpty] = useState(false)

  const [getMainGroupDetails, { data: mainGroupDetails }] = useLazyQuery(getGroupDetailsQuery, {
    onCompleted: response => {
      setIfListGroupEmpty(!(response.list_groups.results.length > 0))
    },
  })
  const [getGroupDetails, { data: details, variables }] = useLazyQuery(getGroupDetailsQuery, {
    onCompleted: () => {
      detailsLoading.current = false
      setLoadingGroupId(null)
    },
  })

  const { loading, data } = useQuery(getGroupQueryApollo, {
    variables: { id: mainGroupId },
    onCompleted: groupData => {
      getMainGroupDetails({
        variables: {
          currentGroupId: groupData.get_group.id,
        },
      })
    },
  })

  return (
    <form onSubmit={submitHandler} style={{ width: '100%' }}>
      <Hidden smDown>
        <Grid container spacing={16}>
          <Grid item xs={12} md={4}>
            {ifListGroupEmpty === false ? (
              <HierarchyGroupSelector
                {...{
                  onChange,
                  data,
                  loading,
                  mainGroupId,
                  mainGroupDetails,
                  details,
                  variables,
                  getGroupDetails,
                  loadingGroupId,
                  setLoadingGroupId,
                  detailsLoading,
                }}
                name="parentEnquirerGroups"
                value={field('parentEnquirerGroups') || []}
                currentGroup={data && data.get_group}
                indeterminatedGroups={previousIndeterminateGroups}
                onIndeterminate={indeterminatedGroups => {
                  dispatch(setUi({ previousIndeterminateGroups: indeterminatedGroups }))
                }}
              />
            ) : (
              t('search.ifListGroupEmpty')
            )}
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid item xs={12} className={css.fields}>
              {mode === MODES.resval && (
                <TextField
                  className={css.field}
                  name="referenceLike"
                  value={field('referenceLike')}
                  label={t('quote.reference')}
                  onChange={upperOnChange({ onChange })}
                  placeholder={`${t('globals.exampleShortColon')} ${
                    REFERENCE_EXAMPLES[identifyCountry] ?? REFERENCE_EXAMPLES.default
                  }`}
                />
              )}
              <TextField
                className={css.field}
                name="plateLike"
                value={field('plateLike')}
                label={t('quote.plate')}
                onChange={upperOnChange({ onChange })}
                placeholder={`${t('globals.exampleShortColon')} ${
                  PLATE_EXAMPLES[identifyCountry] ?? PLATE_EXAMPLES.default
                }`}
              />
              <TextField
                className={css.field}
                name="vinLike"
                value={field('vinLike')}
                label={t('quote.vin')}
                onChange={upperOnChange({ onChange })}
                placeholder={`${t('globals.exampleShortColon')} ${
                  VIN_EXAMPLES[identifyCountry] ?? VIN_EXAMPLES.default
                }`}
              />
            </Grid>
            <Grid
              xs={6}
              style={{ marginTop: 50, position: 'sticky', top: '10rem', display: 'flex' }}
            >
              <Button
                variant="contained"
                colorType="primary"
                height="short"
                type="submit"
                isLoading={isSearchLoading}
                style={{ margin: '0 auto' }}
              >
                <SearchIcon style={{ marginRight: 10 }} />
                {t('globals.search')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      <Hidden mdUp>
        <Grid container direction="column" spacing={16} className={css.container}>
          <Grid item>
            {mode === MODES.resval && (
              <TextField
                className={css.field}
                name="referenceLike"
                value={field('referenceLike')}
                label={t('quote.reference')}
                onChange={upperOnChange({ onChange })}
                placeholder={`${t('globals.exampleShortColon')} ${
                  REFERENCE_EXAMPLES[identifyCountry] ?? REFERENCE_EXAMPLES.default
                }`}
              />
            )}
            <TextField
              className={css.field}
              name="vin"
              value={field('vin')}
              label={t('quote.vin')}
              onChange={upperOnChange({ onChange })}
              placeholder={`${t('globals.exampleShortColon')} ${
                VIN_EXAMPLES[identifyCountry] ?? VIN_EXAMPLES.default
              }`}
            />
            <TextField
              className={css.field}
              name="plateLike"
              value={field('plateLike')}
              label={t('quote.plate')}
              onChange={upperOnChange({ onChange })}
              placeholder={`${t('globals.exampleShortColon')} ${
                PLATE_EXAMPLES[identifyCountry] ?? PLATE_EXAMPLES.default
              }`}
            />
          </Grid>
          <Grid item className={css.dividerWrapper}>
            <Divider className={css.divider} />
          </Grid>
          <Grid item>
            {ifListGroupEmpty === false ? (
              <HierarchyGroupSelector
                {...{
                  onChange,
                  data,
                  loading,
                  mainGroupId,
                  mainGroupDetails,
                  details,
                  variables,
                  getGroupDetails,
                  loadingGroupId,
                  setLoadingGroupId,
                  detailsLoading,
                }}
                name="parentEnquirerGroups"
                value={field('parentEnquirerGroups') || []}
                currentGroup={data && data.get_group}
                indeterminatedGroups={previousIndeterminateGroups}
                onIndeterminate={indeterminatedGroups => {
                  dispatch(setUi({ previousIndeterminateGroups: indeterminatedGroups }))
                }}
              />
            ) : (
              t('search.ifListGroupEmpty')
            )}
          </Grid>
          <Grid item spacing={8} container justify="center" style={{ marginTop: 20 }}>
            <Button
              variant="contained"
              colorType="primary"
              height="short"
              type="submit"
              isLoading={isSearchLoading}
            >
              {t('globals.search')}
            </Button>
          </Grid>
        </Grid>
      </Hidden>
    </form>
  )
}

const QuoteFilter: any = compose(
  withRedux(({ pageData, asyncMap, ui, user }) => {
    const identification = user.data.config.identification.autovista
    let identifyCountry
    Object.keys(identification).forEach(country => {
      if (identification[country].activated === true) {
        identifyCountry = country
      }
    })
    return {
      quotes: pageData.search_quote?.results,
      isSearchLoading: asyncMap.search,
      previousIndeterminateGroups: ui.previousIndeterminateGroups,
      mainGroupId: user?.data?.currentGroup?.id,
      identifyCountry,
    }
  }),
  withStyles(theme => {
    const styles = {
      advancedSearch: { textTransform: 'none', marginBottom: 30 },
      secondSearchPanel: {
        [theme.breakpoints.down('sm')]: {
          marginTop: '0.5rem',
        },
      },
      emptyDateField: { color: theme.palette.secondary.main },
      fields: {
        [theme.breakpoints.up('lg')]: {
          display: 'flex',
          justifyContent: 'space-between',
        },
      },
      field: {
        marginBottom: 20,
        [theme.breakpoints.up('lg')]: {
          margin: '0px 50px 20px',
          '&:first-child': {
            marginLeft: 0,
          },
          '&:last-child': {
            marginRight: 0,
          },
        },
      },
      container: { width: '95%', margin: 'auto' },
      dividerWrapper: { margin: '10px 0px 20px 0px' },
      divider: { width: '90%', margin: 'auto' },
    }
    for (let i = 1; i < 7; i += 1) {
      styles[`order${i}`] = {
        [theme.breakpoints.down('sm')]: {
          order: i,
        },
      }
    }
    return styles
  }),
)(QuoteFilterJSX)

export default QuoteFilter
