// @flow

import React from 'react'
import compose from 'recompose/compose'
import { useTranslation } from 'react-i18next'
import Button from '@autodisol/ads-js/components/CustomButton'
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'
import { SEARCH_PATH } from 'quote/quote-paths'

const styles = ({ breakpoints }) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 30,
    [breakpoints.up('md')]: {
      maxWidth: 350,
    },
  },
  button: {
    marginBottom: 20,
  },
})

type Props = {
  classes: Object,
  history: Object,
  isDisabledCloseButton: boolean,
  isCloseButton: boolean,
  isLoading: boolean,
  handleChangeStep?: Function,
}

const CloseModeFormButtons = ({
  classes,
  history,
  isDisabledCloseButton,
  isCloseButton,
  isLoading,
  handleChangeStep,
}: Props) => {
  const { t } = useTranslation()

  const handleCancel = () => {
    history.push({
      pathname: SEARCH_PATH,
      state: { initialSearchState: 'monitoring' },
    })
  }

  return (
    <div className={classes.container}>
      <Button
        variant="contained"
        colorType="primary"
        type={isCloseButton ? 'submit' : 'button'}
        height="tall"
        onClick={handleChangeStep}
        fullWidth
        disabled={isDisabledCloseButton}
        className={classes.button}
        isLoading={isLoading}
      >
        {isCloseButton ? t('quote.identification.closeQuotation.validate') : t('quote.nextStep')}
      </Button>
      <Button
        variant="contained"
        colorType="secondary"
        height="tall"
        onClick={handleCancel}
        fullWidth
        className={classes.button}
      >
        {t('quote.identification.closeQuotation.cancel')}
      </Button>
    </div>
  )
}

export default (compose(withStyles(styles), withRouter)(CloseModeFormButtons): any)
