// @flow

import { useMutation } from '@apollo/client'
import { closeCaseQuoteQuery } from 'quote/quote-queries'
import { printQuotePath } from 'quote/quote-paths'
import { PRINT_MODE } from 'quote/cmp/PrintQuote/constants'

export const useCloseCaseQuote = (history: Object, field: Function): Function => {
  const [closeQuote, { loading: isLoadingCloseCaseQuote }] = useMutation(closeCaseQuoteQuery)

  const closeCaseQuote = async ({ i, fields, tags }) => {
    const result = await closeQuote({
      variables: {
        quoteInput: {
          id: i?.id,
          deliveryKm: fields.deliveryKm && parseInt(fields.deliveryKm, 10),
          deliveryDate: fields.deliveryDate && {
            date: fields.deliveryDate,
            timezone: 'UTC',
          },
          tags: tags.filter(Boolean),
          mechanicalCost: parseInt(fields.mechanicalCost, 10),
          mechanicalComment: fields.damagesComment,
        },
        comment: fields.feedbackCloseQuotation,
      },
    })

    const closeCaseResponse = result?.data?.close_case_quote
    const id = closeCaseResponse?.id

    if (id) {
      history.push({
        pathname: printQuotePath(id),
        state: {
          options: {
            id,
            plate: closeCaseResponse?.plate,
            make: closeCaseResponse?.make,
            model: closeCaseResponse?.model,
            ownerName: field('customerLastname'),
            ownerFirstname: field('customerFirstname'),
            mode: PRINT_MODE.light,
            isChecked: true,
            isFromCloseQuoteStep: true,
          },
        },
      })
    }
  }

  return { closeCaseQuote, isLoadingCloseCaseQuote }
}
