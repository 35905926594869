// @flow

import moment from 'moment'

type Params = {
  smallestDate: string,
  biggestDate: string,
  measurement: 'years' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds',
  hasfloatingPointNumber: boolean,
}

export const getDiffBetweenTwoDate = ({
  smallestDate,
  biggestDate,
  measurement,
  hasfloatingPointNumber,
}: Params): number => moment(biggestDate).diff(smallestDate, measurement, hasfloatingPointNumber)

export const getFormattedDateByCountryCode = (date: string | Date, code: string): string =>
  new Date(date).toLocaleDateString(code)
