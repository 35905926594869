// @flow

import React from 'react'

import Button from '@material-ui/core/Button'
import { notify, setDynamicData } from 'sharyn/redux/actions'

import { quotePath } from 'quote/quote-paths'
import i18next from '../services/translations/config'

import CorrectionIcon from '../../public/img/CorrectionIcon.svg'

const configureMercure = ({ store, hubUrl }: { store: Object, hubUrl: string }) => {
  const url = new URL(hubUrl)
  url.searchParams.append('topic', 'https://push.autodisol.com/export')
  url.searchParams.append('topic', 'https://push.autodisol.com/quote')

  const es = new EventSource(url.href, { withCredentials: true })

  es.addEventListener('exportReady', (e: Object) => {
    const data = JSON.parse(e.data)
    const { dynamicData } = store.getState()

    store.dispatch(
      setDynamicData({
        exportsReady: {
          ...(dynamicData?.exportsReady && dynamicData.exportsReady),
          [data.data.id]: data.data,
        },
      }),
    )
    store.dispatch(
      notify({
        message: i18next.t('export.exportReady'),
        autoHideDuration: null,
        dismissOnMainAction: false,
        mainAction: (
          <Button color="primary" href={data.data.file} target="_blank">
            {i18next.t('globals.download')}
          </Button>
        ),
      }),
    )
  })

  es.addEventListener('quoteAskCorrection', (e: Object) => {
    const { data } = JSON.parse(e.data)

    store.dispatch(
      notify({
        title: `${data.quote.make} ${data.quote.model}`,
        subTitle: i18next.t('globals.correctionRequest'),
        message: data.changeAskCorrection,
        autoHideDuration: null,
        dismissOnMainAction: false,
        icon: <CorrectionIcon style={{ width: '2rem', fill: 'white' }} />,
        mainAction: (
          <Button color="primary" href={quotePath(data.quote.id)} target="_blank">
            {i18next.t('quote.editDemand')}
          </Button>
        ),
      }),
    )
  })
}

export default configureMercure
