// @flow

import { useState } from 'react'
import { pricingQuoteQuery, acceptQuoteQuery } from 'quote/quote-queries'
import { useMutation } from '@apollo/client'
import Modal from 'Modal'

type Params = {
  quoteId: string,
  mustExecuteAcceptQuoteQuery?: boolean,
}

type ReturnType = {
  amount: string,
  open: boolean,
  isLoading: boolean,
  isLoadingPricingQuote: boolean,
  isLoadingAcceptQuote: boolean,
  openModal: Function,
  handleChange: Function,
  handleCloseModal: Function,
  handleUpdatePricingQuote: Function,
  handleSkipThisStep: Function,
}

export const useActualPurchasePrice = ({
  quoteId,
  mustExecuteAcceptQuoteQuery,
}: Params): ReturnType => {
  const [amount, setAmount] = useState('')

  const { open, openModal, closeModal } = Modal.useModal()

  const [acceptQuote, { loading: isLoadingAcceptQuote }] = useMutation(acceptQuoteQuery, {
    variables: {
      quoteId,
    },
    refetchQueries: ['getQuoteAndCountQuery'],
    awaitRefetchQueries: true,
  })
  const [updatePricingQuote, { loading: isLoadingPricingQuote }] = useMutation(pricingQuoteQuery, {
    onCompleted: () => {
      if (mustExecuteAcceptQuoteQuery) acceptQuote()

      if (!mustExecuteAcceptQuoteQuery) closeModal()
    },
    refetchQueries: mustExecuteAcceptQuoteQuery ? undefined : ['getQuoteAndCountQuery'],
    awaitRefetchQueries: !mustExecuteAcceptQuoteQuery,
  })

  const isLoading = isLoadingPricingQuote || isLoadingAcceptQuote

  const handleChange = e => {
    setAmount(e.target.value)
  }

  const handleCloseModal = () => {
    if (isLoading) return

    closeModal()
  }

  const handleUpdatePricingQuote = () => {
    updatePricingQuote({
      variables: {
        id: quoteId,
        amount: Number(amount),
        type: 'tradein_price',
      },
    })
  }

  const handleSkipThisStep = () => {
    acceptQuote()
  }

  return {
    amount,
    open,
    isLoading,
    isLoadingPricingQuote,
    isLoadingAcceptQuote,
    openModal,
    handleChange,
    handleCloseModal,
    handleUpdatePricingQuote,
    handleSkipThisStep,
  }
}
