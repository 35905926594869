// @flow

export const NO_VALUE = '-'
export const NO_PRICE = '- €'
export const IDENTIFICATION_CATEGORIES = {
  CAR: 'CAR',
  BIKE: 'BIKE',
}
export const IDENTIFICATION_COUNTRIES = {
  BE: 'be',
  ES: 'es',
}
export const IDENTIFICATION_VEHICLE_TYPE = {
  MOTO: 'MOTO',
}
export const RESIZE_MAX_WIDTH = 1440
export const RESIZE_MAX_HEIGHT = 1080
export const RESIZE_QUALITY = 90
export const TYPES_MIME = {
  PDF: 'application/pdf',
}
