// @flow
import type { Node } from 'react' /* eslint-disable import/no-extraneous-dependencies */

import React from 'react'
import createGenerateClassName from '@material-ui/core/styles/createGenerateClassName'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
// $FlowIgnore[cannot-resolve-module]
import globalJss from 'jss'
import jssPreset from 'jss-preset-default'
import pick from 'lodash/fp/pick'
import JssProvider from 'react-jss/lib/JssProvider'
import { connect as withRedux, Provider } from 'react-redux'
import BrowserRouter from 'react-router-dom/BrowserRouter'
import StaticRouter from 'react-router-dom/StaticRouter'
import compose from 'recompose/compose'
import mapProps from 'recompose/mapProps'
import nest from 'recompose/nest'
import withProps from 'recompose/withProps'
import GlobalStylesProvider from '@sharyn/components/GlobalStylesProvider'
import createSharynStore from '@sharyn/redux/store'
import spread from '@sharyn/util/spread'
import spreadIf from '@sharyn/util/spread-if'

const defaultJss = globalJss.setup(jssPreset())

const DynamicThemeProvider = compose(
  withRedux(({ user }) => ({ customTheme: user?.data.customTheme })),
  withProps(({ theme, customTheme, isSsr }) => {
    const customizedTheme = { ...theme }

    if (customTheme) {
      customizedTheme.palette = {
        ...theme.palette,
        primary: {
          ...theme.palette?.primary,
          ...customTheme.palette?.primary,
        },
        secondary: {
          ...theme.palette?.secondary,
          ...customTheme.palette?.secondary,
        },
        text: {
          ...theme.palette?.text,
          ...customTheme.palette?.text,
        },
        error: {
          ...theme.palette?.error,
          ...customTheme.palette?.error,
        },
      }
      customizedTheme.overrides = {
        ...theme.overrides,
        MuiBottomNavigationAction: {
          ...theme.overrides.MuiBottomNavigationAction,
          root: {
            ...theme.overrides?.MuiBottomNavigationAction.root,
            ...customTheme.overrides?.MuiBottomNavigationAction.root,
          },
        },
        MuiTableRow: {
          ...theme.overrides.MuiTableRow,
          root: {
            ...theme.overrides?.MuiTableRow.root,
            ...customTheme.overrides?.MuiTableRow?.root,
          },
        },
      }
    }

    return {
      theme: customizedTheme,
      ...spreadIf(isSsr, { sheetsManager: new Map() }),
    }
  }),
  mapProps(props => pick(['theme', 'sheetsManager', 'children'], props)),
)(MuiThemeProvider)

type ProvidersProps = {
  children?: any,
  App?: Function,
  store?: Object,
  theme: Object,
  globalStyles?: any,
  jss?: Object,
  isSsr?: boolean,
  url?: string,
  routerContext?: Object,
  sheetsRegistry?: Object,
  theme: Object,
}

const Providers = ({
  children,
  App,
  theme,
  globalStyles,
  store = createSharynStore(),
  isSsr,
  url,
  jss = defaultJss,
  routerContext,
  sheetsRegistry,
}: ProvidersProps): Node => {
  const NestedProviders = nest(
    withProps({ store })(Provider),
    withProps({ theme, isSsr })(DynamicThemeProvider),
    isSsr
      ? withProps(spread({ location: url, context: routerContext }))(StaticRouter)
      : BrowserRouter,
    withProps({
      jss,
      generateClassName: createGenerateClassName(),
      ...spread({ registry: sheetsRegistry }),
    })(JssProvider),
    withProps({ globalStyles })(GlobalStylesProvider),
  )
  return <NestedProviders>{App ? <App /> : children}</NestedProviders>
}

export default Providers
