// @flow

/* eslint-disable no-alert */

import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import withStyles from '@material-ui/core/styles/withStyles'
import Page from 'sharyn/components/Page'
import { clearDynamicData } from 'sharyn/redux/actions'
import { connect as withRedux } from 'react-redux'
import compose from 'recompose/compose'
import Hidden from '@material-ui/core/Hidden'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import ExportIcon from '@material-ui/icons/Publish'
import Button from '@autodisol/ads-js/components/CustomButton'
import Pagination, {
  defaultPage,
  defaultNbElementByPage,
} from '@autodisol/ads-js/components/Pagination'
import moment from 'moment'

import { listExportsQuery, listDocumentsQuery } from 'quote/quote-queries'
import { useTranslation } from 'react-i18next'

const updateResults =
  ({ listExports }) =>
  (page, nbElementByPage) => {
    listExports({
      variables: {
        page: page || defaultPage,
        nbElementByPage: nbElementByPage || defaultNbElementByPage,
      },
    })
  }

type ExportedPageProps = {
  classes: Object,
  exportsReady: Object,
  dispatch: Function,
}

const ExportedPageJSX = ({ classes: css, exportsReady, dispatch }: ExportedPageProps) => {
  const { t } = useTranslation()

  const [loadingExport, setLoadingExport] = useState(0)
  const [downloadFlag, setDownloadFlag] = useState(false)
  const [exports, setExports] = useState({})
  const [getDocuments] = useLazyQuery(listDocumentsQuery, {
    onCompleted: documentsData => {
      setLoadingExport(0)
      // eslint-disable-next-line
      const fileUri = documentsData?.list_documents.results[0]?.uri
      if (fileUri && downloadFlag) {
        window.location = fileUri
        setDownloadFlag(false)
      }
    },
  })
  const [listExports, { loading: isTableLoading }] = useLazyQuery(listExportsQuery, {
    // eslint-disable-next-line
    onCompleted: ({ list_exports }) => setExports(list_exports),
  })

  useEffect(() => {
    listExports({
      variables: {
        filters: { orderBy: 'createdAt:desc' },
      },
    })

    return () => {
      dispatch(clearDynamicData())
    }
  }, [dispatch, listExports])

  const handleDownload = exportObject => {
    setLoadingExport(exportObject.id)
    setDownloadFlag(true)
    getDocuments({
      variables: {
        filters: { ids: [exportsReady[exportObject.id]?.document ?? exportObject.document] },
      },
    })
  }
  return (
    <Page noPaper paperClass={css.exportList}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="dense">{t('export.exportDate')}</TableCell>
            <Hidden xsDown>
              <TableCell padding="dense" align="center">
                {t('quote.type')}
              </TableCell>
            </Hidden>
            <Hidden smDown>
              <TableCell padding="dense" align="center">
                {t('export.formatWord')}
              </TableCell>
            </Hidden>
            <TableCell padding="dense" align="center">
              {t('quote.status')}
            </TableCell>
            <TableCell padding="dense" align="center">
              {t('export.downloadLink')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {exports.results &&
            !isTableLoading &&
            exports.results.map(e => {
              const exportReady = exportsReady[e.id]

              return (
                <TableRow key={e.id}>
                  <TableCell padding="dense">
                    {moment.utc(e.createdAt.date).local().calendar(null, {
                      lastWeek: 'DD/MM/YYYY HH:mm',
                      sameElse: 'DD/MM/YYYY HH:mm',
                    })}
                  </TableCell>
                  <Hidden xsDown>
                    <TableCell padding="dense" align="center">
                      {t(`export.type.${e.type}`)}
                    </TableCell>
                  </Hidden>
                  <Hidden smDown>
                    <TableCell padding="dense" align="center">
                      {t(`export.format.${e.format}`)}
                    </TableCell>
                  </Hidden>
                  <TableCell padding="dense" align="center">
                    {exportReady
                      ? t('globals.allStatus.ready')
                      : t(`globals.allStatus.${e.status}`)}
                  </TableCell>
                  <TableCell padding="dense" align="center">
                    {e.status === 'ready' || Boolean(exportsReady) ? (
                      <Button
                        colorType="primary"
                        disabled={!exportReady && (e.status !== 'ready' || loadingExport !== 0)}
                        onClick={() => handleDownload(e)}
                        isLoading={loadingExport === e.id}
                      >
                        <span style={{ fontSize: '0.8rem' }}>{t('globals.download')}</span>
                        <ExportIcon
                          size={20}
                          style={{ transform: 'rotate(180deg)', marginLeft: 5 }}
                        />
                      </Button>
                    ) : (
                      <span>--</span>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
      {isTableLoading && <span>{t('export.loading')}</span>}
      {exports?.length === 0 && <div style={{ marginTop: 20 }}>{t('export.noExport')}</div>}
      <Pagination updateResults={updateResults({ listExports })} count={exports.nbResults ?? 0} />
    </Page>
  )
}

const ExportedPage: any = compose(
  withRedux(({ dynamicData }) => ({
    exportsReady: dynamicData.exportsReady ?? {},
  })),
  withStyles(() => ({
    exportList: {
      padding: '30px',
      '@media (max-width: 380px)': {
        padding: '30px 10px',
      },
      '@media (max-width: 335px)': {
        padding: '30px 0',
      },
    },
  })),
)(ExportedPageJSX)

export default ExportedPage
