// @flow

import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import ReactMarkdown from 'react-markdown'
import breaks from 'remark-breaks'
import gfm from 'remark-gfm'
import AppBar from '@material-ui/core/AppBar'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import AddIcon from '@material-ui/icons/Add'
import HelperIcon from '@material-ui/icons/Help'
import MonitoringIcon from '@material-ui/icons/AssignmentOutlined'
import CloseIcon from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu'
import GroupIcon from '@material-ui/icons/FolderShared'
import UserIcon from '@material-ui/icons/Person'
import Alert from '@autodisol/ads-js/components/Alert'
import { secondaryColorLight } from '@autodisol/ads-js/components/styles'
import { useTranslation } from 'react-i18next'
import { connect as withRedux } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Link from 'react-router-dom/Link'
import compose from 'recompose/compose'
import withStateHandlers from 'recompose/withStateHandlers'
import DrawerItem from 'sharyn/components/DrawerItem'
import NavList from 'sharyn/components/NavList'
import RefreshButton from 'sharyn/components/RefreshButton'
import ModesMenu from 'app/cmp/ModesMenu'
import NavigateBefore from '@material-ui/icons/NavigateBefore'
import { HOME_PATH } from 'home/home-paths'
import { updateUserGroup, setMode } from '_client/redux/actions'
import { logoutRoute } from 'auth/auth-routes'
import { homeRoute } from 'home/home-routes'
import { IDENTIFY_PATH, SEARCH_PATH } from 'quote/quote-paths'
import { switchGroupQuery } from 'quote/quote-queries'
import { identifyRoute, exportListRoute, searchRoute } from 'quote/quote-routes'
import { getModesData, getAccessMode } from 'utils/modes'
import { getLanguage } from 'utils/language'
import ModesSelect from 'app/cmp/ModesMenu/ModesSelect'
import SearchBar from 'app/cmp/SearchBar'
import { MODES } from 'utils/constants/modes'
import * as Sentry from '@sentry/react'

import LogoPicture from '../../../public/img/LogoPicture.svg'

const languageSelect = withStyles({
  selectRoot: {
    border: 'none',
    width: 'fit-content',
  },
  selectMenu: {
    display: 'flex',
  },
})(({ classes: css }: { classes: Object }) => {
  const { i18n } = useTranslation()
  const currentLanguage = getLanguage()

  const handleLanguage = event => {
    setTimeout(() => {
      i18n.changeLanguage(event.target.value)
      window.localStorage.setItem('user-language', event.target.value)
      window.location.reload()
    }, 300)
  }

  return (
    <Select
      className={css.selectRoot}
      onChange={handleLanguage}
      value={currentLanguage}
      classes={{ select: css.selectMenu }}
    >
      {[
        { label: 'Français', language: 'fr', file: 'fr.svg' },
        { label: 'English', language: 'en', file: 'uk.svg' },
        { label: 'Español', language: 'es', file: 'es.svg' },
        { label: 'Nederlands', language: 'nl', file: 'nl.svg' },
        { label: 'Català', language: 'ca', file: 'ca.svg' },
      ].map(({ label, language, file }) => (
        <MenuItem key={language} value={language}>
          <ListItemIcon>
            <img src={`static/img/flags/${file}`} alt="" width="24" height="24" />
          </ListItemIcon>
          <ListItemText primary={label} />
        </MenuItem>
      ))}
    </Select>
  )
})

const QuoteMonitoringDrawerItem = () => {
  const { t } = useTranslation()

  return (
    <Link
      to={{ pathname: SEARCH_PATH, state: { initialSearchState: 'monitoring' } }}
      key={searchRoute.path}
    >
      <DrawerItem label={t('header.menu.quotesMonitoring')} icon={MonitoringIcon} />
    </Link>
  )
}

const SearchDrawerItem = () => {
  const { t } = useTranslation()

  return (
    <Link to={SEARCH_PATH} key={`${searchRoute.path}2`}>
      <DrawerItem label={t('globals.searchNoun')} icon={searchRoute.icon} />
    </Link>
  )
}

const LogoutDrawerItem = withStyles({
  logoutItem: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 56,
    padding: '0 20px',
    justifyContent: 'left',
    borderTop: '1px solid #D2D6D9',
  },
})(({ classes: css }: { classes: Object }) => (
  <div className={css.logoutItem}>
    <Link to={logoutRoute.path} key={logoutRoute.path}>
      <DrawerItem label={logoutRoute.title} icon={logoutRoute.icon} />
    </Link>
  </div>
))

const Version = withStyles({
  logoutItem: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 85,
    padding: '0 20px',
    textAlign: 'right',
    color: '#A8B4BC',
    fontSize: '0.875rem',
  },
})(({ classes: css, appVersion }: { classes: Object, appVersion: string }) => (
  <div className={css.logoutItem}>{appVersion}</div>
))

const DrawerCloseButton = withStyles(() => ({
  closeButton: {
    padding: 0,
  },
  closeIcon: {
    width: '2.44rem',
    height: '2.44rem',
    fontSize: '3rem',
  },
}))(({ classes: css, onClick }: { classes: Object, onClick: Function }) => (
  <IconButton className={css.closeButton} {...{ onClick }}>
    <CloseIcon className={css.closeIcon} />
  </IconButton>
))

const HelperInfo = ({
  handleAlertOpening,
  handleAlertClosing,
  helpInfo,
}: {
  handleAlertOpening: Function,
  handleAlertClosing: Function,
  helpInfo: string,
}) => {
  const { t } = useTranslation()

  return (
    <DrawerItem
      label={t('globals.help')}
      icon={HelperIcon}
      onClick={() =>
        handleAlertOpening({
          title: t('globals.help'),
          content: (
            <ReactMarkdown plugins={[breaks, gfm]} skipHtml>
              {helpInfo}
            </ReactMarkdown>
          ),
          handleNegativeClose: () => handleAlertClosing(),
        })
      }
    />
  )
}

const navItems = hasAModeSelected => [
  homeRoute,
  ...(hasAModeSelected
    ? [
        identifyRoute,
        { component: QuoteMonitoringDrawerItem },
        { component: SearchDrawerItem },
        exportListRoute,
      ]
    : []),

  { component: languageSelect },
]

const handleGroupSwitch =
  ({ dispatch, myGroups, switchGroup, mustReloadThePage }) =>
  async event => {
    const resp = await switchGroup({
      variables: {
        groupId: event.target.value,
      },
    })

    const switchGroupData = resp.data.switch_group

    if (switchGroupData.currentGroup) {
      const selectedGroup = myGroups && myGroups.find(group => group.id === event.target.value)
      const accessModes = getAccessMode({ data: switchGroupData })

      dispatch(setMode(accessModes.includes(MODES.standard) ? MODES.standard : accessModes[0]))
      dispatch(
        updateUserGroup({
          newGroup: selectedGroup,
          newRights: switchGroupData.rights,
          newConfig: switchGroupData.config,
        }),
      )

      const scope = Sentry.getCurrentScope()

      scope.setContext('user group', { id: selectedGroup?.id, name: selectedGroup?.name })

      if (mustReloadThePage) window.location.reload()
    }
  }

const GroupSelect = withStyles(({ palette }) => ({
  selectRoot: {
    border: 'none',
    width: 'fit-content',
    '& svg': {
      color: palette.secondary.dark,
    },
  },
  selectMenu: {
    display: 'flex',
    paddingTop: 11,
    paddingBottom: 11,
    '&:focus': {
      background: 'none',
    },
  },
}))(
  ({
    classes: css,
    user,
    dispatch,
    isSwitchingGroup,
    iconStyle,
    textStyle,
    switchGroup,
    mustReloadThePage,
  }: {
    classes: Object,
    user?: Object,
    dispatch: Function,
    isSwitchingGroup?: boolean,
    iconStyle?: Object,
    textStyle?: Object,
    switchGroup: Function,
    mustReloadThePage: Boolean,
  }) => (
    <Select
      className={css.selectRoot}
      onChange={handleGroupSwitch({
        dispatch,
        myGroups: user?.groups,
        switchGroup,
        mustReloadThePage,
      })}
      value={user?.currentGroup?.id}
      classes={{ select: css.selectMenu }}
    >
      {user &&
        user.groups &&
        user.groups.map(group => (
          <MenuItem key={group.id} value={group.id}>
            <ListItemIcon>
              {isSwitchingGroup ? <CircularProgress size={24} /> : <GroupIcon style={iconStyle} />}
            </ListItemIcon>
            <ListItemText
              primary={group.name}
              primaryTypographyProps={
                group.id === user?.currentGroup.id ? { color: 'textSecondary' } : null
              }
              style={{ ...textStyle, paddingRight: 0, display: 'flex', alignItems: 'center' }}
            />
          </MenuItem>
        ))}
    </Select>
  ),
)

type NavProps = {
  classes: Object,
  className: string,
  history: Object,
  title?: string | Function,
  subtitle?: string,
  user?: Object,
  isDrawerOpen: boolean,
  openDrawer: Function,
  closeDrawer: Function,
  dispatch: Function,
  isSwitchingGroup?: boolean,
  logo?: Object,
  appName?: string,
  backNav?: string,
  appVersion: string,
  helpInfo?: string,
  isLoadingRefrechUserAndGroups?: boolean,
  hasAModeSelected: boolean,
  isDisplayModesMenu: boolean,
  hasRightsToMakeNewRequests: boolean,
  mustReloadThePage: boolean,
  groupId: string,
}

const NavJSX = ({
  classes: css,
  className,
  history,
  title,
  subtitle,
  user = {},
  isDrawerOpen,
  openDrawer,
  closeDrawer,
  dispatch,
  logo,
  appName,
  backNav,
  appVersion,
  helpInfo,
  isLoadingRefrechUserAndGroups,
  hasAModeSelected,
  isDisplayModesMenu,
  hasRightsToMakeNewRequests,
  mustReloadThePage,
  groupId,
}: NavProps) => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState({ isOpened: false })
  const [switchGroup, { loading: isSwitchGroupLoading }] = useMutation(switchGroupQuery)
  const isSwitchingGroup = isLoadingRefrechUserAndGroups || isSwitchGroupLoading

  const handleAlertOpening = options =>
    setOpenModal({ isOpened: true, hideButton: true, ...options })
  const handleAlertClosing = () => setOpenModal({ isOpened: false, hideButton: true, ...openModal })

  return (
    <div {...{ className }}>
      <Alert {...openModal} />
      <AppBar className="hide-on-scroll">
        <Toolbar className={css.toolbar}>
          {backNav && (
            <IconButton
              className={css.backButton}
              color="inherit"
              aria-label="Back"
              onClick={() => {
                backNav
                  ? history.push({
                      pathname: backNav,
                      state: history?.location?.state,
                    })
                  : history.goBack()
              }}
            >
              <NavigateBefore />
            </IconButton>
          )}
          {!backNav && (
            <IconButton className={css.logo} color="inherit" component={Link} to={HOME_PATH}>
              {logo?.content ? (
                <div
                  style={{ width: 30, height: 30 }}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: logo?.content }}
                />
              ) : (
                <LogoPicture style={{ width: 30, height: 30, fill: 'white' }} />
              )}
            </IconButton>
          )}
          <div className={subtitle ? css.titleWithSubtitle : css.title}>
            <Hidden xsDown>
              {isDisplayModesMenu && (
                <ModesMenu key={groupId} anchorPosition={{ translateX: 10, translateY: -16 }} />
              )}
              <Typography variant="h1" className={css.app__name__with__title}>
                <span style={{ color: '#A8B4BC', paddingLeft: 20 }}>{appName} /</span> {title}
              </Typography>
            </Hidden>
            <Hidden smUp>
              <Typography variant="h1">
                <span style={{ color: '#A8B4BC' }}>{appName} / </span>
              </Typography>
              <Typography variant="h1" color="inherit">
                {title}
              </Typography>
            </Hidden>

            {subtitle && (
              <Typography variant="subtitle1" color="inherit">
                - {subtitle} -
              </Typography>
            )}
          </div>
          <RefreshButton />

          <Hidden mdDown>
            {hasRightsToMakeNewRequests && (
              <Tooltip title={t('header.newQuote')} placement="bottom">
                <IconButton
                  color="inherit"
                  style={{ marginRight: '0.5rem' }}
                  component={Link}
                  to={IDENTIFY_PATH}
                >
                  <AddIcon style={{ fontSize: '1.8rem' }} />
                </IconButton>
              </Tooltip>
            )}
            {hasAModeSelected && (
              <Tooltip title={t('header.tooltipSearch')} placement="bottom">
                <IconButton
                  color="inherit"
                  style={{ marginRight: '0.5rem' }}
                  component={Link}
                  to={{ pathname: SEARCH_PATH, state: { initialSearchState: 'monitoring' } }}
                >
                  <MonitoringIcon style={{ fontSize: '1.8rem' }} />
                </IconButton>
              </Tooltip>
            )}
            <GroupSelect
              {...{ user, dispatch, isSwitchingGroup, switchGroup, mustReloadThePage }}
              iconStyle={{ fontSize: '2rem' }}
              textStyle={{ paddingLeft: 0 }}
            />
            {hasAModeSelected && <SearchBar style={{ marginLeft: 20 }} />}
          </Hidden>

          <IconButton color="inherit" onClick={openDrawer}>
            <MenuIcon style={{ fontSize: '2rem' }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={css.appBarPusher} />
      <SwipeableDrawer
        anchor="right"
        open={isDrawerOpen}
        disableBackdropTransition
        onOpen={openDrawer}
        onClose={closeDrawer}
        classes={{ paper: css.drawer }}
      >
        <div className={css.wrapper__searchbar__and__close__button}>
          {hasAModeSelected && <SearchBar className={css.drawerSearchItem} />}
          <DrawerCloseButton onClick={closeDrawer} />
        </div>
        <DrawerItem label={`${user.firstname} ${user.lastname}`} icon={UserIcon} />
        {isDisplayModesMenu && <ModesSelect />}
        <GroupSelect {...{ user, dispatch, isSwitchingGroup, switchGroup, mustReloadThePage }} />
        <Divider style={{ marginTop: 8 }} />
        <NavList navItems={navItems(hasAModeSelected)} />
        {helpInfo && <HelperInfo {...{ handleAlertOpening, handleAlertClosing, helpInfo }} />}
        <Version {...{ appVersion }} />
        <LogoutDrawerItem />
      </SwipeableDrawer>
    </div>
  )
}

export const NavCmp: any = compose(
  withStateHandlers(
    { isDrawerOpen: false, isUserMenuOpen: false, userMenuAnchorEl: null },
    {
      openDrawer: () => () => ({ isDrawerOpen: true }),
      closeDrawer: () => () => ({ isDrawerOpen: false }),
    },
  ),
  withRouter,
  withStyles(({ spacing, mixins, breakpoints, palette }) => ({
    appBarPusher: mixins.toolbar,
    backButton: {
      margin: '0 22px 0 12px',
      background: secondaryColorLight,
      borderRadius: '50%',
      width: 30,
      height: 30,
      color: '#FFFFFF',
    },
    logo: {
      marginRight: 0,
      [breakpoints.up('sm')]: {
        marginRight: 10,
      },
    },
    toolbar: {
      justifyContent: 'space-between',
    },
    title: {
      textAlign: 'center',
      [breakpoints.up('sm')]: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    titleWithSubtitle: {
      extend: 'title',
      marginTop: '0.5rem',
    },
    userMenuUserItem: {
      textAlign: 'center',
      fontWeight: 'bold',
      paddingTop: spacing.unit,
      paddingBottom: spacing.unit * 2,
      '&:focus': { outline: 0 },
    },
    drawerSearchItem: {
      width: 'fit-content',
      '@media (max-width: 380px)': {
        width: '75%',
      },
    },
    drawer: {
      '& svg': {
        color: palette.secondary.dark,
      },
      '& span': {
        fontSize: '1rem',
      },
    },
    modes__menu__wrapper: {
      padding: '5px 16px',
    },
    userMenuItemIcon: { marginRight: spacing.unit },
    wrapper__searchbar__and__close__button: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: 30,
      '& > form': {
        width: '100%',
      },
    },
    app__name__with__title: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
    },
  })),
)(NavJSX)

const Nav: any = withRedux(({ user, ui, env, router }) => {
  const { currentMode, hasAModeSelected, isDisplayModesMenu } = getModesData(
    user,
    router.location.pathname,
  )
  const { rights } = user.data

  return {
    user: user.data,
    helpInfo: user.data.config.user?.content?.help?.text,
    /* eslint-disable */
    appName: user?.data.config.user?.theme?.app_names?.provider?.name,
    logo: user.data.config.user?.theme?.app_logo?.square,
    /* eslint-enable */
    subtitle: ui.subtitle,
    appVersion: env.APP_VERSION,
    hasAModeSelected,
    isDisplayModesMenu,
    mustReloadThePage: router.location.pathname === '/search',
    hasRightsToMakeNewRequests:
      Boolean(rights.find(right => right === 'identify')) &&
      Boolean(rights.find(right => right === 'create_quote')) &&
      hasAModeSelected &&
      user.data.config.quote.quote[currentMode]?.activated !== false &&
      user.data.config.quote.quote[currentMode]?.readonly !== true,
    groupId: user.data.currentGroup.id,
  }
})(NavCmp)

export default Nav
