// @flow

import React from 'react'
import { connect as withRedux } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Card from '@autodisol/ads-js/components/Card'
import Price from 'quote/cmp/QuoteActionsPanel/Resval/Price'
import CorrectionAction from 'quote/cmp/QuoteActionsPanel/Resval/CorrectionAction'
import SentInFleetAction from 'quote/cmp/QuoteActionsPanel/Resval/SentInFleetAction'
import DuplicateRequestAction from 'quote/cmp/QuoteActionsPanel/Resval/DuplicateRequestAction'
import AcceptAction from 'quote/cmp/QuoteActionsPanel/AcceptAction'
import RejectAction from 'quote/cmp/QuoteActionsPanel/Resval/RejectAction'
import UpdateTheRequestAction from 'quote/cmp/QuoteActionsPanel/Resval/UpdateTheRequestAction'
import CloseCaseQuoteAction from 'quote/cmp/QuoteActionsPanel/Resval/CloseCaseQuoteAction'
import AffectTheVehicleAction from 'quote/cmp/QuoteActionsPanel/Resval/AffectTheVehicleAction'
import KeepTheVehicleAction from 'quote/cmp/QuoteActionsPanel/Resval/KeepTheVehicleAction'
import Divider from 'quote/cmp/QuoteActionsPanel/Divider'
import QuoteInfos from 'quote/cmp/QuoteActionsPanel/QuoteInfos'
import { getSubStatus } from '@autodisol/ads-js/utils/functions'
import {
  QUOTE_STATUS_CHANGES_REQUESTED,
  QUOTE_STATUS_QUOTED,
  QUOTE_STATUS_REJECTED,
  QUOTE_STATUS_ACCEPTED,
  QUOTE_STATUS_FLEET,
  QUOTE_STATUS_UPDATED_FLEET,
  QUOTE_STATUS_CLOSED,
  RIGHT_ACCEPT_REJECT_QUOTE,
  RIGHT_CREATE_QUOTE,
  RIGHT_SEND_IN_FLEET_QUOTE,
  RIGHT_UPDATE_FLEET_QUOTE,
  RIGHT_CLOSE_CASE_QUOTE,
  RIGHT_REMOVE_SITE_QUOTE,
  RIGHT_ADD_SITE_QUOTE,
  RIGHT_ASSIGN_QUOTE,
  RIGHT_KEEP_ITEM_QUOTE,
  RIGHT_CORRECT_QUOTE,
} from '_client/config'

type Props = {
  id: string,
  quoterPrice?: Object,
  quoterPriceNotMarged?: Object,
  damagePrice?: Object,
  pricingComment?: string,
  status: string,
  askCorrectionComment?: string,
  rights: string[],
  expired?: boolean,
  archived?: boolean,
  defaultAuctionGroupId: string,
  pendingUpdateExpired?: boolean,
  withdrawalSite?: Object,
  isPendingUpdate?: boolean,
  hasAlreadyBeenAppreciated: boolean,
}

const QuoteActionsPanelResval = ({
  id,
  quoterPrice,
  quoterPriceNotMarged,
  damagePrice,
  pricingComment,
  status,
  askCorrectionComment,
  rights,
  expired,
  archived,
  defaultAuctionGroupId,
  pendingUpdateExpired,
  withdrawalSite,
  isPendingUpdate,
  hasAlreadyBeenAppreciated,
}: Props) => {
  const { t } = useTranslation()

  const hasRightToAcceptAndRejectQuote = rights.includes(RIGHT_ACCEPT_REJECT_QUOTE)
  const hasRightToCreateQuote = rights.includes(RIGHT_CREATE_QUOTE)
  const hasRightToUpdateFleetQuote = rights.includes(RIGHT_UPDATE_FLEET_QUOTE)
  const hasRightToCloseQuote = rights.includes(RIGHT_CLOSE_CASE_QUOTE)
  const hasRightToAffectVehicle = [
    RIGHT_REMOVE_SITE_QUOTE,
    RIGHT_ADD_SITE_QUOTE,
    RIGHT_ASSIGN_QUOTE,
  ].every(element => rights.includes(element))
  const hasRightToKeepTheVehicle = rights.includes(RIGHT_KEEP_ITEM_QUOTE)
  const hasRightToSendInFleetQuote = rights.includes(RIGHT_SEND_IN_FLEET_QUOTE)
  const hasRightToCorrectQuote = rights.includes(RIGHT_CORRECT_QUOTE)

  const quoteStatus = {
    changesRequest: status === QUOTE_STATUS_CHANGES_REQUESTED && hasRightToCorrectQuote,
    quoted:
      status === QUOTE_STATUS_QUOTED && (hasRightToCreateQuote || hasRightToAcceptAndRejectQuote),
    rejected: status === QUOTE_STATUS_REJECTED && Boolean(quoterPrice),
    accepted: status === QUOTE_STATUS_ACCEPTED && hasRightToSendInFleetQuote,
    fleet: status === QUOTE_STATUS_FLEET && (hasRightToUpdateFleetQuote || hasRightToCloseQuote),
    updatedFleet: status === QUOTE_STATUS_UPDATED_FLEET && hasRightToAcceptAndRejectQuote,
    closed: status === QUOTE_STATUS_CLOSED && (hasRightToAffectVehicle || hasRightToKeepTheVehicle),
  }

  const mustDisplayADivider = Object.values(quoteStatus).some(element => element === true)
  const isDisplayUpdateFleetStatus = isPendingUpdate && status === 'waiting'
  const subStatus = getSubStatus(expired, archived)

  return (
    <Card>
      <QuoteInfos
        status={status}
        subStatus={subStatus}
        withdrawalSite={withdrawalSite}
        isDisplayUpdateFleetStatus={isDisplayUpdateFleetStatus}
        hasAlreadyBeenAppreciated={hasAlreadyBeenAppreciated}
      />

      <Price
        id={id}
        comment={pricingComment}
        quoterPrice={quoterPrice?.amount ?? 0}
        quoterPriceNotMarged={quoterPriceNotMarged}
        damagePrice={damagePrice?.amount ?? 0}
      />

      {mustDisplayADivider && <Divider />}

      {quoteStatus.changesRequest && (
        <CorrectionAction id={id} askCorrectionComment={askCorrectionComment} />
      )}
      {quoteStatus.quoted && (
        <>
          <AcceptAction id={id} content={t('quote.accept')} expired={expired} />
          <DuplicateRequestAction id={id} />
          <RejectAction id={id} content={t('quote.reject')} />
        </>
      )}
      {quoteStatus.rejected && (
        <AcceptAction id={id} content={t('quote.accept')} expired={expired} />
      )}
      {quoteStatus.accepted && <SentInFleetAction id={id} />}
      {quoteStatus.fleet && (
        <>
          <UpdateTheRequestAction id={id} />
          <CloseCaseQuoteAction id={id} />
        </>
      )}
      {quoteStatus.updatedFleet && (
        <>
          <AcceptAction
            id={id}
            content={t('quote.newOfferAccepted')}
            expired={pendingUpdateExpired}
          />
          <RejectAction id={id} content={t('quote.newOfferRejected')} />
        </>
      )}
      {quoteStatus.closed && (
        <>
          <AffectTheVehicleAction id={id} defaultAuctionGroupId={defaultAuctionGroupId} />
          <KeepTheVehicleAction id={id} />
        </>
      )}
    </Card>
  )
}

export default (withRedux(({ user }) => ({
  sendInFleetDelay: user.data.config.quote.quote.resval.send_in_fleet_delay,
  rights: user.data.rights,
}))(QuoteActionsPanelResval): any)
