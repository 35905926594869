// @flow

import type { Mode } from 'types/modes'

type Params = {
  enquirerFullname?: string,
  enquirerGroup?: {
    name?: string,
    root?: {
      name?: string,
    },
  },
  contactFullname?: string | null,
  reference?: string,
  mode: Mode,
}

type ReturnType = {
  value: string,
  secondValue?: string,
  translateKey: string,
  fontWeight?: number,
  tooltip?: Object | null,
}[]

export const getApplicantInformationsData = ({
  enquirerFullname,
  enquirerGroup,
  contactFullname,
  reference,
  mode,
}: Params): ReturnType => {
  const standard = [
    {
      value: contactFullname || enquirerFullname || '',
      translateKey: 'enquirer',
      tooltip: contactFullname ? { key: 'via user', value: enquirerFullname } : null,
    },
    {
      value: enquirerGroup?.name ?? '--',
      secondValue: enquirerGroup?.root?.name ?? '--',
      translateKey: 'applicant group',
    },
  ]
  const resval = [
    ...standard,
    {
      value: reference ?? '--',
      translateKey: 'reference',
      fontWeight: 900,
    },
  ]

  return mode === 'resval' ? resval : standard
}
