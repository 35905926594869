// @flow

import React, { useRef } from 'react'
import Button from '@autodisol/ads-js/components/CustomButton'
import AddIcon from '@material-ui/icons/Add'

type Props = {
  onClick?: Function,
  handleChangeInputFile: Function,
  disabled: boolean,
  isLoading: boolean,
  content: string,
  accept: string,
  height: string,
  style?: Object,
}

const AddPictureButton = ({
  onClick,
  handleChangeInputFile,
  disabled,
  isLoading,
  accept,
  content,
  height,
  style,
}: Props): any => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleClickButton = () => {
    if (onClick) onClick()

    inputRef.current && inputRef.current.click()
  }

  return (
    <>
      <input
        ref={inputRef}
        name="file"
        type="file"
        accept={accept}
        onChange={handleChangeInputFile}
        style={{ display: 'none' }}
      />
      <Button
        colorType="secondary"
        height={height}
        variant="contained"
        onClick={handleClickButton}
        fullWidth
        style={{ maxWidth: 350, ...style }}
        disabled={disabled}
        isLoading={isLoading}
      >
        <AddIcon
          style={{
            marginRight: '20px',
            fontSize: '2.5rem',
            position: 'absolute',
            left: 20,
          }}
        />
        {content}
      </Button>
    </>
  )
}

export default AddPictureButton
