// @flow

import React, { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import withStyles from '@material-ui/core/styles/withStyles'
import HistoryLog from '@autodisol/ads-js/components/HistoryLog'
import Pagination, {
  defaultNbElementByPage,
  defaultPage,
} from '@autodisol/ads-js/components/Pagination'
import moment from 'moment-timezone'
import { getLanguage } from '@autodisol/ads-js/i18n'
import { searchQuoteLogQuery } from 'quote/quote-queries'

const HistoryTabJSX = ({ id }: { id: string }) => {
  const [searchQuoteLog, { loading: isHistoryLoading, data: searchQuoteLogData }] =
    useLazyQuery(searchQuoteLogQuery)
  moment.locale(getLanguage())

  const getQuoteLog = async (page = null, nbElementByPage = null) => {
    await searchQuoteLog({
      variables: {
        id,
        nbElementByPage: nbElementByPage || defaultNbElementByPage,
        page: page || defaultPage,
      },
    })
  }
  useEffect(() => {
    getQuoteLog()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <HistoryLog
        {...{ results: searchQuoteLogData?.search_quotelog?.results ?? [] }}
        isLoading={isHistoryLoading}
        light
      />
      <Pagination
        {...{ count: searchQuoteLogData?.search_quotelog?.nbResults ?? 0 }}
        updateResults={(page, nbElementByPage) => getQuoteLog(page, nbElementByPage)}
      />
    </>
  )
}

const HistoryTab: any = withStyles({
  loadingPagination: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
  },
})(HistoryTabJSX)

export default HistoryTab
