export const getLanguage = () => {
  const localStorageLanguage = localStorage?.getItem('user-language')
  const navigatorLanguage = navigator?.language || navigator?.userLanguage
  let language = 'en-GB'

  if (navigatorLanguage) {
    language = navigatorLanguage
  }

  if (localStorageLanguage) {
    language = localStorageLanguage
  }

  return language.substring(0, 2)
}
