// @flow

import React from 'react'
import SectionTitle from 'quote/cmp/PrintQuote/SectionTitle'
import { useTranslation } from 'react-i18next'
import withStyles from '@material-ui/core/styles/withStyles'
import { MODES } from 'utils/constants/modes'

import type { Mode } from 'types/modes'

const styles = () => ({
  fullname: {
    display: 'flex',
    alignItems: 'baseline',
    fontWeight: 600,
    '& > span:first-child': {
      marginRight: 5,
      flexShrink: 0,
      flexBasis: 'auto',
    },
    '& > span:last-child': {
      borderBottom: '1px dashed',
      flexGrow: 1,
    },
  },
  content: {
    fontWeight: 600,
    textAlign: 'justify',
  },
  date__and__city: {
    display: 'flex',
    alignItems: 'baseline',
    fontWeight: 600,
  },
  date: {
    display: 'flex',
    alignItems: 'baseline',
    width: '30%',
    '& > span:first-child': {
      marginRight: 5,
      flexShrink: 0,
      flexBasis: 'auto',
    },
    '& > span:last-child': {
      borderBottom: '1px dashed',
      flexGrow: 1,
      marginRight: 5,
    },
  },
  city: {
    display: 'flex',
    alignItems: 'baseline',
    width: '70%',
    '& > span:first-child': {
      marginRight: 5,
      flexShrink: 0,
      flexBasis: 'auto',
    },
    '& > span:last-child': {
      borderBottom: '1px dashed',
      flexGrow: 1,
    },
  },
  dashed: {
    borderBottom: '1px dashed',
    display: 'inline-block',
    width: 100,
    margin: '0 5px',
  },
  square: {
    display: 'inline-block',
    width: 16,
    height: 16,
    border: '1px solid #414b56',
    marginRight: 8,
    verticalAlign: 'bottom',
  },
  signatures: {
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    whiteSpace: 'pre-line',
    '& > p': {
      paddingBottom: 70,
    },
    '& > p:last-child': {
      borderLeft: '1px solid #F3F3F3',
      paddingLeft: 10,
    },
  },
})

type Props = {
  classes: Object,
  mode: Mode,
}
const Signature = ({ classes, mode }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <SectionTitle title={t('quote.print.signature.title')} />
      <p className={classes.fullname}>
        <span>{t('quote.print.signature.fullname')}</span> <span />
      </p>
      {mode !== MODES.resval && (
        <>
          <p className={classes.content}>{t('quote.print.signature.content')}</p>
          <p className={classes.content}>
            <span className={classes.square} /> {t('quote.print.signature.actualMileage')}
            <span className={classes.dashed} /> {t('globals.km')}
          </p>
          <p className={classes.content}>
            <span className={classes.square} /> {t('quote.print.signature.unguaranteedMileage')}
            <span className={classes.dashed} /> {t('globals.km')}
          </p>
          <p className={classes.content}>{t('quote.print.signature.resaleValue')}</p>
        </>
      )}
      <p className={classes.date__and__city}>
        <span className={classes.date}>
          <span>{t('quote.print.signature.date')}</span>
          <span />
        </span>
        <span className={classes.city}>
          <span>{t('quote.print.signature.city')}</span>
          <span />
        </span>
      </p>
      <div className={classes.signatures}>
        <p>{t('quote.print.signature.establishment')}</p>
        <p>{t('quote.print.signature.customer')}</p>
      </div>
    </>
  )
}

export default (withStyles(styles)(Signature): any)
