// @flow

import React, { useState } from 'react'
import { connect as withRedux } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Button from '@autodisol/ads-js/components/CustomButton'
import Modal from 'Modal'
import Confirmation from 'quote/cmp/QuoteActionsPanel/Resval/DuplicateRequestAction/Confirmation'
import Form from 'quote/cmp/QuoteActionsPanel/Resval/DuplicateRequestAction/Form'
import { RIGHT_CREATE_QUOTE } from '_client/config'

type Props = {
  id: string,
  rights: string[],
}

const DuplicateRequestAction = ({ id, rights }: Props) => {
  const { t } = useTranslation()
  const { open, openModal, closeModal } = Modal.useModal()
  const [show, setShow] = useState<'confirm' | 'form'>('confirm')

  const hasRightToCreateQuote = rights.includes(RIGHT_CREATE_QUOTE)

  const handleConfirm = () => {
    setShow('form')
  }

  const handleOpenModal = () => {
    setShow('confirm')
    openModal()
  }

  if (!hasRightToCreateQuote) return null

  return (
    <>
      <Button
        colorType="yellow"
        height="short"
        onClick={handleOpenModal}
        fullWidth
        variant="contained"
        style={{ margin: '6px 0 12px' }}
      >
        {t('quote.duplicate.request')}
      </Button>

      {open && (
        <Modal
          open={open}
          handleClose={closeModal}
          ariaLabelledby="duplicate the request"
          ariaDescribedby="duplicate of the quotation"
        >
          {show === 'confirm' ? (
            <Confirmation handleConfirm={handleConfirm} handleCloseModal={closeModal} />
          ) : (
            <Form handleCloseModal={closeModal} id={id} />
          )}
        </Modal>
      )}
    </>
  )
}

export default (withRedux(({ user }) => ({
  rights: user.data.rights,
}))(DuplicateRequestAction): any)
