// @flow

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { useTranslation } from 'react-i18next'
import SectionTitle from 'quote/cmp/PrintQuote/SectionTitle'
import ListItem from 'quote/cmp/PrintQuote/ListItem'

const styles = () => ({
  list: {
    paddingLeft: 0,
  },
})

type Props = {
  classes: Object,
  comments: Object[],
}

const Comments = ({ classes, comments }: Props): React$Element<any> => {
  const { t } = useTranslation()

  return (
    <>
      <SectionTitle title={t('quote.comments')} />
      <ul className={classes.list}>
        {comments.map(({ name, value }) => (
          <ListItem
            name={{ value: name, style: { width: '25%', fontWeight: 900 } }}
            content={{ value, style: { fontWeight: 600 } }}
            key={name}
            variant="secondary"
            typeValue="value"
          />
        ))}
      </ul>
    </>
  )
}

export default (withStyles(styles)(Comments): any)
