// @flow

import { IDENTIFICATION_VEHICLE_TYPE } from 'utils/constants'
import { MODES } from 'utils/constants/modes'

import type { Mode } from 'types/modes'

type GetQuoteInputParams = {
  mode: Mode,
  i: Object,
  currentDraftQuoteId?: string,
  fields: Object,
  selectedMake: Object,
  tags: (string | boolean)[],
  generalComment?: string,
  mechanicalComment?: string,
  crashedComment?: string | null,
  selectedVersion: Object,
  selectedSubFamily: Object,
  selectedModel: Object,
}

type GetQuoteInputStandardBikeModeParams = {
  mode: Mode,
  i: Object,
  currentDraftQuoteId?: string,
  fields: Object,
  tags: (string | boolean)[],
  generalComment?: string,
  mechanicalComment?: string,
  crashedComment?: string | null,
}

export const getQuoteInput = ({
  mode,
  i,
  currentDraftQuoteId,
  fields,
  selectedMake,
  tags,
  generalComment,
  mechanicalComment,
  crashedComment,
  selectedVersion,
  selectedSubFamily,
  selectedModel,
}: GetQuoteInputParams): Object => {
  const data: Object = {
    id: i?.id ?? currentDraftQuoteId,
    category: mode,
    plate: fields.plate,
    vin: fields.vin,
    make: selectedMake?.name,
    type: selectedMake?.type,
    family: selectedSubFamily?.parentName,
    subFamily: selectedSubFamily?.data.name,
    model: selectedModel?.name,
    startModelPhase: selectedModel?.saleStart
      ? { date: selectedModel?.saleStart, timezone: 'UTC' }
      : null,
    endModelPhase: selectedModel?.saleEnd
      ? { date: selectedModel?.saleEnd, timezone: 'UTC' }
      : null,
    catalogId: selectedVersion?.id,
    version: selectedVersion?.name,
    trimLevel: selectedVersion?.trimLevel,
    startVersionPhase: selectedVersion?.saleStart
      ? { date: selectedVersion?.saleStart, timezone: 'UTC' }
      : null,
    endVersionPhase: selectedVersion?.saleEnd
      ? { date: selectedVersion?.saleEnd, timezone: 'UTC' }
      : null,
    energy: selectedVersion?.energy,
    hpPower: selectedVersion?.hpPower,
    kwPower: selectedVersion?.kwPower,
    taxPower:
      (i?.identificationCountry !== 'es' || i?.identificationCountry !== 'be') &&
      selectedVersion?.taxPower,
    displacement: selectedVersion?.displacement,
    gearbox: selectedVersion?.gearbox,
    gears: selectedVersion?.gears,
    co2: (fields.co2 && +fields.co2) ?? (i?.regCo2 && +i?.regCo2) ?? null,
    color: i?.regColor ?? i?.color ?? fields.color,
    productionCountry: 'FR',
    regType: i?.regType ?? fields?.regType,
    body: fields.body ?? selectedVersion?.body,
    doors: selectedVersion?.doors,
    seats: fields.seats ?? selectedVersion?.seats,
    deliveryKm: fields.deliveryKm && parseInt(fields.deliveryKm, 10),
    releaseDate: fields.firstRegDate && {
      date: fields.firstRegDate,
      timezone: 'UTC',
    },
    deliveryDate: fields.deliveryDate && {
      date: fields.deliveryDate,
      timezone: 'UTC',
    },
    tags: tags.filter(Boolean),
    catalogSource: 'autovista',
    euroStandard: fields.euroStandard ?? selectedVersion?.euroStandard,
    firstSellPrice: selectedVersion?.price,
    lastRegDate: fields.lastRegDate && { date: fields.lastRegDate, timezone: 'UTC' },
    comments: {
      general: generalComment || null,
      mechanical: mechanicalComment || null,
      crashed: crashedComment || null,
    },
    costs: {
      mechanical: parseInt(fields.mechanicalCost, 10),
    },
    vehicle: fields.vehicle ?? undefined,
  }

  if (mode === MODES.resval) {
    return {
      ...data,
      reference: fields.reference,
      contractDuration: Number(fields.contractDuration),
      contractKmStart: Number(fields.kmToStart),
      contractKm: Number(fields.kmToGo),
      ...(fields.contractDateStart && {
        contractDateStart: {
          date: fields.contractDateStart,
          timezone: 'UTC',
        },
      }),
    }
  }

  return { ...data, currentKm: fields.currentKm && parseInt(fields.currentKm, 10) }
}

export const getQuoteInputStandardBikeMode = ({
  mode,
  i,
  currentDraftQuoteId,
  fields,
  tags,
  generalComment,
  mechanicalComment,
  crashedComment,
}: GetQuoteInputStandardBikeModeParams): Object => {
  const data: Object = {
    id: i?.id ?? currentDraftQuoteId,
    category: mode,
    plate: fields.plate,
    vin: fields.vin,
    make: fields.make,
    type: fields.type ?? IDENTIFICATION_VEHICLE_TYPE.MOTO,
    model: fields.model,
    catalogId: fields?.catalogId,
    version: fields.version || null,
    energy: fields.energy,
    hpPower: Number(fields.hpPower),
    kwPower: Number(fields.kwPower),
    taxPower: Number(fields.taxPower),
    displacement: Number(fields.displacement),
    gearbox: fields.gearbox,
    co2: fields.co2 !== null ? Number(fields.co2) : null,
    color: fields.color,
    productionCountry: 'FR',
    regType: fields.regType,
    body: fields.bodywork,
    seats: Number(fields.seats),
    deliveryKm: fields.deliveryKm && parseInt(fields.deliveryKm, 10),
    deliveryHour: fields.deliveryHour && parseInt(fields.deliveryHour, 10),
    releaseDate: fields.firstRegDate && {
      date: fields.firstRegDate,
      timezone: 'UTC',
    },
    deliveryDate: fields.deliveryDate && {
      date: fields.deliveryDate,
      timezone: 'UTC',
    },
    tags: tags.filter(Boolean),
    catalogSource: 'autovista',
    lastRegDate: fields.lastRegDate && { date: fields.lastRegDate, timezone: 'UTC' },
    comments: {
      general: generalComment || null,
      mechanical: mechanicalComment || null,
      crashed: crashedComment || null,
      bodywork: fields.bodyworkComment || null,
    },
    costs: {
      mechanical: parseInt(fields.mechanicalCost, 10),
      bodywork: parseInt(fields.bodyworkCost, 10),
    },
    currentKm: fields.currentKm && parseInt(fields.currentKm, 10),
  }

  return data
}
