const printSettingsPreset = {
  full: {
    valuation: {
      display: true,
      fields: {
        spot: true,
        spotRotation: true,
        bodyRepairCosts: true,
        mechanicalRestorationCosts: true,
        excessMileage: true,
        btocPrice: true,
        btocAdvised: true,
        quoterPrice: true,
        quoterPriceUnmarked: true,
        tradeinPrice: true,
      },
    },
    insert: {
      display: true,
      fields: {
        quoterPrice: true,
        bodyRepairCosts: true,
        mechanicalRestorationCosts: true,
      },
    },
    identify: {
      display: true,
      fields: {
        plate: true,
        vin: true,
        make: true,
        family: true,
        model: true,
        commercialDesignation: true,
        fuelEnergy: true,
        gearbox: true,
        horsePower: true,
        kwPower: true,
        taxPower: true,
        displacement: true,
        co2: true,
        body: true,
        doorsNumber: true,
        seatsNumber: true,
        color: true,
        registrationVehicleType: true,
        euroStandard: true,
        natCode: true,
      },
    },
    dates: {
      display: true,
      fields: {
        firstRegistrationDate: true,
        registrationCertificateDate: true,
        quotationDate: true,
        contractDuration: true,
        contractDateStart: true,
        deliveryDate: true,
      },
    },
    mileage: {
      display: true,
      fields: {
        currentMileage: true,
        contractKm: true,
        contractKmStart: true,
        deliveryMileage: true,
      },
    },
    pictures: {
      display: true,
      fields: {
        loadedPicturesNumber: true,
      },
    },
    tags: {
      display: true,
      fields: {},
    },
    comments: {
      display: true,
      fields: {
        machanicalComment: true,
        generalComment: true,
        crashedComment: true,
        sendInFleetComment: true,
        updateFleet: true,
      },
    },
    vehiculeOwner: {
      display: true,
      fields: {
        lastName: true,
        firstName: true,
      },
    },
    buyBackExpert: {
      display: true,
      fields: {
        name: true,
        businessName: true,
        quotationDate: true,
        quotationTime: true,
      },
    },
    damages: {
      display: true,
      fields: {},
    },
  },
  light: {
    valuation: {
      display: true,
      fields: {
        spot: false,
        spotRotation: false,
        bodyRepairCosts: true,
        mechanicalRestorationCosts: true,
        excessMileage: true,
        btocPrice: false,
        btocAdvised: false,
        quoterPrice: false,
        tradeinPrice: false,
      },
    },
    identify: {
      display: true,
      fields: {
        plate: true,
        vin: true,
        make: true,
        family: false,
        model: true,
        commercialDesignation: true,
        fuelEnergy: true,
        gearbox: true,
        horsePower: true,
        kwPower: true,
        taxPower: true,
        displacement: true,
        co2: true,
        body: true,
        doorsNumber: true,
        seatsNumber: true,
        color: true,
        registrationVehicleType: true,
        euroStandard: true,
        natCode: false,
      },
    },
    dates: {
      display: true,
      fields: {
        firstRegistrationDate: true,
        registrationCertificateDate: true,
        quotationDate: true,
        contractDuration: true,
        contractDateStart: true,
        deliveryDate: true,
      },
    },
    mileage: {
      display: true,
      fields: {
        currentMileage: true,
        contractKm: true,
        contractKmStart: true,
        deliveryMileage: true,
      },
    },
    pictures: {
      display: true,
      fields: {
        loadedPicturesNumber: true,
      },
    },
    tags: {
      display: true,
      fields: {},
    },
    comments: {
      display: true,
      fields: {
        machanicalComment: true,
        generalComment: true,
        crashedComment: true,
        sendInFleetComment: true,
        updateFleet: true,
      },
    },
    vehiculeOwner: {
      display: true,
      fields: {
        lastName: true,
        firstName: true,
      },
    },
    buyBackExpert: {
      display: false,
      fields: {
        name: true,
        businessName: true,
        quotationDate: true,
        quotationTime: true,
      },
    },
    damages: {
      display: true,
      fields: {},
    },
  },
}

const PLATE_EXAMPLES = {
  default: 'AB123XXW',
  es: '1111AAA',
  fr: 'AB123XX',
}

const VIN_EXAMPLES = {
  default: '1ABCD23456E789123',
}

const REFERENCE_EXAMPLES = {
  default: 'DOSSIER123',
  es: 'CARPETAS123',
  en: 'FOLDER123',
  ca: 'CAIXA123',
  fr: 'DOSSIER123',
  nl: 'DOSSIER123',
}

export { printSettingsPreset, PLATE_EXAMPLES, VIN_EXAMPLES, REFERENCE_EXAMPLES }
