// @flow

import React, { useRef, useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import Button from '@autodisol/ads-js/components/CustomButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Link from '@material-ui/core/Link'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import ExportIcon from '@material-ui/icons/Publish'
import FilterIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import CollapseHeader from '@autodisol/ads-js/components/CollapseHeader'
import Pagination, {
  defaultPage,
  defaultNbElementByPage,
} from '@autodisol/ads-js/components/Pagination'
import moment from 'moment'
import { connect as withRedux } from 'react-redux'
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'
import LoadingPage from 'sharyn/components/LoadingPage'
import Page from 'sharyn/components/Page'
import renderIf from 'sharyn/hocs/render-if'
import withFields from 'sharyn/hocs/with-fields'
import { setUi } from 'sharyn/redux/actions'
import cond from 'sharyn/util/cond'
import spread from 'sharyn/util/spread'
import { useTranslation } from 'react-i18next'
import DisplayParameter from 'quote/cmp/DisplayParameter'
import Export from 'quote/cmp/Export'
import QuoteCardList from 'quote/cmp/QuoteCardList'
import QuoteFilter from 'quote/cmp/QuoteFilter'
import { searchQuoteQuery } from 'quote/quote-queries'
import {
  quoteGroupStatusList,
  QUOTE_STATUS_WAIT_ASSIGN_VALID,
  QUOTE_STATUS_DRAFT,
  QUOTE_STATUS_CHANGES_REQUESTED,
  QUOTE_STATUS_ASSIGNED,
} from '_client/config'
import { getModesData } from 'utils/modes'
import { MODES } from 'utils/constants/modes'
import MonitoringTable from 'quote/cmp/MonitoringTable'
import { getFields } from 'quote/functions'

import type { Mode } from 'types/modes'

const SEARCH_DATA_BY_STATUS = {
  fleet: { status: quoteGroupStatusList.resval.group_fleet, orderBy: 'sentInFleetAt:desc' },
  wait_assign_valid: { status: [QUOTE_STATUS_WAIT_ASSIGN_VALID] },
  monitoring: { dateAfter: moment().startOf('day').toISOString(true) },
  drafts: { status: [QUOTE_STATUS_DRAFT] },
  corrections: { status: [QUOTE_STATUS_CHANGES_REQUESTED] },
  assigned: { status: [QUOTE_STATUS_ASSIGNED] },
}

const updateHasAnySearchBeenDone =
  ({ setExpanded }) =>
  () => {
    setExpanded(null)
  }

type SearchPageProps = {
  classes: Object,
  dispatch: Function,
  isTableLoading?: boolean,
  field: Function,
  fields: Object,
  setField: Function,
  setFields: Function,
  onChange: Function,
  history: Object,
  previousSearchFilters?: Object,
  displayParameter?: Object,
  user: Object,
  clearFields: Function,
  categories: string[],
  mode: Mode,
}

const SearchPageJSX = ({
  classes: css,
  dispatch,
  field,
  fields,
  setField,
  setFields,
  onChange,
  history,
  previousSearchFilters,
  displayParameter,
  user,
  clearFields,
  categories,
  mode,
}: SearchPageProps) => {
  const { t } = useTranslation()

  const { state } = history.location
  const hasInitalSearchData =
    Boolean(state?.initialSearchFilters) || Boolean(state?.initialSearchState)
  const hasPreviousSearchFilters = Boolean(previousSearchFilters)
  const page =
    hasInitalSearchData || !previousSearchFilters ? defaultPage : previousSearchFilters?.page
  const nbElementByPage =
    hasInitalSearchData || !previousSearchFilters
      ? defaultNbElementByPage
      : previousSearchFilters?.nbElementByPage
  const search = state?.initialSearchFilters?.search
  const initialSearchState = state?.initialSearchState
  const searchParams = initialSearchState ? SEARCH_DATA_BY_STATUS[initialSearchState] : null

  const [expanded, setExpanded] = useState(
    history.location.state || previousSearchFilters ? null : 'search',
  )

  const isNewSearch = useRef(false)
  const mustSkipSearchQueryRef = useRef(!hasInitalSearchData && !hasPreviousSearchFilters)
  const defaultVariablesSearchQuoteRef = useRef({
    filters: {
      orderBy: 'updatedAt:desc',
      ...(search && { query: search }),
      ...(searchParams && searchParams),
      categories,
      ...spread({
        ...getFields(field, mode),
      }),
    },
    page,
    nbElementByPage,
  })

  const [fetchSearchQuote, { loading: isTableLoading, data }] = useLazyQuery(searchQuoteQuery, {
    variables: defaultVariablesSearchQuoteRef.current,
    onCompleted: () => {
      if (field('search')) setField('search', undefined)
      dispatch(setUi({ previousSearchFilters: { fields, page, nbElementByPage } }))
    },
  })

  useEffect(() => {
    if (mustSkipSearchQueryRef.current) return

    fetchSearchQuote()
  }, [fetchSearchQuote])

  const refetchQuotes = filters => (nbPage, nbElem) => {
    if (filters && !isNewSearch.current) isNewSearch.current = true

    fetchSearchQuote({
      variables: {
        filters: filters || {
          orderBy: 'updatedAt:desc',
          ...(search && !isNewSearch.current && { query: search }),
          ...(searchParams && !isNewSearch.current && searchParams),
          categories,
          ...spread({
            ...getFields(field, mode),
          }),
        },
        page: nbPage,
        nbElementByPage: nbElem,
      },
    })
  }

  const submitHandler = e => {
    e.preventDefault()

    if (!isNewSearch.current) isNewSearch.current = true

    refetchQuotes()(defaultPage, defaultNbElementByPage)
    updateHasAnySearchBeenDone({ setExpanded })
  }

  const quotes = data ? data.search_quote : {}

  return (
    <Page noPadding noPaper maxWidth="100%">
      <Hidden smDown>
        <Grid Grid container spacing={16} style={{ marginBottom: 20 }}>
          <Grid item xs={3}>
            <TextField
              className={css.fields}
              name="status"
              select
              label={t('quote.status')}
              value={field('status')}
              {...{ onChange }}
              SelectProps={{
                renderValue: selected => {
                  let selectedName
                  Object.keys(quoteGroupStatusList[mode]).map(values => {
                    if (quoteGroupStatusList[mode][values] === selected) {
                      selectedName = t(`globals.allStatus.${values}`)
                    } else if (!selected || selected.length === 0) {
                      selectedName = t('search.allStatus')
                    }
                    return selectedName
                  })
                  return selectedName
                },
                displayEmpty: true,
                fullWidth: true,
              }}
            >
              <MenuItem value={null}>
                <ListItemText primary={t('search.allStatus')} />
              </MenuItem>
              {Object.keys(quoteGroupStatusList[mode]).map(status => (
                <MenuItem key={status} value={quoteGroupStatusList[mode][status]}>
                  <ListItemText primary={t(`globals.allStatus.${status}`)} />
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={3}>
            <TextField
              name="dateAfter"
              value={
                field('dateAfter')
                  ? moment(field('dateAfter')).format('YYYY-MM-DD')
                  : field('dateAfter')
              }
              label={t('search.minUpdateDate')}
              type="date"
              InputProps={{
                classes: { root: field('dateAfter') === '' && css.emptyDateField },
              }}
              {...{ onChange }}
              fullWidth
              className={css.fields}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              className={css.fields}
              name="dateBefore"
              value={field('dateBefore')}
              label={t('search.maxUpdateDate')}
              type="date"
              InputProps={{
                classes: { root: field('dateBefore') === '' && css.emptyDateField },
              }}
              {...{ onChange }}
              fullWidth
            />
          </Grid>
          <Grid container xs={3} direction="column" alignItems="flex-end">
            <div style={{ display: 'grid', textAlign: 'center' }}>
              <Button
                variant="contained"
                colorType="primary"
                height="short"
                onClick={submitHandler}
                isLoading={isTableLoading}
              >
                <SearchIcon style={{ marginRight: 10 }} />
                {t('globals.search')}
              </Button>
              {/* eslint-disable-next-line */}
              <Link
                onClick={() => {
                  if (
                    field('status') ||
                    field('dateBefore') ||
                    field('dateAfter') ||
                    field('plateLike') ||
                    field('parentEnquirerGroups')
                  ) {
                    clearFields(
                      field('status'),
                      field('dateBefore'),
                      field('dateAfter'),
                      field('plateLike'),
                      field('parentEnquirerGroups'),
                    )
                    setExpanded(expanded === 'search' && null)
                    refetchQuotes({ categories, orderBy: 'updatedAt:desc' })(
                      defaultPage,
                      defaultNbElementByPage,
                    )
                  }
                }}
                style={{ textDecoration: 'none' }}
                className={
                  field('status') ||
                  field('dateBefore') ||
                  field('dateAfter') ||
                  field('plateLike') ||
                  field('parentEnquirerGroups')
                    ? css.deleteField
                    : css.deleteFieldDisabled
                }
              >
                {t('search.deleteFilter')}
              </Link>
            </div>
          </Grid>
        </Grid>
        <Grid container style={{ marginBottom: 20 }}>
          <Grid item>
            <ExpansionPanelSummary
              expanded={expanded === 'search'}
              onClick={() => setExpanded(expanded === 'search' ? null : 'search')}
            >
              <ListItemIcon>
                <SearchIcon />
              </ListItemIcon>
              <ListItemText
                inset
                primary={t('search.advancedSearch')}
                className={expanded === 'search' ? css.collapseNameFocused : css.collapseName}
              />
              {expanded === 'search' ? <ExpandLess /> : <ExpandMore />}
            </ExpansionPanelSummary>
          </Grid>
          <Grid item>
            <ExpansionPanelSummary
              expanded={expanded === 'parameter'}
              onClick={() => setExpanded(expanded === 'parameter' ? null : 'parameter')}
            >
              <ListItemIcon>
                <FilterIcon />
              </ListItemIcon>
              <ListItemText
                inset
                primary={t('search.displayParameterTitle')}
                className={expanded === 'parameter' ? css.collapseNameFocused : css.collapseName}
              />
              {expanded === 'parameter' ? <ExpandLess /> : <ExpandMore />}
            </ExpansionPanelSummary>
          </Grid>
          <Grid item>
            <ExpansionPanelSummary
              expanded={expanded === 'export'}
              onClick={() => setExpanded(expanded === 'export' ? null : 'export')}
            >
              <ListItemIcon>
                <ExportIcon style={{ transform: 'rotate(180deg)' }} />
              </ListItemIcon>
              <ListItemText
                inset
                primary={t('export.export')}
                className={expanded === 'export' ? css.collapseNameFocused : css.collapseName}
              />
              {expanded === 'export' ? <ExpandLess /> : <ExpandMore />}
            </ExpansionPanelSummary>
          </Grid>
        </Grid>
        <Divider
          style={{
            width: '93%',
            margin: 'auto',
          }}
        />
        <Collapse in={!!expanded} timeout="auto" unmountOnExit className={css.collapseWrapper}>
          {expanded === 'search' && (
            <QuoteFilter
              field={field}
              onChange={onChange}
              submitHandler={submitHandler}
              mode={mode}
            />
          )}
          {expanded === 'parameter' && (
            <DisplayParameter {...{ field, fields, onChange, setFields, setField, history }} />
          )}
          {expanded === 'export' && (
            <>
              <Export
                {...{
                  nbResults: quotes?.nbResults,
                  history,
                  field,
                  search: search && !isNewSearch.current ? search : null,
                  searchParams: searchParams && !isNewSearch.current ? searchParams : null,
                }}
              />
            </>
          )}
        </Collapse>
      </Hidden>

      <Hidden mdUp>
        <Grid Grid container spacing={16} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <TextField
              className={css.fields}
              name="status"
              select
              label={t('quote.status')}
              value={field('status')}
              {...{ onChange }}
              SelectProps={{
                renderValue: selected => {
                  let selectedName
                  Object.keys(quoteGroupStatusList[mode]).map(values => {
                    if (quoteGroupStatusList[mode][values] === selected) {
                      selectedName = t(`globals.allStatus.${values}`)
                    } else if (!selected || selected.length === 0) {
                      selectedName = t('search.allStatus')
                    }
                    return selectedName
                  })
                  return selectedName
                },
                displayEmpty: true,
                fullWidth: true,
              }}
            >
              <MenuItem value={null}>
                <ListItemText primary={t('search.allStatus')} />
              </MenuItem>
              {Object.keys(quoteGroupStatusList[mode]).map(status => (
                <MenuItem key={status} value={quoteGroupStatusList[mode][status]}>
                  <ListItemText primary={t(`globals.allStatus.${status}`)} />
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="dateAfter"
              value={field('dateAfter')}
              label={t('search.minUpdateDate')}
              type="date"
              InputProps={{
                classes: { root: field('dateAfter') === '' && css.emptyDateField },
              }}
              {...{ onChange }}
              fullWidth
              className={css.fields}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={css.fields}
              name="dateBefore"
              value={field('dateBefore')}
              label={t('search.maxUpdateDate')}
              type="date"
              InputProps={{
                classes: { root: field('dateBefore') === '' && css.emptyDateField },
              }}
              {...{ onChange }}
              fullWidth
            />
          </Grid>
          <Grid item xs={8}>
            <Button
              onClick={() => {
                if (
                  field('status') ||
                  field('dateBefore') ||
                  field('dateAfter') ||
                  field('plateLike') ||
                  field('parentEnquirerGroups')
                ) {
                  clearFields(
                    field('status'),
                    field('dateBefore'),
                    field('dateAfter'),
                    field('plateLike'),
                    field('parentEnquirerGroups'),
                  )
                  setExpanded(expanded === 'search' && null)
                  refetchQuotes({ categories, orderBy: 'updatedAt:desc' })(
                    defaultPage,
                    defaultNbElementByPage,
                  )
                }
              }}
              variant="contained"
              colorType="secondary"
              height="short"
              style={{ width: '100%' }}
              disabled={
                !field('status') &&
                !field('dateBefore') &&
                !field('dateAfter') &&
                !field('plateLike') &&
                !field('parentEnquirerGroups')
              }
            >
              {t('search.deleteFilter')}
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              colorType="primary"
              height="short"
              style={{ width: '100%' }}
              onClick={submitHandler}
              isLoading={isTableLoading}
            >
              {t('globals.search')}
            </Button>
          </Grid>
        </Grid>
        <ExpansionPanel
          expanded={expanded === 'search'}
          onChange={() => setExpanded(expanded === 'search' ? null : 'search')}
        >
          <ExpansionPanelSummary>
            <CollapseHeader title={t('search.advancedSearch')} icon={<SearchIcon />} />
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <QuoteFilter
              field={field}
              onChange={onChange}
              submitHandler={submitHandler}
              mode={mode}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'parameter'}
          onChange={() => setExpanded(expanded === 'parameter' ? null : 'parameter')}
        >
          <ExpansionPanelSummary>
            <CollapseHeader title={t('search.displayParameterTitle')} icon={<FilterIcon />} />
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <DisplayParameter {...{ field, fields, onChange, setFields, setField, history }} />
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel
          expanded={expanded === 'export'}
          onChange={() => setExpanded(expanded === 'export' ? null : 'export')}
        >
          <ExpansionPanelSummary>
            <CollapseHeader
              title={t('export.export')}
              icon={<ExportIcon style={{ transform: 'rotate(180deg)' }} />}
            />
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Export
              {...{
                nbResults: quotes?.nbResults,
                history,
                field,
                search: search && !isNewSearch.current ? search : null,
                searchStatus: searchParams && !isNewSearch.current ? searchParams : null,
              }}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Hidden>
      <Hidden smDown>
        <div style={{ overflow: 'hidden', overflowX: 'scroll' }}>
          <MonitoringTable
            history={history}
            mode={mode}
            quotes={quotes}
            isTableLoading={isTableLoading}
            isNewSearch={isNewSearch.current}
          />
        </div>
      </Hidden>
      <Hidden mdUp>
        {!isTableLoading && (
          <QuoteCardList
            {...{ quotes, displayParameter, user, mode }}
            isNewSearch={isNewSearch.current}
            style={{ marginTop: 25 }}
          />
        )}
      </Hidden>
      {isTableLoading && (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <CircularProgress />
        </div>
      )}
      {cond([
        [
          quotes?.nbResults === 0 && !isTableLoading,
          <div style={{ textAlign: 'center', marginTop: 50 }}>
            <SearchIcon style={{ fontSize: 45, color: '#bbb' }} />
            <Typography variant="body1" style={{ margin: '10px 0 50px 0', textAlign: 'center' }}>
              {t('quote.noResults')}
            </Typography>
          </div>,
        ],
        [
          quotes?.nbResults > 0 && !isTableLoading,
          <Pagination
            updateResults={refetchQuotes()}
            count={quotes?.nbResults || 0}
            style={{ marginBottom: 50 }}
            {...{
              currentPage: quotes.currentPage,
              rowsPerPage: quotes.nbElementByPage,
            }}
          />,
        ],
      ])}
    </Page>
  )
}

export const SearchPageCmp: any = withStyles(({ palette }) => ({
  collapseWrapper: {
    borderBottom: `1px solid ${palette.secondary.light}`,
    paddingBottom: 20,
    paddingTop: 20,
    margin: 'auto',
    width: '93%',
  },
  collapseName: { borderBottom: `1px solid ${palette.secondary.light}`, padding: 0 },
  collapseNameFocused: { borderBottom: `2px solid ${palette.primary.main}`, padding: 0 },
  deleteField: {
    fontSize: '0.85rem',
    cursor: 'pointer',
    color: palette.secondary.dark,
    marginTop: 5,
  },
  deleteFieldDisabled: {
    extend: 'deleteField',
    color: palette.secondary.light,
  },
}))(SearchPageJSX)

const SearchPage: any = compose(
  withRouter,
  withRedux(({ pageData, asyncMap, ui, user, router }) => {
    const { currentMode } = getModesData(user, router.location.pathname)

    return {
      currentPage: pageData.search_quote?.currentPage,
      rowsPerPage: pageData.search_quote?.nbElementByPage,
      displayParameter: user.data?.displayParameter[currentMode],
      user: user.data,
      isPageLoading: asyncMap.page,
      previousSearchFilters: ui.previousSearchFilters,
      categories: currentMode ? [currentMode] : [MODES.standard],
      mode: currentMode,
    }
  }),
  withFields(props => {
    const { state } = props.history.location
    const initialSearchState = state?.initialSearchState
    const searchData = initialSearchState ? SEARCH_DATA_BY_STATUS[initialSearchState] : null
    const initialSearchFilters = state?.initialSearchFilters
    const previousSearchFilters = props?.previousSearchFilters

    if (initialSearchFilters) return initialSearchFilters

    if (searchData) return searchData

    if (previousSearchFilters) return props.previousSearchFilters.fields

    return {}
  }),
  renderIf(({ isPageLoading }) => isPageLoading, LoadingPage),
)(SearchPageCmp)

export default SearchPage
