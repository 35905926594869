// @flow

import createReducer from 'sharyn/redux/create-reducer'
import { loginCase, logoutCase } from 'sharyn/redux/user-cases'
import {
  MERGE_USER_DATA,
  UPDATE_USER_GROUP,
  UPDATE_USER_GROUP_LIST,
  SET_CUSTOM_THEME,
  UPDATE_DISPLAY_PARAMETER,
  UPDATE_DISPLAY_PRINT,
  UPDATE_EXPORT_SELECTION,
  ELEMENT_IDENTIFY,
  SET_CURRENT_DRAFT_QUOTE_ID,
  SET_MODE,
} from '_client/redux/actions'

const userReducer: any = createReducer(
  [
    loginCase,
    logoutCase,
    [
      MERGE_USER_DATA,
      newUserData => state => ({
        ...state,
        data: { ...state.data, ...newUserData },
        mode: {
          ...state.mode,
        },
      }),
    ],
    [
      SET_MODE,
      mode => state => ({
        ...state,
        mode: {
          ...state.mode,
          currentMode: mode,
        },
      }),
    ],
    [
      UPDATE_USER_GROUP,
      ({ newGroup, newRights, newConfig }) =>
        state => ({
          ...state,
          data: { ...state.data, currentGroup: newGroup, rights: newRights, config: newConfig },
        }),
    ],
    [
      UPDATE_USER_GROUP_LIST,
      groupList => state => ({
        ...state,
        data: { ...state.data, groups: groupList },
      }),
    ],
    [
      SET_CUSTOM_THEME,
      customTheme => state => ({
        ...state,
        data: { ...state.data, customTheme },
      }),
    ],
    [
      UPDATE_DISPLAY_PARAMETER,
      displayParameter => state => ({
        ...state,
        data: {
          ...state.data,
          displayParameter: {
            ...state.data.displayParameter,
            ...displayParameter,
          },
        },
      }),
    ],
    [
      UPDATE_DISPLAY_PRINT,
      printSettingFieldsPreset => state => ({
        ...state,
        data: { ...state.data, printSettingFieldsPreset },
      }),
    ],
    [
      UPDATE_EXPORT_SELECTION,
      exportSelection => state => ({
        ...state,
        data: {
          ...state.data,
          exportSelection: {
            ...state.data.exportSelection,
            ...exportSelection,
          },
        },
      }),
    ],
    [
      ELEMENT_IDENTIFY,
      elements => state => ({
        ...state,
        data: { ...state.data, elements },
      }),
    ],
    [
      SET_CURRENT_DRAFT_QUOTE_ID,
      currentDraftQuoteId => state => ({
        ...state,
        data: { ...state.data, currentDraftQuoteId },
      }),
    ],
  ],
  null,
)

export default userReducer
