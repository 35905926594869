// @flow

import React, { useState } from 'react'
import compose from 'recompose/compose'
import { connect as withRedux } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { ICONS_COMPONENTS } from 'app/cmp/ModesMenu/constants'
import { setMode } from '_client/redux/actions'
import { getModesData, getModeList } from 'utils/modes'
import { useTranslation } from 'react-i18next'
import { getFormattedData } from 'app/cmp/ModesMenu/functions'
import { MODES } from 'utils/constants/modes'

import type { AnchorPosition } from 'app/cmp/ModesMenu/types'
import type { ModeList } from 'utils/modes'

const styles = ({ breakpoints }) => ({
  button: {
    border: '1px solid #D8D8D8',
    borderRadius: 0,
    padding: 5,
    justifyContent: 'flex-start',
    minWidth: 'fit-content',
    '&:hover': {
      borderColor: 'transparent',
    },
    [breakpoints.up('md')]: {
      padding: 10,
    },
  },
  disabled: {
    borderColor: 'transparent',
    backgroundColor: '#F5F5F5',
    color: '#868F98 !important',
  },
  button__content: {
    fontSize: '0.8em',
    paddingLeft: 5,
    [breakpoints.up('md')]: {
      fontSize: '1em',
    },
  },
  root: {
    fontSize: '0.9em',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    [breakpoints.up('md')]: {
      fontSize: '1em',
    },
  },
  selected: {
    backgroundColor: '#F5F5F5 !important',
  },
})

type Props = {
  classes: Object,
  anchorPosition: AnchorPosition,
  isDisabledModes: boolean,
  mode: string,
  mustReloadThePage: boolean,
  modeList: ModeList,
  dispatch: Function,
}

const ModesMenu = ({
  classes,
  anchorPosition,
  isDisabledModes,
  mode,
  mustReloadThePage,
  modeList,
  dispatch,
}: Props) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)

  const [modes, setModes] = useState(
    modeList.map(element => ({
      code: element.mode,
      value: t(element.translationKey),
      isSelected: MODES[mode],
    })),
  )

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickButton = e => {
    setModes(getFormattedData(modes, mode))
    setAnchorEl(e.currentTarget)
  }

  const handleClickElement = currentMode => () => {
    setAnchorEl(null)

    if (mode === currentMode) return

    dispatch(setMode(currentMode))

    if (mustReloadThePage) window.location.reload()
  }

  const IconMode = ICONS_COMPONENTS[mode]

  return (
    <>
      <Button
        classes={{
          root: classes.button,
          disabled: classes.disabled,
        }}
        aria-owns={anchorEl ? 'mode-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClickButton}
        disabled={isDisabledModes}
      >
        {IconMode} <span className={classes.button__content}>{t(`globals.mode.menu.${mode}`)}</span>
      </Button>
      <Menu
        PaperProps={{
          style: {
            transform: `translateX(${anchorPosition.translateX}px) translateY(${anchorPosition.translateY}px)`,
          },
        }}
        disableAutoFocusItem
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {modes.map(({ code, value, isSelected }) => (
          <MenuItem
            key={code}
            classes={{ root: classes.root, isSelected: classes.isSelected }}
            selected={isSelected}
            onClick={handleClickElement(code)}
          >
            {value}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default (compose(
  withRedux(({ user, router }) => {
    const { isDisabledModes, currentMode } = getModesData(user, router.location.pathname)
    const modeList = getModeList(user)

    return {
      isDisabledModes,
      mode: currentMode,
      mustReloadThePage: router.location.pathname === '/search',
      modeList,
    }
  }),
  withStyles(styles),
)(ModesMenu): any)
