// @flow
import React from 'react'
import Grid from '@material-ui/core/Grid'
import Plate from '@autodisol/ads-js/components/Plate'
import withStyles from '@material-ui/core/styles/withStyles'
import ApplicantInformations from '@autodisol/ads-js/components/Quote/ApplicantInformations'
import Brand from '@autodisol/ads-js/components/Quote/Brand'
import VehicleInformation from '@autodisol/ads-js/components/VehicleInformationRedesign'
import Pictures from '@autodisol/ads-js/components/Pictures'
import Damages from '@autodisol/ads-js/components/Damages/Damages'
import BtnInfo from '@autodisol/ads-js/components/Quote/BtnInfo'
import QuotePanel from '@autodisol/ads-js/components/Quote/Panel'
import DamagesInfos from '@autodisol/ads-js/components/Quote/Damage/Infos'
import VehicleConditon from '@autodisol/ads-js/components/Quote/Vehicle/Condition'
import QuoteActionsPanelStandard from 'quote/cmp/QuoteActionsPanel/Standard'
import QuoteActionsPanelResval from 'quote/cmp/QuoteActionsPanel/Resval'
import Button from '@autodisol/ads-js/components/CustomButton'
import { secondaryColorExtraLight } from '@autodisol/ads-js/components/styles'
import moment from 'moment'
import Hidden from '@material-ui/core/Hidden'
import Badge from '@autodisol/ads-js/components/Badge'
import classnames from 'classnames'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { getPrices } from '_client/util'
import {
  QUOTE_STATUS_ASSIGNED,
  QUOTE_STATUS_SENT,
  QUOTE_STATUS_WAIT_ASSIGN_VALID,
  QUOTE_STATUS_REJECTED_ASSIGNMENT,
  QUOTE_STATUS_SOLD,
  QUOTE_STATUS_IN_STOCK,
  QUOTE_STATUS_UPDATED_FLEET,
} from '_client/config'
import { MODES } from 'utils/constants/modes'
import { getApplicantInformationsData } from 'quote/cmp/VehicleSheet/Quotation/functions'

import type { Mode } from 'types/modes'

const preloadedState = window.__PRELOADED_STATE__
const { HIDE_DAMAGES_BLOCK } = preloadedState.env

const imgPlaceholders = Array(4)
  .fill({ src: null })
  .map((element, index) => ({ ...element, id: index }))

const copyId = () => {
  const copyText = document.querySelector('#idValue')
  const range = document.createRange()
  const selection = window.getSelection()
  if (copyText) {
    range.selectNode(copyText)
    selection.removeAllRanges()
    selection.addRange(range)
    document.execCommand('copy')
  }
}

const styles = ({ breakpoints }) => {
  const baseStyles = {
    wrapperQuote: {
      [breakpoints.down('sm')]: {
        padding: '40px 20px',
      },
      [breakpoints.up('md')]: {
        padding: '60px 20px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
      },
    },
    wrapperHeadVehicle: {
      '& > div:nth-child(2)': {
        marginBottom: 16,
      },
    },
    wrapperPlateAndApplicantInfos: {
      margin: '0 0 20px 0',
      display: 'block',
      [breakpoints.up('md')]: {
        display: 'flex',
        alignItems: 'center',
      },
      '& > div': {
        [breakpoints.down('md')]: {
          marginBottom: 20,
        },
        [breakpoints.up('md')]: {
          marginLeft: '1rem',
        },
      },
    },
    bottomBanner: {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'space-between',
      background: secondaryColorExtraLight,
      fontStyle: 'italic',
      padding: '1rem 34px',
      position: 'relative',
      left: 0,
      width: '100%',
      [breakpoints.up('md')]: {
        top: '100%',
        flexFlow: 'row wrap',
      },
    },
    copyIdButton: {
      padding: 0,
      alignSelf: 'flex-end',
      marginLeft: 30,
    },
    idContainer: {
      display: 'none',
      flexFlow: 'row',
      [breakpoints.down('sm')]: {
        flexFlow: 'column',
      },
    },
    wrapperVehicleInfo: {
      marginTop: 20,
      [breakpoints.up('md')]: { paddingLeft: 32 },
    },
    vhInfos: {
      [breakpoints.down('sm')]: {
        marginTop: '1rem !important',
      },
    },
    imageContainer: {
      [breakpoints.down('sm')]: {
        maxWidth: 'calc(100% - 16px)',
      },
    },
    wrapperBadge: {
      [breakpoints.up('md')]: { paddingLeft: 32 },
    },
    quoteFirstBlock: {
      [breakpoints.up('md')]: {
        gridColumn: '1 / 2',
        gridRow: '1 / 3',
      },
    },
    quoteSecondBlock: {
      marginTop: 0,
      [breakpoints.up('md')]: {
        gridColumn: '2 / 3',
        gridRow: '1 / 2',
      },
    },
    quoteThirdBlock: {
      marginTop: 20,
      [breakpoints.up('md')]: {
        gridColumn: '1 / 2',
      },
    },
    quoteFourthBlock: {
      marginTop: 20,
      [breakpoints.up('md')]: {
        marginLeft: '10px',
        gridColumn: '2 / 3',
        gridRow: '2 / 5',
      },
    },
    quoteFiveBlock: {
      marginTop: 50,
      [breakpoints.up('md')]: {
        marginTop: 0,
        gridColumn: '1 / 3',
      },
    },
  }

  for (let i = 1; i < 6; i += 1) {
    baseStyles[`order${i}`] = {
      [breakpoints.down('sm')]: {
        order: i,
        marginTop: '2rem',
      },
    }
  }

  return baseStyles
}

type Props = {
  classes: Object,
  plate: Object,
  applicantInformations: Object,
  brand: Object,
  vehicleInformation: Object,
  vehicleConditon: Object,
  damagesInfos: Object,
  quoteActionsPanel: Object,
  btnInfo: Object,
  tags: string[],
  pictures?: Object[],
  damages?: Object,
  status?: string,
  prices?: Object[],
  user?: Object,
  quoterPrice?: Object,
  damagesPictures?: Object[],
  spotEnquirerRotation?: number,
  spotRotation?: number,
  id?: string,
  createdAt?: {
    date: string,
  },
  updatedAt?: Object,
  isExpired?: boolean,
  isArchived?: boolean,
  source?: string,
  mode: Mode,
}

const Quotation = ({
  classes,
  plate,
  applicantInformations,
  brand,
  tags,
  vehicleInformation,
  vehicleConditon,
  damagesInfos,
  btnInfo,
  quoteActionsPanel,
  pictures,
  damages,
  status,
  damagesPictures,
  prices,
  user,
  spotEnquirerRotation,
  spotRotation,
  quoterPrice,
  createdAt,
  updatedAt,
  id,
  isExpired,
  isArchived,
  source,
  mode,
}: Props) => {
  const { t } = useTranslation()

  const isSpotEnquirer = prices && prices.find(spot => spot.type === 'spot_enquirer')
  const {
    spot,
    spot_enquirer: spotEnquirer,
    pending_spot: pendingSpot,
    pending_spot_enquirer: pendingSpotEnquirer,
    b2c_group: b2cGroup,
  } = getPrices(
    ['spot', 'spot_enquirer', 'pending_spot', 'pending_spot_enquirer', 'b2c_group'],
    prices && prices,
    user?.config?.pricing?.display,
    user?.rights,
  )
  const isDisplayPendingPrice = mode === MODES.resval && status === QUOTE_STATUS_UPDATED_FLEET
  const spotPrice = isDisplayPendingPrice ? pendingSpot : spot
  const spotEnquirerPrice = isDisplayPendingPrice ? pendingSpotEnquirer : spotEnquirer
  const isDisplayDamagesBlock = !HIDE_DAMAGES_BLOCK || (HIDE_DAMAGES_BLOCK && source !== 'b2l-wafy')
  const { enquirer, enquirerGroup, contact, reference } = applicantInformations
  const tagsFiltered = tags.filter(tag => !tag.startsWith('quote_'))
  const hasCommercialPictures = pictures && pictures.length > 0
  const hasDamagePictures = damagesPictures && damagesPictures.length > 0

  return (
    <div className={classes.wrapperQuote}>
      <Grid className={classes.quoteFirstBlock}>
        <div className={classes.wrapperHeadVehicle}>
          <div className={classes.wrapperPlateAndApplicantInfos}>
            {plate?.plate && <Plate {...plate} />}
            <ApplicantInformations
              data={getApplicantInformationsData({
                enquirerFullname: enquirer.fullname,
                enquirerGroup,
                contactFullname: contact?.fullname,
                reference,
                mode,
              })}
            />
          </div>

          <Brand {...brand} />
        </div>
      </Grid>

      <Hidden smDown>
        <Grid className={classes.quoteSecondBlock}>
          <div className={classes.wrapperBadge}>
            {tagsFiltered.length > 0 &&
              tagsFiltered.map(tag => <Badge neutral key={tag} {...{ tag }} />)}
          </div>
        </Grid>
      </Hidden>

      <Grid className={`${classnames(classes.order2, classes.vhInfos)} ${classes.quoteThirdBlock}`}>
        <div style={{ marginBottom: 20 }}>
          <VehicleInformation {...vehicleInformation} mode={mode} />
        </div>

        <Hidden mdUp>
          <Grid item xs={12} lg={6} style={{ marginBottom: 20 }}>
            <div className={classes.wrapperBadge}>
              {tagsFiltered.length > 0 &&
                tagsFiltered.map(tag => <Badge neutral key={tag} {...{ tag }} />)}
            </div>
          </Grid>
        </Hidden>

        <Pictures
          moreLabel="de plus"
          justify="flex-start"
          title={hasCommercialPictures ? 'commercial_pictures' : ''}
          maxImagesInARow={4}
          pictures={hasCommercialPictures ? pictures : imgPlaceholders}
        />
        <div
          style={{ margin: '2rem 0' }}
          className={classnames(classes.order4, classes.imageContainer)}
        >
          {mode !== MODES.standard_bike && (
            <Damages inline unicolor {...{ damages }} schema={damages?.schema} />
          )}
        </div>
        <Pictures
          moreLabel="de plus"
          justify="flex-start"
          title={hasDamagePictures ? 'damage_pictures' : ''}
          maxImagesInARow={4}
          pictures={hasDamagePictures ? damagesPictures : imgPlaceholders}
        />
      </Grid>
      <Grid item xs={12} className={classes.quoteFourthBlock}>
        <Grid
          item
          container
          xs={12}
          spacing={8}
          alignContent="flex-start"
          justify="center"
          style={{ marginTop: 0 }}
          className={classes.order5}
        >
          <Grid
            item
            container
            xs={12}
            lg={6}
            spacing={8}
            alignContent="flex-start"
            justify="center"
          >
            <Hidden mdUp>
              <Grid item xs={12} className={classes.order2}>
                <BtnInfo {...btnInfo} />
              </Grid>
            </Hidden>

            {mode !== MODES.resval && (
              <Grid item xs={12} className={classes.order3}>
                <QuotePanel
                  appName="provider"
                  mode={mode}
                  spot={{
                    tooltipTitle: spotEnquirerPrice ?? spotPrice,
                    disableHoverListener: false,
                    disableTouchListener: false,
                    value:
                      (spotEnquirerPrice && spotEnquirerPrice.amount) ||
                      (spotPrice && spotPrice.amount),
                  }}
                  spotEnquirerPrice={spotEnquirerPrice}
                  spotRotation={isSpotEnquirer ? spotEnquirerRotation : spotRotation}
                  b2cGroup={b2cGroup}
                  quoterPrice={quoterPrice}
                  status={status}
                  isExpired={isExpired}
                  isArchived={isArchived}
                  user={user}
                  statusCondition={[
                    QUOTE_STATUS_ASSIGNED,
                    QUOTE_STATUS_SENT,
                    QUOTE_STATUS_WAIT_ASSIGN_VALID,
                    QUOTE_STATUS_REJECTED_ASSIGNMENT,
                    QUOTE_STATUS_SOLD,
                    QUOTE_STATUS_IN_STOCK,
                  ]}
                  style={{ position: 'relative' }}
                />
              </Grid>
            )}
            {mode !== MODES.resval && isDisplayDamagesBlock && (
              <>
                <Grid item xs={12} className={classes.order3}>
                  <DamagesInfos {...damagesInfos} />
                </Grid>
              </>
            )}
            <Grid item xs={12} className={classes.order1}>
              <VehicleConditon {...vehicleConditon} mode={mode} />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            lg={6}
            spacing={8}
            alignContent="flex-start"
            className={classes.order1}
          >
            <Hidden smDown>
              <Grid item md={12}>
                <BtnInfo {...btnInfo} />
              </Grid>
            </Hidden>

            <Grid item xs={12}>
              {mode === MODES.resval ? (
                <QuoteActionsPanelResval {...quoteActionsPanel} />
              ) : (
                <QuoteActionsPanelStandard {...quoteActionsPanel} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.quoteFiveBlock}>
        <div className={classnames(classes.bottomBanner)}>
          <Typography variant="body2" component="span">
            {t('quote.creationDateColon')}
            &nbsp;{createdAt && moment(createdAt?.date).format('DD/MM/YYYY')}
          </Typography>
          <Typography variant="body2" component="span">
            {t('quote.lastUpdateDateColon')}
            &nbsp;{updatedAt && moment(updatedAt?.date).format('DD/MM/YYYY')}
          </Typography>
          <div className={classes.idContainer}>
            <Typography variant="body2" component="span">
              {t('quote.quoteIdColon')} <span id="idValue">{id}</span>
            </Typography>
            <Button className={classes.copyIdButton} onClick={copyId}>
              {t('quote.copyId')}
            </Button>
          </div>
        </div>
      </Grid>
    </div>
  )
}

export default (withStyles(styles)(Quotation): any)
