// @flow

import React from 'react'
import { printSettingsPreset } from 'quote/quote-constant'
import { useTranslation } from 'react-i18next'
import { MODES } from 'utils/constants/modes'
import { QUOTE_STATUS_UPDATED_FLEET } from '_client/config'
import { getFielsToDisplay, numberFormat } from 'quote/cmp/PrintQuote/functions'
import SectionTitle from 'quote/cmp/PrintQuote/SectionTitle'
import ListItem from 'quote/cmp/PrintQuote/ListItem'

import type { Mode } from 'types/modes'
import type { PrintField } from 'types/print'

type Props = {
  printField: PrintField,
  quote?: Object,
  mode: Mode,
}

const Mileage = ({ printField, quote, mode }: Props): React$Element<any> => {
  const { t } = useTranslation()

  const isDisplayPendingData = mode === MODES.resval && quote?.status === QUOTE_STATUS_UPDATED_FLEET
  const contractKmStart = isDisplayPendingData
    ? quote?.pendingUpdate?.contractKmStart
    : quote?.contractKmStart
  const contractKm = isDisplayPendingData ? quote?.pendingUpdate.contractKm : quote?.contractKm
  const contractKmStartFormatted = numberFormat(contractKmStart)
  const deliveryMileageResval =
    typeof contractKmStart === 'number' && typeof contractKm === 'number'
      ? contractKmStart + contractKm
      : undefined
  const deliveryMileageFormatted = numberFormat(
    mode === MODES.resval ? deliveryMileageResval : quote?.deliveryKm,
  )
  const currentKmFormatted = numberFormat(quote?.currentKm)
  const currentPrintSettingsPreset = printSettingsPreset[printField]
  const fields = currentPrintSettingsPreset?.mileage?.fields
  const currentMileage = {
    currentMileage: {
      name: t('quote.print.currentKm'),
      value: typeof currentKmFormatted === 'string' ? `${currentKmFormatted} km` : null,
    },
  }
  const deliveryMileage = {
    deliveryMileage: {
      name: t('quote.print.delivery'),
      value: typeof deliveryMileageFormatted === 'string' ? `${deliveryMileageFormatted} km` : null,
    },
  }
  const data = {
    resval: {
      contractKmStart: {
        name: t('quote.print.currentKm'),
        value:
          typeof contractKmStartFormatted === 'string' ? `${contractKmStartFormatted} km` : null,
      },
      ...deliveryMileage,
    },
    standard: {
      ...currentMileage,
      ...deliveryMileage,
    },
    standard_bike: {
      ...currentMileage,
      ...deliveryMileage,
    },
  }
  const fieldsToDisplay = getFielsToDisplay(fields, data[mode])

  return (
    <>
      <SectionTitle title={t('quote.print.mileage')} />
      {fieldsToDisplay.map(({ name, value }) => (
        <ListItem
          name={{ value: name }}
          content={{ value }}
          key={name}
          variant="secondary"
          typeValue="value"
        />
      ))}
    </>
  )
}

export default Mileage
