// @flow
import { gql } from '@apollo/client'

export const updatePasswordMutation =
  'mutation ($newPassword: String!) { update_password(password: $newPassword) }'

// export const getUserAndGroupsQuery = 'query { get_my_profile, get_my_groups }'

export const getMyProfileAndMyGroupsQuery: any = gql`
  query {
    get_my_profile {
      id
      username
      firstname
      lastname
      fullname
      appSettings {
        provider
      }
      currentGroup {
        id
        name
        rootName
      }
      config
      rights
    }
    get_my_groups {
      id
      name
    }
  }
`

export const getGroupsQuery: any = gql`
  query {
    get_my_groups {
      id
      name
    }
  }
`
