// @flow

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = () => ({
  title: {
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    fontWeight: 500,
    textDecoration: 'underline',
    textUnderlineOffset: '8pt',
    margin: '15pt 0',
  },
})

type Props = {
  classes: Object,
  title: string,
}

const SectionTitle = ({ classes, title }: Props) => <h3 className={classes.title}>{title}</h3>

export default (withStyles(styles)(SectionTitle): any)
