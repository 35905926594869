// @flow

import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import ReactMarkdown from 'react-markdown'
import breaks from 'remark-breaks'
import gfm from 'remark-gfm'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Card from '@autodisol/ads-js/components/Card'
import { connect as withRedux } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'
import Page from 'sharyn/components/Page'
import { useTranslation } from 'react-i18next'
import ModeSelector from 'home/cmp/ModeSelector'
import Mode from 'home/cmp/Mode'
import ModesMenuMobile from 'app/cmp/ModesMenu/ModesMenuMobile'
import { getModesData } from 'utils/modes'

const styles = ({ breakpoints }) => ({
  homeBlocksContainer: {
    padding: '5vh 0 30px 0',
    width: '100%',
    maxWidth: 1080,
  },
  infocomWrapper: { fontSize: '0.875rem', marginTop: 20 },
  infoComTitle: { color: '#868F98', fontWeight: 'bold', fontSize: 'inherit' },
  [breakpoints.down('xs')]: {
    homeBlocksContainer: { paddingTop: 20 },
    addPaddingTop: { paddingTop: 60 },
  },
  title: {
    color: '#D2D6D9',
    fontSize: '1.5em',
    fontWeight: '900',
    margin: 0,
    paddingBottom: 17.5,
    borderBottom: '3px solid #D2D6D9',
    marginBottom: 41,
    display: 'none',
    [breakpoints.up('sm')]: {
      display: 'block',
      fontSize: '2.5rem',
    },
  },
  wrapper_loader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
})

type HomePageProps = {
  classes: Object,
  history: Object,
  firstname: string,
  currentMode: string | null,
  infocom: string | null,
  isPageLoading: boolean,
  hasAModeSelected: boolean,
  isDisplayModeSelector: boolean,
  isDisplayModesMenu: boolean,
}

const HomePage = ({
  classes,
  history,
  firstname,
  currentMode,
  infocom,
  isPageLoading,
  hasAModeSelected,
  isDisplayModeSelector,
  isDisplayModesMenu,
}: HomePageProps) => {
  const { t } = useTranslation()

  if (isPageLoading)
    return (
      <div className={classes.wrapper_loader}>
        <CircularProgress color="secondary" />
      </div>
    )

  return (
    <Page noPaper maxWidth={isDisplayModeSelector ? 700 : 800}>
      <div
        className={`${classes.homeBlocksContainer} ${
          hasAModeSelected ? classes.addPaddingTop : ''
        }`}
      >
        {isDisplayModesMenu && <ModesMenuMobile />}

        <p className={classes.title}>{t('home.hello', { firstname })}</p>

        {isDisplayModeSelector && <ModeSelector />}

        {Boolean(currentMode) && <Mode history={history} query={{ categories: [currentMode] }} />}

        {infocom && (
          <Card fullWidth className={classes.infocomWrapper}>
            <Typography className={classes.infoComTitle}>{t('home.information')}</Typography>
            <ReactMarkdown plugins={[breaks, gfm]}>{infocom}</ReactMarkdown>
          </Card>
        )}
      </div>
    </Page>
  )
}

export default (compose(
  withRedux(({ user, router }) => {
    const { currentMode, hasAModeSelected, isDisplayModeSelector, isDisplayModesMenu } =
      getModesData(user, router.location.pathname)

    return {
      firstname: user.data.firstname,
      currentMode,
      infocom: user.data.config.user?.content?.infocom?.text,
      isPageLoading: !user.data.customTheme,
      hasAModeSelected,
      isDisplayModeSelector,
      isDisplayModesMenu,
    }
  }),
  withRouter,
  withStyles(styles),
)(HomePage): any)
