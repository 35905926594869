// @flow

import { createAction } from 'redux-actions'

export const MERGE_USER_DATA = 'MERGE_USER_DATA'
export const mergeUserData: any = createAction(MERGE_USER_DATA)

export const UPDATE_USER_GROUP = 'UPDATE_USER_GROUP'
export const updateUserGroup: any = createAction(UPDATE_USER_GROUP)

export const UPDATE_USER_GROUP_LIST = 'UPDATE_USER_GROUP_LIST'
export const updateUserGroupList: any = createAction(UPDATE_USER_GROUP_LIST)

export const SET_CUSTOM_THEME = 'SET_CUSTOM_THEME'
export const setCustomTheme: any = createAction(SET_CUSTOM_THEME)

export const UPDATE_DISPLAY_PARAMETER = 'UPDATE_DISPLAY_PARAMETER'
export const updateDisplayParameter: any = createAction(UPDATE_DISPLAY_PARAMETER)

export const UPDATE_DISPLAY_PRINT = 'UPDATE_DISPLAY_PRINT'
export const updateDisplayPrint: any = createAction(UPDATE_DISPLAY_PRINT)

export const UPDATE_EXPORT_SELECTION = 'UPDATE_EXPORT_SELECTION '
export const updateExportSelection: any = createAction(UPDATE_EXPORT_SELECTION)

export const ELEMENT_IDENTIFY = 'ELEMENT_IDENTIFY'
export const elementIdentify: any = createAction(ELEMENT_IDENTIFY)

export const SET_CURRENT_DRAFT_QUOTE_ID = 'SET_CURRENT_DRAFT_QUOTE_ID'
export const setCurrentDraftQuoteId: any = createAction(SET_CURRENT_DRAFT_QUOTE_ID)

export const SET_MODE = 'SET_MODE'
export const setMode: any = createAction(SET_MODE)
