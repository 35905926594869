// @flow

import { gql } from '@apollo/client'

export const findFacetsQuery: any = gql`
  query ($filters: QuoteSearchFilterInput) {
    search_quote(filters: $filters) {
      facets {
        name
        values {
          value
          count
        }
      }
    }
  }
`
