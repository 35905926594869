// @flow

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { useTranslation } from 'react-i18next'
import SectionTitle from 'quote/cmp/PrintQuote/SectionTitle'

const styles = () => ({
  fullname: {
    fontWeight: 900,
    '& > span:last-child': {
      textTransform: 'uppercase',
    },
  },
})

type Props = {
  classes: Object,
  lastname: string,
  firstname: string,
}

const VehicleOwner = ({ classes, lastname, firstname }: Props): React$Element<any> => {
  const { t } = useTranslation()

  return (
    <>
      <SectionTitle title={t('quote.vehicleOwner')} />
      <p className={classes.fullname}>
        <span>{firstname}</span> <span>{lastname}</span>
      </p>
    </>
  )
}

export default (withStyles(styles)(VehicleOwner): any)
