// @flow

/* eslint-disable no-script-url */

import React from 'react'

import Button from '@autodisol/ads-js/components/CustomButton'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import BackIcon from '@material-ui/icons/ArrowBack'
import QuestionIcon from '@material-ui/icons/HelpOutline'
import HomeIcon from '@material-ui/icons/Home'
import Link from 'react-router-dom/Link'
import Page from 'sharyn/components/Page'
import { connect as withRedux } from 'react-redux'

type NotFoundPageProps = {
  classes: Object,
  appHost?: string,
}

const NotFoundPageJSX = ({ classes: css, appHost }: NotFoundPageProps) => (
  <Page middle noPaper>
    <div style={{ textAlign: 'center' }}>
      <QuestionIcon style={{ fontSize: 120, color: '#ccc', marginBottom: 20 }} />
      <Typography variant="h6" gutterBottom>
        Il n&apos;y a rien ici !
      </Typography>
      <div className={css.actions}>
        <Button
          colorType={window.location.hostname !== appHost ? 'secondary' : 'primary'}
          variant="contained"
          href="javascript:history.back()"
          className={css.backButton}
        >
          <BackIcon className={css.buttonIcon} />
          Retour
        </Button>
        <Button
          colorType={window.location.hostname !== appHost ? 'secondary' : 'primary'}
          variant="contained"
          component={Link}
          to="/"
        >
          <HomeIcon className={css.buttonIcon} />
          Accueil
        </Button>
      </div>
    </div>
  </Page>
)

const NotFoundPage = withStyles({
  sign: { fontSize: 120, color: '#ccc', marginBottom: 20 },
  backButton: { marginRight: 20 },
  buttonIcon: { width: 20, marginRight: 6, position: 'relative', top: -1 },
  actions: { marginTop: 30 },
})(NotFoundPageJSX)

export default (withRedux(({ env }) => ({
  appHost: env.APP_ADS_HOST,
}))(NotFoundPage): any)
