// @flow

import React from 'react'
import type { Element } from 'react'

import QuoteCard from 'quote/cmp/QuoteCard'
import type { Mode } from 'types/modes'

const QuoteCardList = ({
  quotes,
  style,
  displayParameter,
  user,
  mode,
  isNewSearch,
}: {
  quotes: Object,
  style?: Object,
  displayParameter?: Object,
  user?: Object,
  mode: Mode,
  isNewSearch?: boolean,
}): Element<'div'> => (
  <div {...{ style }}>
    {quotes.results &&
      quotes.results.map(quote => (
        <QuoteCard key={quote.id} {...{ quote, displayParameter, user, mode, isNewSearch }} />
      ))}
  </div>
)

export default QuoteCardList
