// @flow

import React from 'react'
import compose from 'recompose/compose'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Modal from 'Modal'
import Button from '@autodisol/ads-js/components/CustomButton'
import TextField from '@autodisol/ads-js/components/Inputs/ReactHookForm/TextField'
import AlertMessageZone from '@autodisol/ads-js/components/AlertMessage/AlertMessageZone'
import { getErrorsFormatted } from '@autodisol/ads-js/utils/reactHookForm/errors'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { getLanguage } from 'utils/language'
import { useDuplicateRequest } from 'quote/cmp/QuoteActionsPanel/Resval/DuplicateRequestAction/useDuplicateRequest'
import i18next from 'services/translations/config'
import {
  MIN_KM,
  MAX_KM,
  MIN_CONTRACT_DURATION,
  MAX_CONTRACT_DURATION,
} from 'quote/cmp/validationRules'
import { validateStringNumber } from 'utils/reactHookForm'

const styles = ({ breakpoints }) => ({
  description: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '.875em',
    marginBottom: 50,
    [breakpoints.up('sm')]: {
      margin: '0 50px 28px',
    },
  },
  form: {
    padding: '0 20px',
    '& > div:not(div:last-child)': {
      marginBottom: 30,
    },
    '& > div > p': {
      color: '#A8B4BCad',
    },
    [breakpoints.up('sm')]: {
      padding: 0,
      '& > div': {
        margin: '0 50px 28px',
        width: 'calc(100% - 100px)',
      },
      '& > div:last-child': {
        marginBottom: 0,
      },
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    '& button': {
      margin: '0 20px 10px',
    },
    [breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      '& button': {
        padding: '10px 40px',
        margin: 0,
      },
      '& button:first-child': {
        marginRight: 10,
      },
      '& button:last-child': {
        marginLeft: 10,
      },
    },
  },
  errors: {
    margin: '0 0 20px',
    [breakpoints.up('sm')]: {
      margin: '0 50px 20px',
    },
  },
})

const MIN_DELIVERY_DATE = moment().format('YYYY-MM-DD')
const language = getLanguage()

const validationSchema = z.object({
  kmToStart: validateStringNumber({
    invalidValue: i18next.t('quote.duplicate.form.error.kmToStart.invalidValue'),
    message: i18next.t('quote.duplicate.form.error.kmToStart.message'),
    min: MIN_KM,
    max: MAX_KM,
  }),
  kmToGo: validateStringNumber({
    invalidValue: i18next.t('quote.duplicate.form.error.kmToGo.invalidValue'),
    message: i18next.t('quote.duplicate.form.error.kmToGo.message'),
    min: MIN_KM,
    max: MAX_KM,
  }),
  contractDateStart: z.coerce
    .date({
      errorMap: (issue, ctx) => {
        if (issue.code === 'invalid_date')
          return {
            message: i18next.t('quote.duplicate.form.error.contractDateStart.invalidValue'),
          }
        return { message: ctx.defaultError }
      },
    })
    .min(new Date(MIN_DELIVERY_DATE), {
      message: i18next.t('quote.duplicate.form.error.contractDateStart.message', {
        date: new Date(MIN_DELIVERY_DATE).toLocaleDateString(language),
      }),
    }),
  contractDuration: validateStringNumber({
    invalidValue: i18next.t('quote.duplicate.form.error.contractDuration.invalidValue'),
    message: i18next.t('quote.duplicate.form.error.contractDuration.message'),
    min: MIN_CONTRACT_DURATION,
    max: MAX_CONTRACT_DURATION,
  }),
  feedbackDuplicateRequest: z.string().nullable(),
})

type ValidationSchema = z.infer<typeof validationSchema>

type Props = {
  classes: Object,
  id: string,
  handleCloseModal: () => void,
  history: Object,
}

const Form = ({ classes, id, handleCloseModal, history }: Props) => {
  const { t } = useTranslation()
  const { quoteData, onSubmit, isLoading } = useDuplicateRequest({ id, history })
  const { contractKmStart, contractKm, contractDuration, contractDateStart } = quoteData

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    reValidateMode: 'onSubmit',
  })

  return (
    <>
      <Modal.Header>{t('quote.duplicate.request')}</Modal.Header>
      <Modal.Content>
        <p className={classes.description}>
          {t('quote.duplicate.editableInformationForCreateDuplicate')}
        </p>

        <form id="duplicateForm" className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <TextField
            control={control}
            name="kmToStart"
            defaultValue=""
            type="number"
            label={`${t('quote.kmToStart')} *`}
            placeholder={`${t('globals.exampleColon')} 1000`}
            helperText={i18next.t('quote.duplicate.form.fields.helperText.kmToStart', {
              km: contractKmStart,
            })}
          />
          <TextField
            control={control}
            name="kmToGo"
            defaultValue=""
            type="number"
            label={`${t('quote.kmToGo')} *`}
            placeholder={`${t('globals.exampleColon')} 1000`}
            helperText={i18next.t('quote.duplicate.form.fields.helperText.kmToGo', {
              km: contractKm,
            })}
          />
          <TextField
            control={control}
            name="contractDateStart"
            defaultValue=""
            type="date"
            label={`${t('quote.contractDateStart')} *`}
            inputProps={{
              min: MIN_DELIVERY_DATE,
            }}
            helperText={i18next.t('quote.duplicate.form.fields.helperText.contractDateStart', {
              date: new Date(contractDateStart.date).toLocaleDateString(language),
            })}
          />
          <TextField
            control={control}
            name="contractDuration"
            defaultValue=""
            type="number"
            label={`${t('quote.contractDuration')} (${t('globals.month')}) *`}
            placeholder={`${t('globals.exampleColon')} 36`}
            helperText={i18next.t('quote.duplicate.form.fields.helperText.contractDuration', {
              duration: contractDuration,
            })}
          />
          <TextField
            variant="standard"
            control={control}
            name="feedbackDuplicateRequest"
            defaultValue=""
            type="text"
            label={t('quote.duplicate.form.fields.feedback')}
            placeholder={t('quote.generalFeedbackPlaceholder')}
            multiline
            rows={3}
          />
        </form>
      </Modal.Content>
      <Modal.Actions>
        {Object.keys(errors).length > 0 && (
          <div className={classes.errors}>
            <AlertMessageZone messages={getErrorsFormatted(errors)} />
          </div>
        )}

        <div className={classes.buttons}>
          <Button
            colorType="secondary"
            height="short"
            variant="contained"
            onClick={handleCloseModal}
          >
            {t('globals.cancel')}
          </Button>
          <Button
            form="duplicateForm"
            colorType="green"
            height="short"
            variant="contained"
            type="submit"
            isLoading={isLoading}
            disabled={isLoading}
          >
            {t('quote.duplicate.request')}
          </Button>
        </div>
      </Modal.Actions>
    </>
  )
}

export default (compose(withStyles(styles), withRouter)(Form): any)
