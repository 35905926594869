// @flow

import * as React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'

const styles = () => ({
  tooltip: {
    backgroundColor: '#fff',
    color: '#40454F',
    font: 'normal normal bold 14px/20px Lato',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: 4,
    padding: '5px 10px',
    textAlign: 'center',
    inset: '-25px auto 0px auto',
  },
  icon: {
    color: '#414b56',
    marginLeft: 5,
  },
})

type Props = {
  classes: Object,
  title: string | React.Node,
  offset: string,
}

const CustomTooltip = ({ classes, title, offset }: Props) => (
  <Tooltip
    title={title}
    classes={{ tooltip: classes.tooltip }}
    PopperProps={{
      popperOptions: {
        modifiers: {
          offset: {
            offset,
          },
        },
      },
    }}
  >
    <InfoIcon className={classes.icon} />
  </Tooltip>
)

export default (withStyles(styles)(CustomTooltip): any)
