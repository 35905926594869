// @flow

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { useTranslation } from 'react-i18next'
import SectionTitle from 'quote/cmp/PrintQuote/SectionTitle'
import Badge from '@autodisol/ads-js/components/Badge'

const styles = () => ({
  tags: {
    '& > span': {
      backgroundColor: '#F3F3F3',
      border: 'none',
      fontWeight: 900,
      margin: '0 0.4rem 0.4rem 0',
    },
  },
})

type Props = {
  classes: Object,
  tags: Object[],
}

const MoreInformation = ({ classes, tags }: Props): React$Element<any> => {
  const { t } = useTranslation()

  return (
    <>
      <SectionTitle title={t('quote.print.tags')} />
      <div className={classes.tags}>
        {tags.map(tag => (
          <Badge neutral key={tag} {...{ tag }} />
        ))}
      </div>
    </>
  )
}

export default (withStyles(styles)(MoreInformation): any)
