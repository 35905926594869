// @flow
import React from 'react'

import NewQuoteIcon from '@material-ui/icons/Add'
import ExportIcon from '@material-ui/icons/Publish'
import SearchIcon from '@material-ui/icons/Search'

import NewQuotePage from 'quote/cmp-page/NewQuotePage'
import EditQuotePage from 'quote/cmp-page/EditQuotePage'
import ExportedPage from 'quote/cmp-page/ExportedPage'
import IdentifyPage from 'quote/cmp-page/IdentifyPage'
import QuotePage from 'quote/cmp-page/QuotePage'
import PrintQuotePage from 'quote/cmp-page/PrintQuotePage'
import SearchPage from 'quote/cmp-page/SearchPage'
import AcceptValidAssignPage from 'quote/cmp-page/AcceptValidAssignPage'
import RejectValidAssignPage from 'quote/cmp-page/RejectValidAssignPage'

import {
  EXPORT_LIST_PATH,
  IDENTIFY_PATH,
  NEW_QUOTE_PATH,
  SEARCH_PATH,
  editQuotePath,
  printQuotePath,
  quotePath,
  quotePathValidate,
  quotePathReject,
} from 'quote/quote-paths'
import i18next from '../services/translations/config'

export const quoteRoute = {
  path: (quotePath(): string),
  exact: true,
  loggedInOnly: true,
  pageComponent: QuotePage,
  backNav: SEARCH_PATH,
  title: ({ router }: { router: Object }): any =>
    router?.location?.state &&
    Object.keys(router?.location?.state).length > 0 &&
    i18next
      .t('quote.vehicleCard', {
        make: router?.location?.state?.make,
        plate: router?.location?.state?.plate,
      })
      .toUpperCase(),
}

export const searchRoute: Object = {
  path: SEARCH_PATH,
  exact: true,
  loggedInOnly: true,
  pageComponent: SearchPage,
  title: i18next.t('quote.quotesMonitoringAndSearch'),
  icon: SearchIcon,
  withBottomNav: true,
}

export const newQuoteRoute = {
  path: NEW_QUOTE_PATH,
  exact: true,
  loggedInOnly: true,
  pageComponent: NewQuotePage,
  title: (i18next.t('globals.newRequest'): any),
  icon: NewQuoteIcon,
}

export const editQuoteRoute = {
  path: (editQuotePath(): string),
  exact: true,
  loggedInOnly: true,
  pageComponent: EditQuotePage,
  title: ({ router }: { router: Object }): string => {
    const plate = router?.location?.state?.plate
    const edit = i18next.t('quote.edit')

    return plate ? `${plate} - ${edit}` : edit
  },
}

export const printQuoteRoute = {
  path: printQuotePath,
  exact: true,
  loggedInOnly: true,
  pageComponent: PrintQuotePage,
  title: ({ router }: { router: Object }): string =>
    [
      router?.location?.state?.options?.plate,
      ' – ',
      router?.location?.state?.options?.make,
      router?.location?.state?.options?.model,
      ' – ',
      i18next.t('quote.print.sheet'),
    ]
      .filter(x => x)
      .join(' '),
  backNav: (_: Object, { params }: { params: Object, router: Object }): string =>
    quotePath(params.id),
}

export const exportListRoute: Object = {
  path: EXPORT_LIST_PATH,
  exact: true,
  loggedInOnly: true,
  pageComponent: ExportedPage,
  title: i18next.t('export.exports'),
  icon: () => <ExportIcon style={{ transform: 'rotate(180deg)' }} />,
}

export const identifyRoute: Object = {
  path: IDENTIFY_PATH,
  exact: true,
  loggedInOnly: true,
  pageComponent: IdentifyPage,
  title: i18next.t('globals.newRequest'),
  icon: NewQuoteIcon,
  withBottomNav: true,
}

export const acceptValidAssign: Object = {
  path: quotePathValidate(),
  exact: true,
  loggedInOnly: true,
  pageComponent: AcceptValidAssignPage,
  title: i18next.t('quote.waitAssignValidTitleAccept'),
}

export const rejectValidAssign: Object = {
  path: quotePathReject(),
  exact: true,
  loggedInOnly: true,
  pageComponent: RejectValidAssignPage,
  title: i18next.t('quote.waitAssignValidTitleReject'),
}
