// @flow

import Resizer from 'react-image-file-resizer'

type Params = {
  file: File,
  pictureMaxWidth: number,
  pictureMaxHeight: number,
  pictureQuality: number,
}

export const resizeFile = ({
  file,
  pictureMaxWidth,
  pictureMaxHeight,
  pictureQuality,
}: Params): Promise<File> =>
  new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      pictureMaxWidth,
      pictureMaxHeight,
      'JPEG',
      pictureQuality,
      0,
      uri => {
        resolve(uri)
      },
      'file',
    )
  })
