import {
  TAG_IS_DAMAGED,
  TAG_HAS_VAT,
  TAG_NEEDS_TIMING_BELT,
  TAG_HAS_SERVICE_RECORD,
  TAG_IS_FOREIGN,
  TYPE_EL_BATTERY_PURCHASED,
  TYPE_EL_BATTERY_RENT,
  TYPE_EL_BATTERY_HAS_WARRANTY,
  TYPE_EL_BATTERY_HAS_NO_POWER_CABLE,
  TYPE_EL_BATTERY_HAS_POWER_CABLE_MODE_2,
  TYPE_EL_BATTERY_HAS_POWER_CABLE_MODE_3,
  TYPE_EL_BATTERY_NUMBER_SUPPLIED_ONE,
  TYPE_EL_BATTERY_NUMBER_SUPPLIED_TWO,
  TYPE_EL_BATTERY_NUMBER_SUPPLIED_MORE,
  TAG_HAS_ABS_EQUIPMENT,
  TAG_HAS_VEHICLE_INSPECTION,
  TAG_PREVIOUS_AMBULANCE,
  TAG_PREVIOUS_TAXI,
  TAG_PREVIOUS_DRIVING_SCHOOL,
  TAG_PREVIOUS_LSR_LTR,
  TAG_PREVIOUS_CIRCUIT_OFF_ROAD,
  TAG_PREVIOUS_DELIVERY,
  TAG_PREVIOUS_ROAD,
  TAG_PREVIOUS_NOT_CONCERNED,
  TAG_IS_BROKEN,
} from '_client/config'
import i18next from 'services/translations/config'
import { MODES } from 'utils/constants/modes'

const ALL_MODES = Object.values(MODES)
const CAR_MODES = [MODES.standard, MODES.resval]
const BIKE_MODES = [MODES.standard_bike]

const PREVIOUS_USE_QUESTION_CHOICES = {
  TAXI: {
    label: i18next.t('quote.identification.taxi'),
    tag: TAG_PREVIOUS_TAXI,
  },
}

const isElectricOrHybrid = identification => ['EL', 'GH', 'EH'].includes(identification?.energy)
const isNotElectic = identification => !['EL'].includes(identification?.energy)

const descriptionElectric = [
  {
    subName: 'hasPowerCableMode2',
    subLabel: i18next.t('quote.identification.questions.hasPowerCableModeTwo'),
    subTag: TYPE_EL_BATTERY_HAS_POWER_CABLE_MODE_2,
  },
  {
    subName: 'hasPowerCableMode3',
    subLabel: i18next.t('quote.identification.questions.hasPowerCableModeThree'),
    subTag: TYPE_EL_BATTERY_HAS_POWER_CABLE_MODE_3,
  },
]

const questions = [
  {
    name: 'damaged',
    label: i18next.t('quote.identification.questions.isDamaged'),
    tag: TAG_IS_DAMAGED,
    modes: ALL_MODES,
  },
  {
    name: 'broken',
    label: i18next.t('quote.identification.questions.isBroken'),
    tag: TAG_IS_BROKEN,
    modes: ALL_MODES,
  },
  {
    name: 'timingBelt',
    label: i18next.t('quote.identification.questions.needsTimingBelt'),
    tag: TAG_NEEDS_TIMING_BELT,
    activeCallback: isNotElectic,
    modes: CAR_MODES,
  },
  {
    name: 'timingBelt',
    label: i18next.t('quote.identification.questions.transmission'),
    tag: TAG_NEEDS_TIMING_BELT,
    modes: BIKE_MODES,
  },
  {
    name: 'serviceRecord',
    label: i18next.t('quote.identification.questions.hasServiceRecord'),
    tag: TAG_HAS_SERVICE_RECORD,
    modes: ALL_MODES,
  },
  {
    name: 'vat',
    label: i18next.t('quote.identification.questions.hasVat'),
    tag: TAG_HAS_VAT,
    helper: 'VU, Dérivé VP, VSL, CTTE, location, VTC, Taxi',
    modes: ALL_MODES,
  },
  {
    name: 'hasAbs',
    label: i18next.t('quote.identification.questions.hasAbs'),
    tag: TAG_HAS_ABS_EQUIPMENT,
    modes: BIKE_MODES,
  },
  {
    name: 'hasVehicleInspection',
    label: i18next.t('quote.identification.questions.hasVehicleInspection'),
    tag: TAG_HAS_VEHICLE_INSPECTION,
    modes: BIKE_MODES,
  },
  {
    name: 'batteryType',
    label: i18next.t('quote.identification.questions.batteryType'),
    activeCallback: isElectricOrHybrid,
    choices: [
      {
        label: i18next.t('quote.identification.purchased'),
        tag: TYPE_EL_BATTERY_PURCHASED,
      },
      {
        label: i18next.t('quote.identification.rent'),
        tag: TYPE_EL_BATTERY_RENT,
      },
    ],
    modes: ALL_MODES,
  },
  {
    name: 'hasWarranty',
    label: i18next.t('quote.identification.questions.hasWarranty'),
    tag: TYPE_EL_BATTERY_HAS_WARRANTY,
    activeCallback: isElectricOrHybrid,
    modes: ALL_MODES,
  },
  {
    name: 'hasChargingCable',
    label: i18next.t('quote.identification.questions.hasChargingCable'),
    tag: TYPE_EL_BATTERY_HAS_NO_POWER_CABLE,
    activeCallback: isElectricOrHybrid,
    hasSubQuestions: true,
    subQuestions: descriptionElectric,
    modes: CAR_MODES,
  },
  {
    name: 'hasChargingCable',
    label: i18next.t('quote.identification.questions.hasChargingCableOrCharger'),
    tag: TYPE_EL_BATTERY_HAS_NO_POWER_CABLE,
    activeCallback: isElectricOrHybrid,
    modes: BIKE_MODES,
  },
  {
    name: 'batteryNumber',
    label: i18next.t('quote.identification.questions.batteryNumber'),
    activeCallback: isElectricOrHybrid,
    choices: [
      {
        label: i18next.t('quote.identification.batteryNumber.one'),
        tag: TYPE_EL_BATTERY_NUMBER_SUPPLIED_ONE,
      },
      {
        label: i18next.t('quote.identification.batteryNumber.two'),
        tag: TYPE_EL_BATTERY_NUMBER_SUPPLIED_TWO,
      },
      {
        label: i18next.t('quote.identification.batteryNumber.more'),
        tag: TYPE_EL_BATTERY_NUMBER_SUPPLIED_MORE,
      },
    ],
    modes: BIKE_MODES,
  },
  {
    name: 'foreign',
    label: i18next.t('quote.identification.questions.isForeign'),
    tag: TAG_IS_FOREIGN,
    modes: ALL_MODES,
  },
  {
    name: 'previous_use',
    label: i18next.t('quote.identification.questions.previousUse'),
    choices: [
      {
        label: i18next.t('quote.identification.notConcerned'),
        tag: TAG_PREVIOUS_NOT_CONCERNED,
      },
      {
        label: i18next.t('quote.identification.ambulance'),
        tag: TAG_PREVIOUS_AMBULANCE,
      },
      PREVIOUS_USE_QUESTION_CHOICES.TAXI,
      {
        label: i18next.t('quote.identification.drivingSchool'),
        tag: TAG_PREVIOUS_DRIVING_SCHOOL,
      },
      {
        label: i18next.t('quote.identification.lsrLtr'),
        tag: TAG_PREVIOUS_LSR_LTR,
      },
    ],
    modes: CAR_MODES,
  },
  {
    name: 'previous_use',
    label: i18next.t('quote.identification.questions.previousUse'),
    choices: [
      {
        label: i18next.t('quote.identification.route'),
        tag: TAG_PREVIOUS_ROAD,
      },
      PREVIOUS_USE_QUESTION_CHOICES.TAXI,
      {
        label: i18next.t('quote.identification.circuitOfRoad'),
        tag: TAG_PREVIOUS_CIRCUIT_OFF_ROAD,
      },
      {
        label: i18next.t('quote.identification.delivery'),
        tag: TAG_PREVIOUS_DELIVERY,
      },
      {
        label: i18next.t('quote.identification.bikeSchool'),
        tag: TAG_PREVIOUS_DRIVING_SCHOOL,
      },
    ],
    modes: BIKE_MODES,
  },
]

export const getQuestions = mode => questions.filter(element => element.modes.includes(mode))

export default questions
