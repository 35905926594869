// @flow

import React, { useEffect } from 'react'

import Grid from '@material-ui/core/Grid'
import LoadingPage from 'sharyn/components/LoadingPage'
import Page from 'sharyn/components/Page'
import Typography from '@material-ui/core/Typography'
import { connect as withRedux } from 'react-redux'
import compose from 'recompose/compose'
import { useMutation } from '@apollo/client'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { quotePath } from '../quote-paths'
import { validateAssignmentMutation } from '../quote-queries'

type AcceptValidAssignPageProps = {
  location: Object,
  history: Object,
}

const AcceptValidAssignPage = ({ location, history }: AcceptValidAssignPageProps) => {
  const { t } = useTranslation()

  const id = location.pathname.split('/').pop()

  const [validateAssignment] = useMutation(validateAssignmentMutation, {
    onCompleted: () => {
      history.push({
        pathname: quotePath(id),
      })
    },
  })

  useEffect(() => {
    validateAssignment({
      variables: {
        id,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page maxWidth={1362} noPaper>
      <Grid container justify="center">
        <div style={{ display: 'block' }}>
          <Typography variant="body1">{t('quote.waitAssignValidAccepted')}</Typography>
          <LoadingPage noDelay />
        </div>
      </Grid>
    </Page>
  )
}

export default (compose(
  withRedux(({ location }) => {
    location
  }),
  withRouter,
)(AcceptValidAssignPage): any)
