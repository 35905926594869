// @flow

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { useTranslation } from 'react-i18next'
import Damages from '@autodisol/ads-js/components/Damages/Damages'
import mapValues from 'lodash/mapValues'
import keyBy from 'lodash/keyBy'
import { getNameSchema } from 'quote/cmp/findSchema'
import ListItem from 'quote/cmp/PrintQuote/ListItem'
import DamagesLegend from '@autodisol/ads-js/components/Damages/DamagesLegend'
import SectionTitle from 'quote/cmp/PrintQuote/SectionTitle'

const styles = () => ({
  schema: {
    '& > div': {
      margin: 0,
      width: '100%',
    },
    '& > div > div': {
      maxWidth: '50%',
      padding: '8px !important',
    },
  },
  legend__title: {
    textAlign: 'center',
    marginTop: 0,
  },
  legend__list: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 15pt',
  },
  legend__list__item: {
    width: '50%',
    padding: '5pt',
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.6rem',
    '& > svg': {
      marginRight: '5pt',
    },
  },
  damages__list: {
    paddingLeft: 0,
    marginTop: '5pt',
  },

  legend: {
    border: '1px solid grey',
  },
})

const legendData = [{ type: 'T' }, { type: 'P' }, { type: 'TP' }, { type: 'RP' }]

type Props = {
  classes: Object,
  quote?: Object,
}

const DamagesBlock = ({ classes, quote }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <SectionTitle title={t('quote.damages')} />
      <div className={classes.schema}>
        <Damages
          schema={getNameSchema(quote?.type, quote?.dorrs)}
          damages={mapValues(keyBy(quote?.damages, 'zone'), ({ type }) => ({ type }))}
          greyscale
        />
      </div>
      <p className={classes.legend__title}>{t('quote.print.legend')}</p>
      <p className={classes.legend__list}>
        {legendData.map(({ type }) => (
          <span key={type} className={classes.legend__list__item}>
            <DamagesLegend
              type={type}
              variant="greyscale"
              width={36}
              height={16}
              className={classes.legend}
            />
            {t(`damage.type.${type}`)}
          </span>
        ))}
      </p>
      {quote?.damages && (
        <ul className={classes.damages__list}>
          {quote.damages.map(({ type, zone }) => (
            <ListItem
              key={`${zone}-${type}`}
              name={{ value: t(`damage.${zone}`) }}
              content={{ value: t(`damage.type.${type}`) }}
              variant="primary"
              typeValue="value"
            />
          ))}
        </ul>
      )}
    </>
  )
}

export default (withStyles(styles)(DamagesBlock): any)
