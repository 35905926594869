// @flow

import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Redirect from 'react-router-dom/Redirect'
import { logUserOut } from 'sharyn/redux/actions'
import { LOGIN_PATH } from 'auth/auth-paths'
import Cookies from 'js-cookie'
import * as Sentry from '@sentry/react'

const preloadedState = window.__PRELOADED_STATE__
const { JWT_AUTH_COOKIE_DOMAIN } = preloadedState.env

type LogoutRedirectProps = {
  dispatch: Function,
}

const LogoutRedirectJSX = ({ dispatch }: LogoutRedirectProps) => {
  useEffect(() => {
    Cookies.remove('autodisol_api_jwt_hp', { domain: JWT_AUTH_COOKIE_DOMAIN })

    Sentry.getCurrentScope().clear()

    dispatch(logUserOut())
  }, [dispatch])

  return <Redirect to={LOGIN_PATH} />
}

const LogoutRedirect: any = connect()(LogoutRedirectJSX)

export default LogoutRedirect
