// @flow

import React, { useState } from 'react'
import { compose } from 'recompose'
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'
import { SEARCH_PATH } from 'quote/quote-paths'
import Modal from 'Modal'
import Button from '@autodisol/ads-js/components/CustomButton'

const styles = () => ({
  textField: {
    marginTop: 30,
    '& > div > textarea': {
      padding: '0 5px',
    },
  },
})

type Props = {
  classes: Object,
  open: boolean,
  searchValue: String,
  closeModal: () => void,
  history: Object,
}
const SearchModal = ({ classes, open, searchValue, closeModal, history }: Props) => {
  const { t } = useTranslation()

  const defaultValue = searchValue.includes(';') ? searchValue.replace(/;/g, '\n') : ''

  const [value, setValue] = useState(defaultValue)

  const handleChangeTextField = e => {
    setValue(e.target.value)
  }

  const handleSubmit = event => {
    event.preventDefault()

    const formattedValue = value.trim().replace(/\s+/g, ';')

    if (formattedValue !== '') {
      history.push({
        pathname: SEARCH_PATH,
        state: { initialSearchFilters: { search: formattedValue } },
      })
    }

    closeModal()
  }

  return (
    <Modal open={open} handleClose={closeModal}>
      <Modal.Content>{t('quote.search.modal.content')}</Modal.Content>
      <form onSubmit={handleSubmit} id="form">
        <TextField
          name="multi_search"
          label={t('globals.search')}
          type="text"
          value={value}
          onChange={handleChangeTextField}
          className={classes.textField}
          InputLabelProps={{ shrink: true }}
          multiline
          rows={5}
        />
      </form>

      <Modal.Actions>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            form="form"
            colorType="green"
            variant="contained"
            type="submit"
            style={{ width: '50%', marginTop: 10 }}
          >
            {t('globals.search')}
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  )
}

export default (compose(withStyles(styles), withRouter)(SearchModal): any)
