// @flow

import React, { useState, useEffect } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import NewQuoteIcon from '@material-ui/icons/Add'
import QuotesIcon from '@material-ui/icons/AssignmentOutlined'
import SearchIcon from '@material-ui/icons/Search'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import { useTranslation } from 'react-i18next'
import { connect as withRedux } from 'react-redux'
import compose from 'recompose/compose'
import { SEARCH_PATH, IDENTIFY_PATH } from 'quote/quote-paths'
import swit from 'sharyn/util/swit'
import { getModesData } from 'utils/modes'

const handleBottomNavigation =
  ({ setBottomNavigationValue, routerHistory }) =>
  (_, value) => {
    setBottomNavigationValue(value)
    swit(value, [
      [
        0,
        () => {
          routerHistory.push(SEARCH_PATH)
        },
      ],
      [1, () => routerHistory.push(IDENTIFY_PATH)],
      [
        2,
        () => {
          routerHistory.push({
            pathname: SEARCH_PATH,
            state: { initialSearchState: 'monitoring' },
          })
        },
      ],
    ])
  }

type BottomNavProps = {
  classes: Object,
  routerHistory: Object,
  hasRightsToMakeNewRequests: boolean,
}

const BottomNavJSX = ({
  classes: css,
  routerHistory,
  hasRightsToMakeNewRequests,
}: BottomNavProps) => {
  const { t } = useTranslation()
  const [bottomNavigationValue, setBottomNavigationValue] = useState(null)

  useEffect(() => {
    swit(routerHistory.location.pathname, [
      [
        SEARCH_PATH,
        () => {
          if (routerHistory.location.state?.initialSearchState === 'monitoring') {
            setBottomNavigationValue(2)
          } else {
            setBottomNavigationValue(0)
          }
        },
      ],
      [IDENTIFY_PATH, () => setBottomNavigationValue(1)],
    ])
  }, [routerHistory])

  return (
    <BottomNavigation
      className={css.bottomNavigationBar}
      showLabels
      value={bottomNavigationValue}
      onChange={handleBottomNavigation({
        ...{ setBottomNavigationValue, routerHistory },
      })}
    >
      <BottomNavigationAction label={t('globals.searchNoun')} icon={<SearchIcon />} />
      {hasRightsToMakeNewRequests && (
        <BottomNavigationAction
          label={t('globals.newRequest')}
          icon={<NewQuoteIcon />}
          className={css.middleButton}
        />
      )}
      <BottomNavigationAction label={t('globals.quotesMonitoringShort')} icon={<QuotesIcon />} />
    </BottomNavigation>
  )
}

const BottomNav: any = compose(
  withRedux(({ user, router }) => {
    const { rights } = user.data
    const { currentMode } = getModesData(user, router.location.pathname)

    return {
      hasRightsToMakeNewRequests:
        Boolean(rights.find(right => right === 'identify')) &&
        Boolean(rights.find(right => right === 'create_quote')) &&
        currentMode !== null &&
        user.data.config.quote.quote[currentMode]?.activated !== false &&
        user.data.config.quote.quote[currentMode]?.readonly !== true,
    }
  }),
  withStyles(({ breakpoints }) => ({
    bottomNavigationBar: {
      zIndex: 2,
      position: 'fixed',
      bottom: 0,
      width: '100%',
      [breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    middleButton: {
      flex: 1.3,
    },
  })),
)(BottomNavJSX)

export default BottomNav
