// @flow

import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import Button from '@autodisol/ads-js/components/CustomButton'
import Modal from 'Modal'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import { pricingQuoteQuery } from 'quote/quote-queries'
import { isInteger } from 'utils/number'

const FORM_ID = 'commercialProposition'

export const checkCanSubmitACommercialOffer = ({
  hasViewGroupQuote,
  mustBePropositionLessThanOrEqualToB2cAdvisedPrice,
  mustBePropositionLessThanOrEqualToQuoterPrice,
  propositionValue,
  hasB2cAdvisedPrice,
  isSmallerOrEqualTradeinOfferThanB2cAdvisedPrice,
  isSmallerOrEqualTradeinOfferThanQuoterPrice,
}: {
  hasViewGroupQuote: boolean,
  mustBePropositionLessThanOrEqualToB2cAdvisedPrice: boolean,
  mustBePropositionLessThanOrEqualToQuoterPrice: boolean,
  propositionValue: string | number,
  hasB2cAdvisedPrice: boolean,
  isSmallerOrEqualTradeinOfferThanB2cAdvisedPrice: boolean,
  isSmallerOrEqualTradeinOfferThanQuoterPrice: boolean,
}): boolean => {
  if (
    hasViewGroupQuote ||
    (!mustBePropositionLessThanOrEqualToB2cAdvisedPrice &&
      !mustBePropositionLessThanOrEqualToQuoterPrice)
  )
    return true

  if (
    mustBePropositionLessThanOrEqualToB2cAdvisedPrice &&
    mustBePropositionLessThanOrEqualToQuoterPrice
  )
    return (
      propositionValue === '' ||
      (hasB2cAdvisedPrice && isSmallerOrEqualTradeinOfferThanB2cAdvisedPrice) ||
      isSmallerOrEqualTradeinOfferThanQuoterPrice
    )

  if (mustBePropositionLessThanOrEqualToB2cAdvisedPrice)
    return (
      propositionValue === '' ||
      !hasB2cAdvisedPrice ||
      (hasB2cAdvisedPrice && isSmallerOrEqualTradeinOfferThanB2cAdvisedPrice)
    )

  return propositionValue === '' || isSmallerOrEqualTradeinOfferThanQuoterPrice
}

export const getCommercialPropositionTooHighMessage = (
  mustBePropositionLessThanOrEqualToB2cAdvisedPrice: boolean,
  mustBePropositionLessThanOrEqualToQuoterPrice: boolean,
): string => {
  if (
    mustBePropositionLessThanOrEqualToB2cAdvisedPrice &&
    mustBePropositionLessThanOrEqualToQuoterPrice
  )
    return 'quote.commercialPropositionTooHigh.lteB2cAndLteQuoter'

  if (mustBePropositionLessThanOrEqualToB2cAdvisedPrice)
    return 'quote.commercialPropositionTooHigh.lteB2c'

  if (mustBePropositionLessThanOrEqualToQuoterPrice)
    return 'quote.commercialPropositionTooHigh.lteQuoter'

  return ''
}

const CommercialPropositionModal = ({
  rights,
  quoteId,
  quoterPrice,
  tradeinOffer,
  b2cAdvisedPrice,
  tradeinOfferConfiguration,
  open,
  closeModal,
}: {
  rights: string[],
  quoteId: string,
  quoterPrice: Object,
  tradeinOffer: Object,
  b2cAdvisedPrice?: Object,
  tradeinOfferConfiguration: Object,
  open: boolean,
  closeModal: Function,
}) => {
  const { t } = useTranslation()

  const [proposition, setProposition] = useState(null)
  const [pricingQuoteMutation, { loading: isPricingQuoteLoading }] = useMutation(
    pricingQuoteQuery,
    {
      onCompleted: () => {
        closeModal()
      },
      refetchQueries: ['getQuoteAndCountQuery'],
      awaitRefetchQueries: true,
    },
  )

  const propositionValue = proposition ?? tradeinOffer?.amount ?? ''

  const handleChangeCommercialProposition = e => {
    const { value } = e.target

    if (isInteger(value)) {
      setProposition(e.target.value)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    pricingQuoteMutation({
      variables: { id: quoteId, amount: parseFloat(propositionValue), type: 'tradein_offer' },
    })
  }

  const hasViewGroupQuote = rights.includes('view_group_quote')
  const hasB2cAdvisedPrice = b2cAdvisedPrice?.amount !== undefined
  const isSmallerOrEqualTradeinOfferThanB2cAdvisedPrice =
    propositionValue <= b2cAdvisedPrice?.amount
  const isSmallerOrEqualTradeinOfferThanQuoterPrice = propositionValue <= quoterPrice?.amount
  const mustBePropositionLessThanOrEqualToB2cAdvisedPrice =
    tradeinOfferConfiguration?.conditions?.lte_b2c ?? false
  const mustBePropositionLessThanOrEqualToQuoterPrice =
    tradeinOfferConfiguration?.conditions?.lte_quoter ?? false

  const canSubmitACommericalOffer = checkCanSubmitACommercialOffer({
    hasViewGroupQuote,
    mustBePropositionLessThanOrEqualToB2cAdvisedPrice,
    mustBePropositionLessThanOrEqualToQuoterPrice,
    propositionValue,
    hasB2cAdvisedPrice,
    isSmallerOrEqualTradeinOfferThanB2cAdvisedPrice,
    isSmallerOrEqualTradeinOfferThanQuoterPrice,
  })

  const commercialPropositionTooHighMessage = getCommercialPropositionTooHighMessage(
    mustBePropositionLessThanOrEqualToB2cAdvisedPrice,
    mustBePropositionLessThanOrEqualToQuoterPrice,
  )

  return (
    <Modal
      open={open}
      handleClose={closeModal}
      ariaLabelledby="commercial proposition"
      ariaDescribedby="commercial proposition"
    >
      <Modal.Header>{t('quote.commercialProposition')}</Modal.Header>
      <Modal.Content>
        <form onSubmit={handleSubmit} id={FORM_ID}>
          <TextField
            name="commercial_proposition"
            type="text"
            value={propositionValue}
            onChange={handleChangeCommercialProposition}
            InputLabelProps={{ shrink: true }}
          />
          {!canSubmitACommericalOffer && commercialPropositionTooHighMessage !== '' && (
            <p style={{ color: 'red' }}>{t(commercialPropositionTooHighMessage)}</p>
          )}
        </form>
      </Modal.Content>
      <Modal.Actions>
        <div>
          <Button
            colorType="secondary"
            height="short"
            variant="contained"
            onClick={closeModal}
            style={{ width: 215, marginRight: 4 }}
          >
            {t('globals.cancel')}
          </Button>
          <Button
            form={FORM_ID}
            type="submit"
            colorType="green"
            height="short"
            variant="contained"
            style={{ width: 215, marginLeft: 4 }}
            disabled={!propositionValue || !canSubmitACommericalOffer || isPricingQuoteLoading}
          >
            {isPricingQuoteLoading ? (
              <CircularProgress size={24} color="primary" />
            ) : (
              t('quote.save')
            )}
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  )
}

export default (CommercialPropositionModal: any)
