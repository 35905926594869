// @flow

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import compose from 'recompose/compose'
import { connect as withRedux } from 'react-redux'
import { getModesData, getModeList } from 'utils/modes'
import { setMode } from '_client/redux/actions'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import type { Modes } from 'app/cmp/ModesMenu/types'
import type { ModeList } from 'utils/modes'
import { useTranslation } from 'react-i18next'

const styles = ({ palette }) => ({
  root: {
    border: 'none',
    width: 'fit-content',
    '& svg': {
      color: palette.secondary.dark,
    },
  },
  select: {
    display: 'flex',
    paddingTop: 11,
    paddingBottom: 11,
    '&:focus': {
      background: 'none',
    },
  },
})

type Props = {
  classes: Object,
  mode: Modes,
  modeList: ModeList,
  mustReloadThePage: boolean,
  dispatch: Function,
}

const ModesSelect = ({ classes, mode, modeList, mustReloadThePage, dispatch }: Props) => {
  const { t } = useTranslation()

  const handleSelect = e => {
    const { value } = e.target

    if (mode === value) return

    dispatch(setMode(e.target.value))

    if (mustReloadThePage) window.location.reload()
  }

  return (
    <Select
      className={classes.root}
      value={mode}
      onChange={handleSelect}
      classes={{ select: classes.select }}
    >
      {modeList.map(element => {
        const IconComponent = element.icon

        return (
          <MenuItem key={element.mode} value={element.mode}>
            <ListItemIcon>
              <IconComponent />
            </ListItemIcon>
            <ListItemText>{t(element.translationKey)}</ListItemText>
          </MenuItem>
        )
      })}
    </Select>
  )
}

export default (compose(
  withRedux(({ user, router }) => {
    const { currentMode } = getModesData(user, router.location.pathname)
    const modeList = getModeList(user)

    return {
      mode: currentMode,
      modeList,
      mustReloadThePage: router.location.pathname === '/search',
    }
  }),
  withStyles(styles),
)(ModesSelect): any)
