// @flow

import React from 'react'
import compose from 'recompose/compose'
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { findFacetsQuery } from 'home/home-queries'
import { findFacets } from '_client/util'
import { SEARCH_PATH } from 'quote/quote-paths'
import { quoteGroupStatusList } from '_client/config'
import Button from '@autodisol/ads-js/components/CustomButton'
import Typography from '@material-ui/core/Typography'

import FleetIcon from '../../../public/img/fleetIcon.svg'

const styles = ({ palette }) => ({
  buttonText: {
    flexGrow: 1,
    textAlign: 'left',
    marginLeft: 70,
  },
  icon: {
    position: 'absolute',
    left: 30,
    width: '2.3rem',
    fill: 'white',
  },
  badge: {
    width: '2rem',
    minWidth: '2rem',
    height: '2rem',
    borderRadius: '50%',
    background: 'white',
    color: palette.secondary.dark,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
})

const useFleetButton = (categories, history) => {
  const { data, loading: isLoading } = useQuery(findFacetsQuery, {
    variables: {
      filters: {
        onlyCount: true,
        categories,
        status: quoteGroupStatusList.resval.group_fleet,
      },
    },
  })

  const formattedFacets: Object = findFacets(data?.search_quote?.facets)

  const handleClick = () => {
    history.push({
      pathname: SEARCH_PATH,
      state: {
        initialSearchState: 'fleet',
        orderBy: 'sentInFleetAt:desc',
      },
    })
  }

  return {
    isLoading,
    count: (formattedFacets?.status?.fleet ?? 0) + (formattedFacets?.status?.updated_fleet ?? 0),
    handleClick,
  }
}

type Props = {
  classes: Object,
  categories: string[],
  history: Object,
}

const FleetButton = ({ classes, categories, history }: Props) => {
  const { t } = useTranslation()
  const { isLoading, count, handleClick } = useFleetButton(categories, history)

  return (
    <Button
      colorType="secondary"
      height="tall"
      variant="contained"
      onClick={handleClick}
      fullWidth
      isLoading={isLoading}
      disabled={count === 0}
    >
      <FleetIcon className={classes.icon} />
      <span className={classes.buttonText}> {t('home.fleet')}</span>
      <Typography variant="button" component="div" className={classes.badge}>
        {count}
      </Typography>
    </Button>
  )
}

export default (compose(withStyles(styles), withRouter)(FleetButton): any)
