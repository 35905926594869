// @flow

import React, { useRef, useState } from 'react'
import { useMutation } from '@apollo/client'
import withStyles from '@material-ui/core/styles/withStyles'
import seq from 'sharyn/util/seq'
import Grid from '@material-ui/core/Grid'
import Pictures from '@autodisol/ads-js/components/Pictures'
import compose from 'recompose/compose'
import { connect as withRedux } from 'react-redux'
import { removeDocumentMutation, uploadDocumentMutation } from 'quote/quote-queries'
import { DOCUMENT_TYPE } from 'utils/constants/quote'
import { useTranslation } from 'react-i18next'
import { getModesData } from 'utils/modes'
import { MODES } from 'utils/constants/modes'
import { TAG_REG_CARD } from '_client/config'
import PhotoElement from 'quote/cmp/Photos/PhotoElement'
import AddPictureButton from 'quote/cmp/AddPictureButton'
import { resizeFile } from 'utils/resize'
import { getDocumentTags } from 'quote/functions'
import { TYPES_MIME } from 'utils/constants'

import type { Mode } from 'types/modes'

import RegCard from '../../../../public/img/RegCard.svg'

const handlePictureUpload =
  ({
    currentDraftQuoteId,
    uploadDocument,
    field,
    filePicker,
    identifiedVehicle: i,
    uploadedPictures,
    setUploadedPictures,
    filePickerHandler,
    isEditionMode,
    isCorrectionMode,
    resizeEnabled,
    tags,
    type,
    isCloseMode,
    pictureMaxWidth,
    pictureMaxHeight,
    pictureQuality,
  }: {
    currentDraftQuoteId: any,
    uploadDocument: Function,
    field: Function,
    filePicker: Function,
    identifiedVehicle: Object,
    uploadedPictures: Object[],
    setUploadedPictures: Function,
    filePickerHandler: Function,
    isEditionMode: any,
    isCorrectionMode: any,
    resizeEnabled: boolean,
    tags: string[],
    type: string,
    isCloseMode?: boolean,
    pictureMaxWidth: number,
    pictureMaxHeight: number,
    pictureQuality: number,
  }) =>
  async e => {
    e.preventDefault()

    await filePickerHandler(e)

    const { name } = e.target

    let image = filePicker && filePicker(name)[0]

    if (!image) return

    if (resizeEnabled && image.type !== TYPES_MIME.PDF) {
      const imageResize = await resizeFile({
        file: image,
        pictureMaxWidth,
        pictureMaxHeight,
        pictureQuality,
      })
      if (imageResize.size < image.size) image = imageResize
    }

    const resp = await uploadDocument({
      variables: {
        documentInput: {
          type,
          tags,
          vin: i?.vin ?? field('vin'),
          quoteId: isEditionMode || isCorrectionMode || isCloseMode ? i?.id : currentDraftQuoteId,
        },
        file: image,
      },
    })

    if (resp?.data?.upload_document) {
      setUploadedPictures([
        {
          id: resp.data.upload_document.id,
          name: resp.data.upload_document.filename,
          src: resp.data.upload_document.uri,
          tags: resp.data.upload_document.tags[0] ?? [],
          type: resp.data.upload_document.type,
          mime: resp.data.upload_document.mime,
        },
        ...uploadedPictures,
      ])
    }
  }

const deletePicture =
  ({ uploadedPictures, setUploadedPictures, removeDocument, picture }) =>
  async () => {
    const resp = await removeDocument({
      variables: {
        id: picture?.id,
      },
    })

    if (resp?.data?.remove_document?.successful) {
      setUploadedPictures(uploadedPictures.filter(v => v.id !== picture?.id))
    }
  }

const PhotosCmp = ({
  onChange,
  classes: css,
  currentDraftQuoteId,
  field,
  filePicker,
  identifiedVehicle,
  uploadedPictures,
  setUploadedPictures,
  filePickerHandler,
  isEditionMode,
  isCorrectionMode,
  resizeEnabled,
  hasRequiredPhotos,
  schema,
  isCloseMode,
  mode,
  pictureMaxWidth,
  pictureMaxHeight,
  pictureQuality,
}: {
  onChange: any,
  classes: any,
  currentDraftQuoteId: any,
  field: any,
  filePicker: any,
  identifiedVehicle: any,
  uploadedPictures: any,
  setUploadedPictures: Function,
  filePickerHandler: any,
  isEditionMode: any,
  isCorrectionMode: any,
  resizeEnabled: any,
  hasRequiredPhotos: any,
  schema: Object[],
  isCloseMode?: boolean,
  mode: Mode,
  pictureMaxWidth: number,
  pictureMaxHeight: number,
  pictureQuality: number,
}) => {
  const { t } = useTranslation()
  const [actualTags, setActualTags] = useState(undefined)

  const pictureInputRef = useRef<HTMLInputElement | null>(null)
  const regCardInputRef = useRef<HTMLInputElement | null>(null)

  const [uploadDocument, { loading: isPictureUploading }] = useMutation(uploadDocumentMutation)
  const [removeDocument] = useMutation(removeDocumentMutation)

  const isBikeMode = mode === MODES.standard_bike
  const hasFoundRegCardPicture = uploadedPictures.find(e => e.type === TAG_REG_CARD) !== undefined

  const handlePhotoButton = (value?: string) => () => {
    if (value === TAG_REG_CARD) {
      regCardInputRef.current && regCardInputRef.current.click()
      setActualTags(undefined)
      return
    }

    setActualTags(value)
    pictureInputRef.current && pictureInputRef.current.click()
  }

  const handleClickAddPickture = () => {
    setActualTags(undefined)
  }

  const handleChangeInputFile = type => e =>
    seq(
      () => onChange(e),
      () => {
        e.persist()

        handlePictureUpload({
          currentDraftQuoteId,
          uploadDocument,
          field,
          filePicker,
          identifiedVehicle,
          uploadedPictures,
          setUploadedPictures,
          filePickerHandler,
          isEditionMode,
          isCorrectionMode,
          resizeEnabled,
          tags: getDocumentTags(actualTags, type),
          type,
          isCloseMode,
          pictureMaxWidth,
          pictureMaxHeight,
          pictureQuality,
        })(e)
      },
    )

  const handleDelete = value => () => {
    const picture = uploadedPictures.find(e => e.tags === value || e.type === value)

    if (!picture) return

    deletePicture({
      uploadedPictures,
      setUploadedPictures,
      removeDocument,
      picture,
    })()

    if (picture?.type === DOCUMENT_TYPE.REG_CARD && regCardInputRef.current) {
      regCardInputRef.current.value = ''
      return
    }

    if (pictureInputRef.current) {
      pictureInputRef.current.value = ''
    }
  }

  return (
    <>
      <Grid spacing={40} container justify="center" style={{ maxWidth: 600, textAlign: 'center' }}>
        <div className={css.introduction}>
          <h2>
            {t(
              `quote.identification.picture.${
                isBikeMode ? 'bike' : 'car'
              }.reachMinimimPicturesNumber`,
            )}
          </h2>
          <p>{t('quote.identification.picture.helpText')}</p>
        </div>

        <input
          ref={pictureInputRef}
          name="picture"
          type="file"
          accept="image/png, image/jpg, image/jpeg, fake/mime" // fake/mime is a workaround for bug with latest Android version
          onChange={handleChangeInputFile(DOCUMENT_TYPE.PHOTO)}
          style={{ display: 'none' }}
        />
        <input
          ref={regCardInputRef}
          name="regCard"
          type="file"
          accept=".pdf, image/jpg, image/jpeg, fake/mime" // fake/mime is a workaround for bug with latest Android version
          onChange={handleChangeInputFile(DOCUMENT_TYPE.REG_CARD)}
          style={{ display: 'none' }}
        />

        {schema.map(zone => {
          const pictureFound = uploadedPictures.find(e => e.tags === zone?.tags)

          return (
            <PhotoElement
              key={zone.id}
              title={t(`quote.identification.schema.${zone.id}`)}
              subtitle={zone.subtitle ? t(`quote.identification.schema.${zone.id}Subtitle`) : ''}
              svg={zone.svg}
              handlePhotoButton={handlePhotoButton(zone.tags)}
              uploadedPictures={uploadedPictures}
              isPictureUploading={
                schema.find(e => e.tags === actualTags)?.tags === zone?.tags &&
                !hasRequiredPhotos &&
                isPictureUploading
              }
              handleDelete={handleDelete(zone.tags)}
              pictures={uploadedPictures.filter(e => e.tags === zone?.tags) ?? []}
              isDisabled={pictureFound?.tags === zone?.tags || isPictureUploading}
              hasPicture={pictureFound !== undefined}
            />
          )
        })}
        {isBikeMode && (
          <PhotoElement
            title={t(`quote.identification.schema.bike.regCard`)}
            svg={<RegCard />}
            handlePhotoButton={handlePhotoButton(TAG_REG_CARD)}
            uploadedPictures={uploadedPictures}
            isPictureUploading={!hasRequiredPhotos && !actualTags && isPictureUploading}
            handleDelete={handleDelete(TAG_REG_CARD)}
            pictures={uploadedPictures.filter(e => e.type === TAG_REG_CARD) ?? []}
            isDisabled={hasFoundRegCardPicture || isPictureUploading}
            hasPicture={hasFoundRegCardPicture}
          />
        )}
      </Grid>

      <AddPictureButton
        onClick={handleClickAddPickture}
        handleChangeInputFile={handleChangeInputFile(DOCUMENT_TYPE.PHOTO)}
        disabled={!hasRequiredPhotos || isPictureUploading}
        isLoading={hasRequiredPhotos && isPictureUploading}
        accept="image/png, image/jpg, image/jpeg, fake/mime" // fake/mime is a workaround for bug with latest Android version
        content={t('quote.addPicture')}
        height="tall"
        style={{ marginTop: 30 }}
      />

      <Grid container spacing={16} justify="center" style={{ marginTop: 30 }}>
        {uploadedPictures
          .filter(e => e.tags === 'commercial')
          .map(picture => (
            <Grid key={picture.id} item md={2} xs={6}>
              <Pictures
                pictures={[picture]}
                deletable
                maxImagesInARow={1}
                justify="flex-start"
                handleDelete={deletePicture({
                  uploadedPictures,
                  setUploadedPictures,
                  removeDocument,
                  picture: uploadedPictures.find(
                    uploadedPicture => uploadedPicture.id === picture.id,
                  ),
                })}
              />
            </Grid>
          ))}
      </Grid>
    </>
  )
}

const PhotosStyle = withStyles(() => ({
  introduction: {
    padding: '0 10%',
    '& > h2': {
      fontSize: '1rem',
      marginBottom: 5,
    },
    '& > p': {
      fontSize: '0.75rem',
      margin: 0,
    },
  },
}))(PhotosCmp)

const Photos: any = compose(
  withRedux(({ router, user }) => {
    const { currentMode } = getModesData(user, router.location.pathname)

    return { isCloseMode: router?.location?.state?.mode === 'close', mode: currentMode }
  }),
)(PhotosStyle)

export default Photos
