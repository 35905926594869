// @flow

import { MODES } from 'utils/constants/modes'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import TimerIcon from '@material-ui/icons/Timer'
import MotorcycleIcon from '@material-ui/icons/Motorcycle'
import type { Mode } from '../types/modes'

export type ModeList = {
  mode: Mode,
  translationKey: string,
  icon: typeof LocalOfferIcon,
}[]

type UserData = {
  mode: {
    currentMode?: string | null,
    isDisabledModes?: boolean,
  },
  data: {
    config: {
      quote: {
        quote: {
          standard: {
            activated: boolean,
          },
          resval: {
            activated: boolean,
          },
        },
      },
    },
  },
}

type ModesData = {
  accessModes: string[],
  isDisabledModes: boolean,
  currentMode: string | null,
  isDisplayModeSelector: boolean,
  isDisplayModesMenu: boolean,
  hasAModeSelected: boolean,
}

const DISABLED_MODES_PATHNAMES = [
  '/new-quote',
  '/edit-quote',
  '/quote',
  '/valid-quote',
  '/reject-quote/',
]

const MODES_DETAILS = {
  standard: { translationKey: 'home.mode.standard', icon: LocalOfferIcon },
  resval: { translationKey: 'home.mode.resval', icon: TimerIcon },
  standard_bike: { translationKey: 'home.mode.standard_bike', icon: MotorcycleIcon },
}

export const getAccessMode = (userData?: Object): string[] => {
  const modes = []
  const quoteConfiguration = userData?.data?.config?.quote?.quote

  if (
    quoteConfiguration?.standard?.activated !== false ||
    (quoteConfiguration?.standard?.children &&
      quoteConfiguration?.standard?.children?.activated !== null)
  ) {
    modes.push(MODES.standard)
  }

  if (
    quoteConfiguration?.resval?.activated ||
    (quoteConfiguration?.resval?.children &&
      quoteConfiguration?.resval?.children?.activated !== null)
  ) {
    modes.push(MODES.resval)
  }

  if (
    quoteConfiguration?.standard_bike?.activated ||
    (quoteConfiguration?.standard_bike?.children &&
      quoteConfiguration?.standard_bike?.children?.activated !== null)
  ) {
    modes.push(MODES.standard_bike)
  }

  return modes
}

export const getModeList = (userData?: Object): ModeList => {
  const accessModes = getAccessMode(userData)

  return accessModes.map(mode => ({
    mode,
    ...MODES_DETAILS[mode],
  }))
}

export const getModesData = (userData: UserData, pathname: string): ModesData => {
  const accessModes = getAccessMode(userData)
  const defaultMode = accessModes.includes(MODES.standard) ? MODES.standard : accessModes[0]
  const mode = userData?.mode?.currentMode ?? null
  const currentMode =
    (pathname !== '/' && mode === null) || (accessModes.length === 1 && mode === null)
      ? defaultMode
      : mode

  return {
    accessModes,
    isDisabledModes: Boolean(DISABLED_MODES_PATHNAMES.find(element => pathname.includes(element))),
    currentMode,
    isDisplayModeSelector: accessModes.length > 1 && currentMode === null,
    isDisplayModesMenu: accessModes.length > 1 && currentMode !== null,
    hasAModeSelected: currentMode !== null,
  }
}
