// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import withStyles from '@material-ui/core/styles/withStyles'
import { connect as withRedux } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'
import { useQuoteDataFromCache } from 'hooks/useQuoteDataFromCache'
import { useFormData } from 'quote/cmp/QuoteActionsPanel/Resval/AffectTheVehicleAction/useFormData'
import { useSites } from 'quote/cmp/QuoteActionsPanel/Resval/AffectTheVehicleAction/useSites'
import { RIGHT_REMOVE_SITE_QUOTE, RIGHT_ADD_SITE_QUOTE, RIGHT_ASSIGN_QUOTE } from '_client/config'
import Typography from '@material-ui/core/Typography'
import Progress from '@material-ui/core/CircularProgress'
import Checkbox from '@autodisol/ads-js/components/Inputs/ReactHookForm/Checkbox'
import ActionWithModal from 'quote/cmp/QuoteActionsPanel/Resval/ActionWithModal'
import Modal from 'Modal'
import SiteSelection from 'quote/cmp/QuoteActionsPanel/Resval/AffectTheVehicleAction/SiteSelection'
import { notify } from 'sharyn/redux/actions'

const styles = () => ({
  loader: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  information__message: {
    marginTop: 20,
    fontStyle: 'italic',
    textAlign: 'center',
  },
})

type LabelProps = {
  isDisabledCheckbox: boolean,
}

const Label = ({ isDisabledCheckbox }: LabelProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Typography variant="body2">{t('quote.identicalBillingAndDeliverySite')}</Typography>
      {isDisabledCheckbox && (
        <Typography variant="caption">{t('quote.notABillingSite')}</Typography>
      )}
    </>
  )
}

type Props = {
  classes: Object,
  id: string,
  rights: string[],
  dispatch: Function,
  history: Object,
}

const AffectTheVehicleAction = ({ classes, id, rights, dispatch, history }: Props) => {
  const { t } = useTranslation()
  const { open, openModal, closeModal } = Modal.useModal()
  const quoteData = useQuoteDataFromCache(id)
  const {
    refetchWithdrawalSites,
    refetchBillingSites,
    billingSites,
    withdrawalSites,
    isLoadingFetch,
    hasMoreThanOneWithdrawalPage,
    hasMoreThanOneBillingPage,
    postAssignQuote,
    isLoadingPost,
    isLoadingDefaultActionGroupIdData,
    getDefaultAuctionGroupId,
  } = useSites({ id, dispatch, history })
  const {
    handleChangeCheckbox,
    handleChangeRadio,
    handleClickValidate,
    isDisabledRadio,
    isDisabledCheckbox,
    control,
    errors,
    resetData,
  } = useFormData({ billingSites, postAssignQuote })

  const hasRightToAffectVehicle = [
    RIGHT_REMOVE_SITE_QUOTE,
    RIGHT_ADD_SITE_QUOTE,
    RIGHT_ASSIGN_QUOTE,
  ].every(element => rights.includes(element))

  const handleCloseModal = () => {
    closeModal()
    resetData()
  }

  const handleOpenModal = async () => {
    const { data: defaultAuctionGroupIdData } = await getDefaultAuctionGroupId()

    const auctionGroupId = defaultAuctionGroupIdData?.get_default_auction_group_id

    if (auctionGroupId) {
      openModal()
      return
    }

    dispatch(
      notify({
        title: t('error.general.anErrorOccured'),
        message: t('error.general.pleaseTryAgainByReloadingThePage'),
      }),
    )
  }

  if (!hasRightToAffectVehicle) return null

  return (
    <ActionWithModal
      handleCloseModal={handleCloseModal}
      actionButtonContent={t('quote.affectTheVehicle.button')}
      ariaLabelledby="affect the vehicle"
      ariaDescribedby="affect the vehicle"
      modalTitle={t('quote.requestWithdrawal.notification.title')}
      modalDescription=""
      errors={errors}
      isLoading={isLoadingPost}
      modalValidateButtonContent={t('quote.sendDemand')}
      open={open}
      openModal={handleOpenModal}
      handleClickValidate={handleClickValidate}
      buttonType="button"
      isLoadingButtonOpenModal={isLoadingDefaultActionGroupIdData}
      informationMessage={
        <p className={classes.information__message}>{t('quote.mentionOfSalesMandate')}</p>
      }
    >
      {isLoadingFetch ? (
        <div className={classes.loader}>
          <Progress size={40} />
        </div>
      ) : (
        <>
          <SiteSelection
            control={control}
            sites={withdrawalSites}
            handleChangeRadio={handleChangeRadio}
            refetch={refetchWithdrawalSites}
            enquirerGroup={quoteData.enquirerGroup}
            isDsplayFilterSites={hasMoreThanOneWithdrawalPage}
            label={t('quote.withdrawalSite')}
            type="withdrawal"
          />
          <Checkbox
            control={control}
            name="useSameSite"
            defaultValue={false}
            label={<Label isDisabledCheckbox={isDisabledCheckbox} />}
            color="secondary"
            handleChangeCheckbox={handleChangeCheckbox}
            isDisabled={isDisabledCheckbox}
          />
          <SiteSelection
            control={control}
            sites={billingSites}
            refetch={refetchBillingSites}
            enquirerGroup={quoteData.enquirerGroup}
            isDsplayFilterSites={hasMoreThanOneBillingPage}
            isDisabled={isDisabledRadio}
            label={t('quote.billingSite')}
            type="billing"
          />
        </>
      )}
    </ActionWithModal>
  )
}

export default (compose(
  withRedux(({ user }) => ({
    rights: user.data.rights,
  })),
  withRouter,
  withStyles(styles),
)(AffectTheVehicleAction): any)
