// @flow

import type { TypeValue } from 'quote/cmp/PrintQuote/types'

export const numberFormat = (
  value?: string | number,
  style: 'currency' | 'decimal' = 'decimal',
): null | string => {
  const language = window.localStorage.getItem('user-language') ?? navigator.language ?? 'fr-FR'
  const number = parseInt(value, 10)

  return !Number.isNaN(number)
    ? Intl.NumberFormat(language, {
        style,
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(number)
    : null
}

export const getFielsToDisplay = (
  fields: Object,
  data: Object,
): { name: string, value: string | number | null, typeValue?: TypeValue }[] =>
  Object.keys(fields)
    .map(element => fields[element] && data?.[element])
    .filter(element => Boolean(element))
