// @flow

import React from 'react'
import { connect as withRedux } from 'react-redux'
import { useMutation } from '@apollo/client'
import { acceptQuoteQuery } from 'quote/quote-queries'
import { RIGHT_ACCEPT_REJECT_QUOTE } from '_client/config'
import Button from '@autodisol/ads-js/components/CustomButton'
import ExpiredQuotation from 'quote/cmp/QuoteActionsPanel/Resval/ExpiredQuotation'

const useAcceptQuote = (id: string) => {
  const [acceptQuote, { loading: isAcceptLoading }] = useMutation(acceptQuoteQuery, {
    variables: {
      quoteId: id,
    },
    refetchQueries: ['getQuoteAndCountQuery'],
    awaitRefetchQueries: true,
  })

  const handleActions = () => {
    acceptQuote()
  }

  return {
    handleActions,
    isAcceptLoading,
  }
}

type Props = {
  id: string,
  content: string,
  expired?: boolean,
  rights: string[],
  isDisabled?: boolean,
}

const AcceptAction = ({ id, content, expired, rights, isDisabled }: Props) => {
  const { handleActions, isAcceptLoading } = useAcceptQuote(id)
  const hasRightToAcceptQuote = rights.includes(RIGHT_ACCEPT_REJECT_QUOTE)

  if (!hasRightToAcceptQuote) return null

  if (expired) {
    return <ExpiredQuotation />
  }

  return (
    <Button
      colorType="green"
      height="short"
      onClick={handleActions}
      fullWidth
      variant="contained"
      style={{ margin: '6px 0 12px' }}
      isLoading={isAcceptLoading}
      disabled={isDisabled}
    >
      {content}
    </Button>
  )
}

export default (withRedux(({ user }) => ({
  rights: user.data.rights,
}))(AcceptAction): any)
