// @flow

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import Tooltip from 'Tooltip'

const styles = () => ({
  title: {
    fontWeight: 700,
    color: '#868F98',
    margin: '0.5rem 0 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltip: {
    backgroundColor: '#fff',
    color: '#40454F',
    font: 'normal normal bold 14px/20px Lato',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: 4,
    padding: '5px 10px',
    textAlign: 'center',
    inset: '-25px auto 0px auto',
  },
  icon: {
    color: '#414b56',
    verticalAlign: 'sub',
  },
})

type Props = {
  classes: Object,
  title: string,
  offerDeadline: string,
  offset: string,
}

const Title = ({ classes, title, offerDeadline, offset }: Props) => {
  const { t } = useTranslation()

  return (
    <Typography variant="h2" className={classes.title}>
      {title}
      <Tooltip title={t('quote.expiryDateOfTheOffer', { date: offerDeadline })} offset={offset} />
    </Typography>
  )
}

export default (withStyles(styles)(Title): any)
