// @flow

import LogoutIcon from '@material-ui/icons/PowerSettingsNew'

import LogoutRedirect from 'auth/cmp/LogoutRedirect'
import ForgottenPasswordPageWrapper from 'auth/cmp-page/ForgottenPasswordPageWrapper'
import LoginPage from 'auth/cmp-page/LoginPage'
import NewPasswordPageWrapper from 'auth/cmp-page/NewPasswordPageWrapper'
import { FORGOTTEN_PASSWORD_PATH, LOGIN_PATH, LOGOUT_PATH, newPasswordPath } from 'auth/auth-paths'
import i18next from '../services/translations/config'

export const loginRoute = {
  path: LOGIN_PATH,
  exact: true,
  loggedOutOnly: true,
  pageComponent: LoginPage,
  title: (i18next.t('auth.logIn'): string),
}

export const logoutRoute = {
  path: LOGOUT_PATH,
  exact: true,
  icon: LogoutIcon,
  pageComponent: LogoutRedirect,
  title: (i18next.t('auth.logOut'): string),
}

export const forgottenPasswordRoute = {
  path: FORGOTTEN_PASSWORD_PATH,
  exact: true,
  pageComponent: ForgottenPasswordPageWrapper,
  title: (i18next.t('auth.forgottenPassword'): string),
}

export const newPasswordRoute = {
  path: (newPasswordPath(): string),
  exact: true,
  pageComponent: NewPasswordPageWrapper,
  title: (i18next.t('auth.newPassword'): string),
}
