// @flow

import React from 'react'
import { connect as withRedux } from 'react-redux'
import Button from '@autodisol/ads-js/components/CustomButton'
import { editQuotePath } from 'quote/quote-paths'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { RIGHT_CLOSE_CASE_QUOTE } from '_client/config'

type Props = {
  id: string,
  rights: string[],
}

const CloseCaseQuoteAction = ({ id, rights }: Props): React$Element<any> | null => {
  const { t } = useTranslation()

  const hasRightToCloseQuote = rights.includes(RIGHT_CLOSE_CASE_QUOTE)

  if (!hasRightToCloseQuote) return null

  return (
    <Button
      variant="contained"
      colorType="error"
      height="short"
      component={Link}
      to={{
        pathname: editQuotePath(id),
        state: { mode: 'close' },
      }}
      fullWidth
      style={{ margin: '6px 0 12px' }}
    >
      {t('quote.close')}
    </Button>
  )
}

export default (withRedux(({ user }) => ({
  rights: user.data.rights,
}))(CloseCaseQuoteAction): any)
