// @flow

import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

type Props = {
  classes: Object,
  children: React.Node,
}

type ModalProps = {
  children: React.Node,
  classes: Object,
  open: boolean,
  handleClose: () => void,
  ariaLabelledby: string,
  ariaDescribedby: string,
}

type UseModalReturnType = {
  closeModal: () => void,
  openModal: () => void,
  open: boolean,
}

const useModal = (): UseModalReturnType => {
  const [open, setOpen] = React.useState(false)

  const openModal = () => setOpen(true)

  const closeModal = () => setOpen(false)

  return {
    open,
    openModal,
    closeModal,
  }
}

const Header = withStyles(() => ({
  title: {
    margin: '10px 0 0',
  },
}))(({ children, classes }: Props) => (
  <DialogTitle className={classes.title}>{children}</DialogTitle>
))

const Content = withStyles(() => ({
  content: {
    padding: 0,
    '&:first-child': {
      padding: 0,
    },
    textAlign: 'justify',
  },
}))(({ children, classes }: Props) => (
  <DialogContent className={classes.content}>{children}</DialogContent>
))

const Actions = withStyles(() => ({
  actions: {
    display: 'block',
    width: '100%',
  },
}))(({ children, classes }: Props) => (
  <DialogActions className={classes.actions}>{children}</DialogActions>
))

const Modal: any = withStyles(({ breakpoints }) => ({
  paper: {
    padding: 0,
    [breakpoints.up('sm')]: {
      padding: 40,
    },
  },
}))(
  ({
    children,
    classes,
    open,
    handleClose,
    ariaLabelledby,
    ariaDescribedby,
  }: ModalProps): React$Element<any> => (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby={ariaLabelledby}
      aria-describedby={ariaDescribedby}
      classes={{
        paper: classes.paper,
      }}
    >
      {children}
    </Dialog>
  ),
)

Modal.Header = Header
Modal.Content = Content
Modal.Actions = Actions
Modal.useModal = useModal

export default Modal
