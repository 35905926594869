// @flow

import React from 'react'
import { connect as withRedux } from 'react-redux'
import compose from 'recompose/compose'
import moment from 'moment'
import numeral from 'numeral'
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { getPrice } from '_client/util'
import { getModesData } from 'utils/modes'
import { styles } from 'quote/cmp/MonitoringTable/styles'

type Props = {
  classes: Object,
  handleClickRow: (quote: Object) => () => void,
  displayParameter: Object,
  isTableLoading: boolean,
  quotes: Object,
  user: Object,
}

const TableStandardMode = ({
  classes,
  handleClickRow,
  displayParameter,
  isTableLoading,
  quotes,
  user,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Table style={{ marginTop: 25 }}>
      <TableHead>
        <TableRow>
          {displayParameter?.creationDate && <TableCell>{t('search.creationDate')}</TableCell>}
          {displayParameter?.plateVin && (
            <TableCell>
              {t('quote.plate')} / {t('quote.vin')}
            </TableCell>
          )}
          {displayParameter?.makeModel && (
            <TableCell>
              {t('quote.make')} / {t('quote.model')}
            </TableCell>
          )}
          {displayParameter?.status && <TableCell>{t('quote.status')}</TableCell>}
          {displayParameter?.commitmentPrice && (
            <TableCell className={classes.priceHead}>{t('quote.commitmentPrice')}</TableCell>
          )}
          {displayParameter?.soldPrice && (
            <TableCell className={classes.priceHead}>
              {t('search.soldPrice')} / {t('search.soldDate')}
            </TableCell>
          )}
          {displayParameter?.advisedPrice && (
            <TableCell className={classes.priceHead}>{t('search.advisedPrice')}</TableCell>
          )}
          {displayParameter?.quotationDate && (
            <TableCell className={classes.priceHead}>{t('search.quotationDate')}</TableCell>
          )}
          {displayParameter?.mileage && (
            <TableCell className={classes.priceHead}>{t('search.mileage')}</TableCell>
          )}
          {displayParameter?.deliveryDate && (
            <TableCell className={classes.priceHead}>{t('search.deliveryDate')}</TableCell>
          )}
          {displayParameter?.enquirer && (
            <TableCell className={classes.priceHead}>{t('search.enquirer')}</TableCell>
          )}
          {displayParameter?.registrationDate && (
            <TableCell className={classes.priceHead}>{t('search.registrationDate')}</TableCell>
          )}
          {displayParameter?.updatedAt && (
            <TableCell className={classes.priceHead}>{t('search.updatedAt')}</TableCell>
          )}
          {displayParameter?.group && (
            <TableCell className={classes.priceHead}>{t('quote.group')}</TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {!isTableLoading &&
          quotes?.results &&
          quotes?.results.map(quote => {
            const soldPrice = quote.prices && quote.prices.find(price => price.type === 'sell')
            const quoterPrice = getPrice(
              'quoter_marged',
              quote?.prices && quote?.prices,
              user?.config?.pricing?.display,
              user?.rights,
            )
            const b2cAdvisedPrice = getPrice(
              'b2c_advised',
              quote?.prices && quote?.prices,
              user?.config?.pricing?.display,
              user?.rights,
            )

            return (
              <TableRow key={quote.id} className={classes.tableRow} onClick={handleClickRow(quote)}>
                {displayParameter?.creationDate && (
                  <TableCell>
                    {quote.createdAt ? moment(quote.createdAt?.date).format('DD/MM/YYYY') : '-'}
                  </TableCell>
                )}
                {displayParameter?.plateVin && (
                  <TableCell>
                    <Typography variant="body1" color="textSecondary">
                      {quote.plate || '-'}
                      <br />
                      <Typography variant="body1" component="span">
                        {quote.vin || '-'}
                      </Typography>
                    </Typography>
                  </TableCell>
                )}
                {displayParameter?.makeModel && (
                  <TableCell style={{ textTransform: 'capitalize' }}>
                    <Typography variant="body1" color="textSecondary">
                      {quote.make ? quote.make.toLowerCase() : '-'}{' '}
                      {quote.model ? quote.model.toLowerCase() : '-'}
                    </Typography>
                  </TableCell>
                )}
                {displayParameter?.status && (
                  <TableCell>
                    <Typography variant="body1">
                      {quote.status === 'sent' && quote.tracking
                        ? t(`globals.allStatus.${quote.tracking.status}`)
                        : t(`globals.allStatus.${quote.status}`)}
                    </Typography>
                  </TableCell>
                )}
                {displayParameter?.commitmentPrice && (
                  <TableCell className={classes.price}>
                    <Typography variant="body1" color="textSecondary">
                      {quoterPrice ? `${numeral(quoterPrice.amount).format()} €` : '-'}
                    </Typography>
                  </TableCell>
                )}
                {displayParameter?.soldPrice && (
                  <TableCell className={classes.price}>
                    <Typography variant="body1" color="textSecondary">
                      {soldPrice ? `${numeral(soldPrice?.amount).format()} €` : '-'}
                      <br />
                      <Typography variant="body1" component="span">
                        {soldPrice ? moment(soldPrice.createdAt?.date).format('DD/MM/YYYY') : '-'}
                      </Typography>
                    </Typography>
                  </TableCell>
                )}
                {displayParameter?.advisedPrice && (
                  <TableCell className={classes.price}>
                    <Typography variant="body1" color="textSecondary">
                      {b2cAdvisedPrice ? `${numeral(b2cAdvisedPrice?.amount).format()} €` : '-'}
                    </Typography>
                  </TableCell>
                )}
                {displayParameter?.quotationDate && (
                  <TableCell className={classes.price}>
                    <Typography variant="body1" color="textSecondary">
                      {quoterPrice ? moment(quoterPrice.createdAt?.date).format('DD/MM/YYYY') : '-'}
                    </Typography>
                  </TableCell>
                )}
                {displayParameter?.mileage && (
                  <TableCell className={classes.price}>
                    <Typography variant="body1" color="textSecondary">
                      {quote.deliveryKm ? `${numeral(quote.deliveryKm).format()} km` : '-'}
                    </Typography>
                  </TableCell>
                )}
                {displayParameter?.deliveryDate && (
                  <TableCell className={classes.price}>
                    <Typography variant="body1" color="textSecondary">
                      {quote.deliveryDate
                        ? moment(quote.deliveryDate?.date).format('DD/MM/YYYY')
                        : '-'}
                    </Typography>
                  </TableCell>
                )}
                {displayParameter?.enquirer && (
                  <TableCell className={classes.price}>
                    <Typography variant="body1" color="textSecondary">
                      <Tooltip
                        title={
                          quote?.contact?.fullname
                            ? t('quote.viaUser', { user: quote?.enquirer?.fullname })
                            : ''
                        }
                        placement="top"
                      >
                        <div>{quote?.contact?.fullname || quote?.enquirer?.fullname || '-'}</div>
                      </Tooltip>
                      ({quote.enquirerGroup ? quote.enquirerGroup.name : '-'})
                    </Typography>
                  </TableCell>
                )}
                {displayParameter?.registrationDate && (
                  <TableCell className={classes.price}>
                    <Typography variant="body1" color="textSecondary">
                      {quote.releaseDate
                        ? moment(quote.releaseDate?.date).format('DD/MM/YYYY')
                        : '-'}
                    </Typography>
                  </TableCell>
                )}
                {displayParameter?.updatedAt && (
                  <TableCell className={classes.price}>
                    <Typography variant="body1" color="textSecondary">
                      {moment(quote.updatedAt?.date).format('DD/MM/YYYY')}
                    </Typography>
                  </TableCell>
                )}
                {displayParameter?.group && (
                  <TableCell className={classes.price}>
                    <Typography variant="body1" color="textSecondary">
                      {quote?.quotations?.[0]?.quoterGroup
                        ? quote?.quotations?.[0]?.quoterGroup?.name
                        : '-'}
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
            )
          })}
      </TableBody>
    </Table>
  )
}

export default (compose(
  withStyles(styles),
  withRedux(({ user, router }) => {
    const { currentMode } = getModesData(user, router.location.pathname)

    return {
      displayParameter: user.data?.displayParameter[currentMode],
      user: user.data,
      mode: currentMode,
    }
  }),
)(TableStandardMode): any)
