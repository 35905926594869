// @flow

import React from 'react'

import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import Card from '@autodisol/ads-js/components/Card'
import moment from 'moment'
import numeral from 'numeral'
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'
import { useTranslation } from 'react-i18next'
import { quotePath, editQuotePath } from 'quote/quote-paths'
import { getPrices } from '_client/util'
import { QUOTE_STATUS_UPDATED_FLEET } from '_client/config'
import { MODES } from 'utils/constants/modes'

import type { Mode } from 'types/modes'

const QuoteCardJSX = ({
  classes: css,
  history,
  quote,
  style,
  displayParameter,
  user,
  mode,
  isNewSearch,
}: {
  classes: Object,
  quote: Object,
  history: Object,
  style?: Object,
  displayParameter?: Object,
  user?: Object,
  mode: Mode,
  isNewSearch: boolean,
}) => {
  const { t } = useTranslation()

  const soldPrice = quote.prices && quote.prices.find(price => price.type === 'sell')
  const { quoter_marged: quoterPrice, pending_quoter_marged: pendingQuoterPrice } = getPrices(
    ['quoter_marged', 'pending_quoter_marged'],
    quote?.prices && quote?.prices,
    user?.config?.pricing?.display,
    user?.rights,
  )
  const price =
    mode === MODES.resval && quote?.status === QUOTE_STATUS_UPDATED_FLEET
      ? pendingQuoterPrice
      : quoterPrice

  const handleClick = () => {
    const { status, plate, make, id } = quote
    const initialSearchFilters = history.location?.state?.initialSearchFilters
    const state = status === 'draft' ? { plate } : { make, plate }

    history.push({
      pathname: status === 'draft' ? editQuotePath(id) : quotePath(id),
      state: {
        ...(Boolean(initialSearchFilters) && !isNewSearch && { initialSearchFilters }),
        // $FlowIgnore[exponential-spread]
        ...state,
      },
    })
  }

  return (
    <Card className={css.quoteCard} onClick={handleClick} {...{ style }}>
      <Grid container spacing={8}>
        <Grid item xs={12} style={{ padding: '20px 15px 10px 15px' }}>
          {displayParameter?.creationDate && quote.createdAt && (
            <Typography variant="body1">
              {t('quote.creationDateColon')} {moment(quote.createdAt.date).format('DD/MM/YYYY')}
            </Typography>
          )}
          {displayParameter?.quotationDate && (
            <Typography variant="body1">
              {t('quote.quotationDateColon')}{' '}
              {price ? moment(price.createdAt.date).format('DD/MM/YYYY') : '-'}
            </Typography>
          )}
          {displayParameter?.updatedAt && (
            <Typography variant="body1">
              {t('quote.updatedAtColon')} {moment(quote.updatedAt.date).format('DD/MM/YYYY')}
            </Typography>
          )}
        </Grid>

        <Grid
          item
          style={{ width: '100%', display: 'flex', background: '#F5F5F5', padding: '5px 15px' }}
        >
          <Grid item xs={6}>
            {displayParameter?.plateVin && (
              <>
                <Grid item xs={12}>
                  <Typography variant="body1" color="textSecondary">
                    {quote.plate}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">{quote.vin} </Typography>
                </Grid>
              </>
            )}
            {displayParameter?.makeModel && (
              <Grid item xs={12} style={{ textTransform: 'capitalize' }}>
                <Typography variant="body1" color="textSecondary">
                  {quote.make ? quote.make.toLowerCase() : '--'}
                  &nbsp;{quote.model ? quote.model.toLowerCase() : '--'}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid item xs={6}>
            {displayParameter?.commitmentPrice && (
              <Grid item xs={12} style={{ textAlign: 'right' }}>
                <Typography variant="body1" color="textSecondary">
                  {price ? `${numeral(price?.amount).format()} €` : '-'}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <Typography variant="body1">
                {quote.status === 'sent' && quote.tracking
                  ? `${t(`globals.allStatus.${quote.tracking.status}`)} ${
                      soldPrice?.type === 'sell' ? `(${numeral(soldPrice?.amount).format()} €)` : ''
                    }`
                  : displayParameter?.status && t(`globals.allStatus.${quote.status}`)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ padding: '10px 15px 0px 15px' }}>
          {displayParameter?.registrationDate && (
            <Typography variant="body1" color="secondary">
              {t('quote.inssuanceDateShortColon')}
              &nbsp;
              <Typography variant="body1" component="span" style={{ display: 'inline' }}>
                {quote.releaseDate ? moment(quote.releaseDate.date).format('DD/MM/YYYY') : '--'}
              </Typography>
            </Typography>
          )}
          {displayParameter?.mileage && (
            <Typography variant="body1" color="secondary">
              {t('quote.mileageColon')}
              &nbsp;
              <Typography variant="body1" component="span" style={{ display: 'inline' }}>
                {quote.deliveryKm ? `${numeral(quote.deliveryKm).format()} km` : '-'}
              </Typography>
            </Typography>
          )}
          {displayParameter?.deliveryDate && (
            <Typography variant="body1" color="secondary">
              {t('quote.deliveryDateColon')}
              &nbsp;
              <Typography variant="body1" component="span" style={{ display: 'inline' }}>
                {quote.deliveryDate ? moment(quote.deliveryDate.date).format('DD/MM/YYYY') : '-'}
              </Typography>
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} style={{ padding: '10px 15px 20px 15px' }}>
          {displayParameter?.group && (
            <Typography variant="body1" color="secondary">
              {t('quote.groupColon')}
              &nbsp;
              <Typography variant="body1" component="span" style={{ display: 'inline' }}>
                {quote?.quotations?.[0]?.quoterGroup
                  ? quote?.quotations?.[0]?.quoterGroup?.name
                  : '-'}
              </Typography>
            </Typography>
          )}
          {displayParameter?.enquirer && (
            <>
              <Typography variant="body1" color="secondary">
                {t('quote.enquirerColon')}
                &nbsp;
                <Typography variant="body1" component="span" style={{ display: 'inline' }}>
                  {quote.enquirer ? quote.enquirer.fullname : '-'}
                </Typography>
              </Typography>
              <Typography variant="body1" component="span" style={{ display: 'inline' }}>
                {t('quote.groups')} ({quote.enquirerGroup ? quote.enquirerGroup.name : '-'})
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
    </Card>
  )
}

const QuoteCardCmp = withStyles({
  quoteCard: {
    padding: '0.22rem !important',
    marginBottom: '0.5rem',
    '& *': { textOverflow: 'ellipsis', overflow: 'hidden' },
  },
})(QuoteCardJSX)

const QuoteCard: any = compose(withRouter)(QuoteCardCmp)

export default QuoteCard
