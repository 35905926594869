// @flow

import { useApolloClient } from '@apollo/client'
import { getQuoteAndCountQuery } from 'quote/quote-queries'

export const useQuoteDataFromCache = (id: string): Object => {
  const client = useApolloClient()

  const quoteData = client.readQuery({
    query: getQuoteAndCountQuery,
    variables: {
      id,
    },
  })

  return quoteData?.get_indexed_quote ?? {}
}
