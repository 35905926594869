// @flow

import React from 'react'
import { connect as withRedux } from 'react-redux'
import compose from 'recompose/compose'
import moment from 'moment'
import numeral from 'numeral'
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { getPrices } from '_client/util'
import { getModesData } from 'utils/modes'
import { styles } from 'quote/cmp/MonitoringTable/styles'
import { QUOTE_STATUS_UPDATED_FLEET } from '_client/config'

type Props = {
  classes: Object,
  handleClickRow: (quote: Object) => () => void,
  displayParameter: Object,
  isTableLoading: boolean,
  quotes: Object,
  user: Object,
}

const TableResvalMode = ({
  classes,
  handleClickRow,
  displayParameter,
  isTableLoading,
  quotes,
  user,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Table style={{ marginTop: 25 }}>
      <TableHead>
        <TableRow>
          {displayParameter?.creationDate && <TableCell>{t('search.creationDate')}</TableCell>}
          {(displayParameter?.reference || displayParameter?.plate) && (
            <TableCell>
              {displayParameter?.reference && <span>{t('search.reference')}</span>}
              {displayParameter?.reference && displayParameter?.plate && <br />}
              {displayParameter?.plate && <span>{t('search.plate')}</span>}
            </TableCell>
          )}
          {displayParameter?.makeModel && (
            <TableCell>
              {t('quote.make')} / {t('quote.model')}
            </TableCell>
          )}
          {(displayParameter?.deliveryDate || displayParameter?.contractDuration) && (
            <TableCell>
              {displayParameter?.deliveryDate && t('search.deliveryDate')}
              {displayParameter?.deliveryDate && displayParameter?.contractDuration && <br />}
              {displayParameter?.contractDuration && t('search.contractDuration')}
            </TableCell>
          )}

          {displayParameter?.mileageToGo && <TableCell>{t('search.mileageToGo')}</TableCell>}
          {displayParameter?.status && <TableCell>{t('search.status')}</TableCell>}
          {displayParameter?.commitmentPrice && (
            <TableCell className={classes.priceHead}>{t('search.commitmentPrice')}</TableCell>
          )}
          {displayParameter?.quotationDate && (
            <TableCell className={classes.priceHead}>{t('search.quotationDate')}</TableCell>
          )}
          {displayParameter?.enquirer && (
            <TableCell className={classes.priceHead}>{t('search.enquirer')}</TableCell>
          )}
          {displayParameter?.mileageToRoad && (
            <TableCell className={classes.priceHead}>{t('search.mileageToRoad')}</TableCell>
          )}
          {displayParameter?.registrationDate && (
            <TableCell className={classes.priceHead}>{t('search.registrationDate')}</TableCell>
          )}
          {displayParameter?.updatedAt && (
            <TableCell className={classes.priceHead}>{t('search.updatedAt')}</TableCell>
          )}
          {displayParameter?.group && (
            <TableCell className={classes.priceHead}>{t('search.group')}</TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {!isTableLoading &&
          quotes?.results &&
          quotes?.results.map(quote => {
            const { quoter_marged: quoterPrice, pending_quoter_marged: pendingQuoterPrice } =
              getPrices(
                ['quoter_marged', 'pending_quoter_marged'],
                quote?.prices && quote?.prices,
                user?.config?.pricing?.display,
                user?.rights,
              )
            const isDisplayPendingData = quote?.status === QUOTE_STATUS_UPDATED_FLEET
            const price = isDisplayPendingData ? pendingQuoterPrice : quoterPrice
            const contractKmStart = isDisplayPendingData
              ? quote?.pendingUpdate?.contractKmStart
              : quote?.contractKmStart
            const contractKm = isDisplayPendingData
              ? quote?.pendingUpdate?.contractKm
              : quote?.contractKm
            const contractDuration = isDisplayPendingData
              ? quote?.pendingUpdate?.contractDuration
              : quote?.contractDuration
            const deliveryDate =
              quote?.deliveryDate ?? quote?.updatedContractDateStart ?? quote?.contractDateStart
            const deliveryDateFormatted = deliveryDate
              ? moment(deliveryDate?.date).format('DD/MM/YYYY')
              : '-'
            const contractDurationFormatted =
              typeof contractDuration === 'number'
                ? `${quote.contractDuration} ${t('globals.month')}`
                : '-'

            return (
              <TableRow key={quote.id} className={classes.tableRow} onClick={handleClickRow(quote)}>
                {displayParameter?.creationDate && (
                  <TableCell>
                    {quote.createdAt ? moment(quote.createdAt?.date).format('DD/MM/YYYY') : '-'}
                  </TableCell>
                )}
                {(displayParameter?.reference || displayParameter?.plate) && (
                  <TableCell>
                    <Typography variant="body1" color="textSecondary">
                      {displayParameter?.reference ? quote.reference ?? '-' : ''}
                      {displayParameter?.reference && displayParameter?.plate && <br />}
                      {displayParameter?.plate ? quote.plate ?? '-' : ''}
                    </Typography>
                  </TableCell>
                )}

                {displayParameter?.makeModel && (
                  <TableCell style={{ textTransform: 'capitalize' }}>
                    <Typography variant="body1" color="textSecondary">
                      {quote.make ? quote.make.toLowerCase() : '-'}{' '}
                      {quote.model ? quote.model.toLowerCase() : '-'}
                    </Typography>
                  </TableCell>
                )}
                {(displayParameter?.deliveryDate || displayParameter?.contractDuration) && (
                  <TableCell>
                    <Typography variant="body1" color="textSecondary">
                      {displayParameter?.deliveryDate ? deliveryDateFormatted : ''}
                      {displayParameter?.deliveryDate && displayParameter?.contractDuration && (
                        <br />
                      )}
                      {displayParameter?.contractDuration ? contractDurationFormatted : ''}
                    </Typography>
                  </TableCell>
                )}
                {displayParameter?.mileageToGo && (
                  <TableCell>
                    <Typography variant="body1" color="textSecondary">
                      {contractKm ? `${contractKm} km` : '-'}
                    </Typography>
                  </TableCell>
                )}
                {displayParameter?.status && (
                  <TableCell>
                    <Typography variant="body1">
                      {quote.status === 'sent' && quote.tracking
                        ? t(`globals.allStatus.${quote.tracking.status}`)
                        : t(`globals.allStatus.${quote.status}`)}
                    </Typography>
                  </TableCell>
                )}
                {displayParameter?.commitmentPrice && (
                  <TableCell className={classes.price}>
                    <Typography variant="body1" color="textSecondary">
                      {price ? `${numeral(price.amount).format()} €` : '-'}
                    </Typography>
                  </TableCell>
                )}
                {displayParameter?.quotationDate && (
                  <TableCell className={classes.price}>
                    <Typography variant="body1" color="textSecondary">
                      {price ? moment(price.createdAt?.date).format('DD/MM/YYYY') : '-'}
                    </Typography>
                  </TableCell>
                )}
                {displayParameter?.enquirer && (
                  <TableCell className={classes.price}>
                    <Typography variant="body1" color="textSecondary">
                      <Tooltip
                        title={
                          quote?.contact?.fullname
                            ? t('quote.viaUser', { user: quote?.enquirer?.fullname })
                            : ''
                        }
                        placement="top"
                      >
                        <div>{quote?.contact?.fullname || quote?.enquirer?.fullname || '-'}</div>
                      </Tooltip>
                      {quote.enquirerGroup ? quote.enquirerGroup.name : '-'}
                    </Typography>
                  </TableCell>
                )}
                {displayParameter?.mileageToRoad && (
                  <TableCell className={classes.price}>
                    <Typography variant="body1" color="textSecondary">
                      {contractKmStart ? `${contractKmStart} km` : '-'}
                    </Typography>
                  </TableCell>
                )}
                {displayParameter?.registrationDate && (
                  <TableCell className={classes.price}>
                    <Typography variant="body1" color="textSecondary">
                      {quote.releaseDate
                        ? moment(quote.releaseDate?.date).format('DD/MM/YYYY')
                        : '-'}
                    </Typography>
                  </TableCell>
                )}
                {displayParameter?.updatedAt && (
                  <TableCell className={classes.price}>
                    <Typography variant="body1" color="textSecondary">
                      {moment(quote.updatedAt?.date).format('DD/MM/YYYY')}
                    </Typography>
                  </TableCell>
                )}
                {displayParameter?.group && (
                  <TableCell className={classes.price}>
                    <Typography variant="body1" color="textSecondary">
                      {quote?.quotations?.[0]?.quoterGroup
                        ? quote?.quotations?.[0]?.quoterGroup?.name
                        : '-'}
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
            )
          })}
      </TableBody>
    </Table>
  )
}

export default (compose(
  withStyles(styles),
  withRedux(({ user, router }) => {
    const { currentMode } = getModesData(user, router.location.pathname)

    return {
      displayParameter: user.data?.displayParameter[currentMode],
      user: user.data,
      mode: currentMode,
    }
  }),
)(TableResvalMode): any)
