// @flow

import React from 'react'
import TableStandardMode from 'quote/cmp/MonitoringTable/Standard'
import TableResvalMode from 'quote/cmp/MonitoringTable/Resval'
import { quotePath, editQuotePath } from 'quote/quote-paths'
import { MODES } from 'utils/constants/modes'
import type { Mode } from 'types/modes'

type Props = {
  history: Object,
  quotes: Object,
  isTableLoading: boolean,
  mode: Mode,
  isNewSearch: boolean,
}

const MonitoringTable = ({
  history,
  quotes,
  isTableLoading,
  mode,
  isNewSearch,
}: Props): React$Element<any> => {
  const handleClickRow = quote => () => {
    const { status, plate, make, id } = quote
    const initialSearchFilters = history.location?.state?.initialSearchFilters
    const state = status === 'draft' ? { plate } : { make, plate }

    history.push({
      pathname: status === 'draft' ? editQuotePath(id) : quotePath(id),
      state: {
        ...(Boolean(initialSearchFilters) && !isNewSearch && { initialSearchFilters }),
        // $FlowIgnore[exponential-spread]
        ...state,
      },
    })
  }

  if (mode === MODES.resval)
    return (
      <TableResvalMode
        quotes={quotes}
        isTableLoading={isTableLoading}
        handleClickRow={handleClickRow}
      />
    )

  return (
    <TableStandardMode
      quotes={quotes}
      isTableLoading={isTableLoading}
      handleClickRow={handleClickRow}
    />
  )
}

export default MonitoringTable
