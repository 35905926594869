// @flow

import React from 'react'
import { printSettingsPreset } from 'quote/quote-constant'
import { useTranslation } from 'react-i18next'
import { getFielsToDisplay } from 'quote/cmp/PrintQuote/functions'
import withStyles from '@material-ui/core/styles/withStyles'
import SectionTitle from 'quote/cmp/PrintQuote/SectionTitle'
import ListItem from 'quote/cmp/PrintQuote/ListItem'
import { DOCUMENT_TYPE } from 'utils/constants/quote'

import type { Mode } from 'types/modes'
import type { PrintField } from 'types/print'

const styles = () => ({
  list: {
    paddingLeft: 0,
  },
})

type Props = {
  classes: Object,
  printField: PrintField,
  quote?: Object,
  mode: Mode,
}

const LoadedPictures = ({ classes, printField, quote, mode }: Props): React$Element<any> => {
  const { t } = useTranslation()

  const currentPrintSettingsPreset = printSettingsPreset[printField]
  const fields = currentPrintSettingsPreset?.pictures?.fields

  const standard = {
    loadedPicturesNumber: {
      name: t('quote.print.number'),
      value:
        quote?.documents && quote?.documents.filter(d => d.type === DOCUMENT_TYPE.PHOTO).length,
    },
  }
  const data = {
    standard: { ...standard },
    resval: { ...standard },
    standard_bike: { ...standard },
  }
  const fieldsToDisplay = getFielsToDisplay(fields, data[mode])

  return (
    <>
      <SectionTitle title={t('quote.print.loadedPictures')} />
      <ul className={classes.list}>
        {fieldsToDisplay.map(({ name, value }) => (
          <ListItem
            name={{ value: name }}
            content={{ value }}
            key={name}
            variant="secondary"
            typeValue="value"
          />
        ))}
      </ul>
    </>
  )
}

export default (withStyles(styles)(LoadedPictures): any)
