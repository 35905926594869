// @flow

import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { ICONS_COMPONENTS } from 'app/cmp/ModesMenu/constants'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import type { Mode } from 'types/modes'

type Props = {
  classes: Object,
  mode: Mode,
}

const styles = ({ breakpoints }) => ({
  container: {
    width: '100%',
    position: 'fixed',
    top: 64,
    left: 0,
    zIndex: 1100,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 16px',
    alignItems: 'center',
    boxShadow: '0px 3px 6px #00000029',
    backgroundColor: '#F5F5F5',
    color: '#868F98',
    font: 'normal normal bold 16px/19px Lato',
    [breakpoints.up('sm')]: {
      display: 'none',
    },
  },
})

const DisplayCurrentMode = ({ classes, mode }: Props) => {
  const { t } = useTranslation()

  return (
    <div className={classes.container}>
      <p>{ICONS_COMPONENTS[mode]}</p>
      <p>{t(`globals.mode.menu.${mode}`)}</p>
      <p>
        <ArrowDropDownIcon />
      </p>
    </div>
  )
}

export default (withStyles(styles)(DisplayCurrentMode): any)
