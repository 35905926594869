// @flow

import { forgottenPasswordRoute, loginRoute, logoutRoute, newPasswordRoute } from 'auth/auth-routes'
import { fakeClientErrorRoute, notFoundRoute } from 'error/error-routes'
import { homeRoute } from 'home/home-routes'
import {
  editQuoteRoute,
  exportListRoute,
  newQuoteRoute,
  printQuoteRoute,
  quoteRoute,
  searchRoute,
  identifyRoute,
  acceptValidAssign,
  rejectValidAssign,
} from 'quote/quote-routes'

const allRoutes = [
  loginRoute,
  homeRoute,
  newQuoteRoute,
  printQuoteRoute,
  quoteRoute,
  searchRoute,
  logoutRoute,
  forgottenPasswordRoute,
  newPasswordRoute,
  fakeClientErrorRoute,
  notFoundRoute,
  editQuoteRoute,
  exportListRoute,
  identifyRoute,
  acceptValidAssign,
  rejectValidAssign,
]

export default allRoutes
