// @flow
import { useQuoteDataFromCache } from 'hooks/useQuoteDataFromCache'
import { useMutation } from '@apollo/client'
import { duplicateQuoteQuery, sendQuoteQuery } from 'quote/quote-queries'
import { SEARCH_PATH } from 'quote/quote-paths'

type Params = {
  id: string,
  history: Object,
}

type OnSubmitParams = {
  kmToStart: number,
  kmToGo: number,
  contractDateStart: string | Date,
  contractDuration: number,
  feedbackDuplicateRequest: string,
}

type ReturnTypeUseDuplicateRequest = {
  quoteData: Object,
  onSubmit: (data: OnSubmitParams) => void,
  isLoading: boolean,
}

export const useDuplicateRequest = ({ id, history }: Params): ReturnTypeUseDuplicateRequest => {
  const quoteData = useQuoteDataFromCache(id)

  const [sendQuote, { loading: isLoadingSendQuote }] = useMutation(sendQuoteQuery, {
    onCompleted: () => {
      history.push({ pathname: SEARCH_PATH, state: { initialSearchState: 'monitoring' } })
    },
  })
  const [duplicateQuote, { loading: isLoadingDuplicateQuote }] = useMutation(duplicateQuoteQuery, {
    onCompleted: ({ duplicate }) => {
      sendQuote({ variables: { quoteId: duplicate.id, quoterGroupId: null } })
    },
  })

  const onSubmit = data => {
    duplicateQuote({
      variables: {
        id,
        quoteInput: {
          category: 'resval',
          contractKm: data.kmToGo,
          contractKmStart: data.kmToStart,
          contractDateStart: {
            date: data.contractDateStart,
            timezone: 'UTC',
          },
          contractDuration: data.contractDuration,
        },
        comment: data.feedbackDuplicateRequest,
      },
    })
  }

  return {
    quoteData,
    onSubmit,
    isLoading: isLoadingDuplicateQuote || isLoadingSendQuote,
  }
}
