// @flow

import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import {
  listSitesQuery,
  assignQuoteQuery,
  getDefaultAuctionGroupIdQuery,
} from 'quote/quote-queries'
import i18next from 'services/translations/config'
import { getFilteredSites } from 'quote/cmp/QuoteActionsPanel/functions'
import { notify } from 'sharyn/redux/actions'
import { SEARCH_PATH } from 'quote/quote-paths'

type Params = {
  id: string,
  dispatch: Function,
  history: Object,
}

type ReturnTypeUseSites = {
  refetchWithdrawalSites: Function,
  refetchBillingSites: Function,
  billingSites: Object[],
  withdrawalSites: Object[],
  isLoadingFetch: boolean,
  hasMoreThanOneWithdrawalPage: boolean,
  hasMoreThanOneBillingPage: boolean,
  postAssignQuote: Function,
  isLoadingPost: boolean,
  isLoadingDefaultActionGroupIdData: boolean,
  getDefaultAuctionGroupId: Function,
}

export const useSites = ({ id, dispatch, history }: Params): ReturnTypeUseSites => {
  const [
    getDefaultAuctionGroupId,
    { data: defaultAuctionGroupIdData, loading: isLoadingDefaultActionGroupIdData },
  ] = useLazyQuery(getDefaultAuctionGroupIdQuery)

  const auctionGroupId = defaultAuctionGroupIdData?.get_default_auction_group_id

  const {
    data: withdrawal,
    refetch: refetchWithdrawalSites,
    loading: isLoadingWithdrawalSites,
  } = useQuery(listSitesQuery, {
    variables: {
      filters: {
        types: ['withdrawal'],
      },
    },
  })
  const {
    data: billing,
    refetch: refetchBillingSites,
    loading: isLoadingBillingSites,
  } = useQuery(listSitesQuery, {
    variables: {
      filters: {
        types: ['billing'],
      },
    },
  })

  const [assignQuote, { loading: isLoadingAssignQuote }] = useMutation(assignQuoteQuery, {
    // eslint-disable-next-line camelcase
    onCompleted: ({ assign_quote }) => {
      dispatch(
        notify({
          title: i18next.t('quote.requestWithdrawal.notification.title'),
          subTitle: i18next.t('quote.requestWithdrawal.notification.subtitle'),
          message: i18next.t('quote.requestWithdrawal.notification.message', {
            plate: assign_quote.plate,
          }),
        }),
      )
      history.push({
        pathname: SEARCH_PATH,
        state: { initialSearchState: 'monitoring' },
      })
    },
  })

  const billingSites = billing?.list_sites
  const withdrawalSites = withdrawal?.list_sites

  const postAssignQuote = async ({ sites }) => {
    assignQuote({
      variables: {
        id,
        auctionGroupId,
        billingSiteId: sites.billing,
        withdrawalSiteId: sites.withdrawal,
      },
    })
  }

  return {
    refetchWithdrawalSites,
    refetchBillingSites,
    billingSites: getFilteredSites(billingSites?.results) ?? [],
    withdrawalSites: getFilteredSites(withdrawalSites?.results) ?? [],
    isLoadingFetch: isLoadingWithdrawalSites || isLoadingBillingSites,
    hasMoreThanOneWithdrawalPage: withdrawalSites?.nbPages > 1,
    hasMoreThanOneBillingPage: billingSites?.nbPages > 1,
    postAssignQuote,
    isLoadingPost: isLoadingAssignQuote,
    isLoadingDefaultActionGroupIdData,
    getDefaultAuctionGroupId,
  }
}
