// @flow

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { NO_VALUE, NO_PRICE } from 'utils/constants'
import i18next from 'services/translations/config'

import type { TypeValue } from 'quote/cmp/PrintQuote/types'

const styles = () => ({
  item: {
    width: '100%',
    display: 'flex',
    lineHeight: 1.5,
    '& > span:first-child': {
      fontWeight: 600,
      paddingRight: '20pt',
      width: '100%',
      textAlign: 'right',
    },
    '& > span:last-child': {
      fontWeight: 900,
      width: '100%',
    },
  },
  primary: {
    '& > span:first-child': {
      paddingRight: '20pt',
      textAlign: 'right',
    },
  },
  secondary: {
    '& > span:first-child': {
      paddingRight: '0pt',
      textAlign: 'left',
    },
  },
})

type Props = {
  classes: Object,
  name: {
    value: string | React$Element<any>,
    style?: { width?: string, fontWeight?: string | number },
  },
  content: {
    value: string | number | null,
    style?: { fontWeight?: string | number },
  },
  variant: 'primary' | 'secondary',
  typeValue: TypeValue,
}

const type = {
  value: NO_VALUE,
  price: NO_PRICE,
  day: `${NO_VALUE} ${i18next.t('globals.day')}`,
}

const ListItem = ({ classes, name, content, variant, typeValue }: Props): React$Element<'li'> => (
  <li className={`${classes.item} ${classes[variant]}`}>
    <span style={name?.style}>{name.value}</span>{' '}
    <span style={content?.style}>{content.value ?? type[typeValue]}</span>
  </li>
)

export default (withStyles(styles)(ListItem): any)
