// @flow

import React from 'react'
import { connect as withRedux } from 'react-redux'
import { useMutation } from '@apollo/client'
import { rejectQuoteQuery } from 'quote/quote-queries'
import { RIGHT_ACCEPT_REJECT_QUOTE } from '_client/config'
import Button from '@autodisol/ads-js/components/CustomButton'

const useAcceptQuote = (id: string) => {
  const [rejectQuote, { loading: isRejectLoading }] = useMutation(rejectQuoteQuery, {
    variables: {
      quoteId: id,
    },
    refetchQueries: ['getQuoteAndCountQuery'],
    awaitRefetchQueries: true,
  })

  const handleActions = () => {
    rejectQuote()
  }

  return {
    handleActions,
    isRejectLoading,
  }
}

type Props = {
  id: string,
  content: string,
  rights: string[],
}

const RejectAction = ({ id, content, rights }: Props) => {
  const { handleActions, isRejectLoading } = useAcceptQuote(id)
  const hasRightToAcceptAndRejectQuote = rights.includes(RIGHT_ACCEPT_REJECT_QUOTE)

  if (!hasRightToAcceptAndRejectQuote) return null

  return (
    <Button
      colorType="error"
      height="short"
      onClick={handleActions}
      fullWidth
      variant="contained"
      style={{ margin: '6px 0 12px' }}
      isLoading={isRejectLoading}
    >
      {content}
    </Button>
  )
}

export default (withRedux(({ user }) => ({
  rights: user.data.rights,
}))(RejectAction): any)
