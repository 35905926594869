import * as Sentry from '@sentry/react'

const preloadedState = window.__PRELOADED_STATE__
const { SENTRY_DSN_PUBLIC, STAGE, APP_VERSION } = preloadedState.env

Sentry.init({
  dsn: SENTRY_DSN_PUBLIC,
  environment: STAGE,
  release: APP_VERSION,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})
