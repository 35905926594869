// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import withStyles from '@material-ui/core/styles/withStyles'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'
import Button from '@autodisol/ads-js/components/CustomButton'
import TextField from '@material-ui/core/TextField'
import { useActualPurchasePrice } from 'quote/cmp/QuoteActionsPanel/ActualPurchasePriceModal/useActualPurchasePrice'
import Modal from 'Modal'

const styles = ({ breakpoints }) => ({
  content: {
    margin: '30px 50px',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 50px',
    '& button': {
      margin: '0 20px 10px',
    },
    [breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      '& button': {
        padding: '10px 40px',
        margin: 0,
      },
      '& button:first-child': {
        marginRight: '5%',
      },
      '& button:last-child': {
        marginLeft: '5%',
      },
    },
  },
})

type Props = {
  classes: Object,
  actionButtonContent: string,
  ariaLabelledby: string,
  ariaDescribedby: string,
  modalValidateButtonContent: string,
  location: Object,
  isDisabled?: boolean,
  isDisplaySkipThisStepButton?: boolean,
  mustExecuteAcceptQuoteQuery?: boolean,
}

const ActualPurchasePriceModal = ({
  classes,
  actionButtonContent,
  ariaLabelledby,
  ariaDescribedby,
  modalValidateButtonContent,
  location,
  isDisabled,
  isDisplaySkipThisStepButton,
  mustExecuteAcceptQuoteQuery,
}: Props) => {
  const quoteId = location.pathname.split('/').pop()

  const { t } = useTranslation()
  const {
    amount,
    open,
    openModal,
    handleChange,
    handleCloseModal,
    handleUpdatePricingQuote,
    handleSkipThisStep,
    isLoading,
    isLoadingPricingQuote,
    isLoadingAcceptQuote,
  } = useActualPurchasePrice({
    quoteId,
    mustExecuteAcceptQuoteQuery,
  })

  return (
    <>
      <Button
        colorType={isDisplaySkipThisStepButton ? 'green' : 'primary'}
        height="short"
        variant="contained"
        onClick={openModal}
        fullWidth
        isDisabled={isDisabled}
        style={{ margin: '6px 0 12px' }}
      >
        {actionButtonContent}
      </Button>

      {open && (
        <Modal
          open={open}
          handleClose={handleCloseModal}
          ariaLabelledby={ariaLabelledby}
          ariaDescribedby={ariaDescribedby}
        >
          <Modal.Header>{t('quote.actualPurchasePrice.title')}</Modal.Header>
          <Modal.Content>
            <div className={classes.content}>
              <TextField
                name="actualPurchasePrice"
                label={`${t('quote.actualPurchasePrice.title')}*`}
                value={amount}
                type="number"
                inputProps={{ min: 0 }}
                autoComplete="off"
                onChange={handleChange}
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <div className={classes.buttons}>
              {isDisplaySkipThisStepButton ? (
                <Button
                  colorType="secondary"
                  height="short"
                  variant="contained"
                  onClick={handleSkipThisStep}
                  isLoading={isLoadingAcceptQuote && amount === ''}
                  disabled={isLoading}
                >
                  {t('quote.actualPurchasePrice.skipThisStep')}
                </Button>
              ) : (
                <Button
                  colorType="secondary"
                  height="short"
                  variant="contained"
                  onClick={handleCloseModal}
                  disabled={isLoadingPricingQuote}
                >
                  {t('globals.cancel')}
                </Button>
              )}

              <Button
                colorType="green"
                height="short"
                variant="contained"
                type="button"
                isLoading={isLoading && amount !== ''}
                disabled={isLoading || amount === ''}
                onClick={handleUpdatePricingQuote}
              >
                {modalValidateButtonContent}
              </Button>
            </div>
          </Modal.Actions>
        </Modal>
      )}
    </>
  )
}

export default (compose(withStyles(styles), withRouter)(ActualPurchasePriceModal): any)
