// @flow

import DashboardIcon from '@material-ui/icons/Dashboard'

import HomePage from 'home/cmp-page/HomePage'
import { HOME_PATH } from 'home/home-paths'
import i18next from '../services/translations/config'

export const homeRoute = {
  path: HOME_PATH,
  exact: true,
  loggedInOnly: true,
  pageComponent: HomePage,
  title: (i18next.t('home.title'): string),
  icon: DashboardIcon,
  withBottomNav: true,
  backNav: null,
}
