// @flow

import React from 'react'
import numeral from 'numeral'
import withStyles from '@material-ui/core/styles/withStyles'
import { useTranslation } from 'react-i18next'
import Tooltip from 'Tooltip'

const styles = () => ({
  element: {
    fontSize: '1.125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 900,
    '& > span': {
      color: '#868F98',
      fontWeight: 700,
      paddingRight: 5,
    },
  },
  fontSize: {
    fontSize: '1rem',
  },
})

type PropsTooltipTitle = {
  classes: Object,
  contractKm: number,
  contractKmStart: number,
  contractDuration: number,
}

const TooltipTitle = withStyles(() => ({
  container: {
    fontSize: '1.1rem',
    listStyleType: 'none',
    paddingLeft: 0,
    '& > li > span': {
      fontWeight: 900,
    },
  },
}))(({ classes, contractKm, contractKmStart, contractDuration }: PropsTooltipTitle) => {
  const { t } = useTranslation()

  return (
    <ul className={classes.container}>
      <li>
        <span>{t('quote.kmToTheRoad')}</span>: {contractKmStart} km
      </li>
      <li>
        <span>{t('quote.kmToGo')}</span>: {contractKm} km
      </li>
      <li>
        <span>{t('quote.contractDuration')}</span>: {contractDuration} {t('globals.month')}
      </li>
    </ul>
  )
})

type Props = {
  classes: Object,
  quoterPrice?: number,
  pendingQuoterPrice?: number,
  contractKm: number,
  contractKmStart: number,
  contractDuration: number,
}

const PriceForUpdateFleetStatus = ({
  classes,
  quoterPrice,
  pendingQuoterPrice,
  contractKm,
  contractKmStart,
  contractDuration,
}: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <p className={classes.element}>
        {pendingQuoterPrice ? `${numeral(pendingQuoterPrice).format()} €` : '- €'}
      </p>
      <p className={`${classes.element} ${classes.fontSize}`}>
        <span>{t('quote.prices.currentOffer')}</span>{' '}
        {quoterPrice ? `${numeral(quoterPrice).format()} €` : '- €'}
        <Tooltip
          title={
            <TooltipTitle
              contractKmStart={contractKmStart}
              contractKm={contractKm}
              contractDuration={contractDuration}
            />
          }
          offset="0px -87px"
        />
      </p>
    </>
  )
}

export default (withStyles(styles)(PriceForUpdateFleetStatus): any)
